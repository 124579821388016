import {
    QUESTIONS_BY_TOPIC_ID,
    QUESTIONS_BY_TOPIC_ID_SUCCESS,
    QUESTIONS_BY_TOPIC_ID_ERROR, SUBMIT_QUESTIONS_BY_TOPIC_ID_SUCCESS, SUBMIT_QUESTIONS_BY_TOPIC_ID_ERROR
} from '../actions';

const INIT_STATE = {
    quetionsByTopic: [],
    quetionsByTopicLoading: false,
    quetionsByTopicError: '',
    submittopicQuesRes: [],
    submittopicQuesError: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case QUESTIONS_BY_TOPIC_ID:
            return { ...state, quetionsByTopicLoading: true };

        case QUESTIONS_BY_TOPIC_ID_SUCCESS:
            return {
                ...state,
                quetionsByTopicLoading: false,
                quetionsByTopic: action.payload,
                quetionsByTopicError: ''
            };
        
        case SUBMIT_QUESTIONS_BY_TOPIC_ID_SUCCESS:
                return {
                    ...state,
                    submittopicQuesRes: action.payload
                };
        
        case SUBMIT_QUESTIONS_BY_TOPIC_ID_ERROR:
                return {
                    ...state,
                    submittopicQuesError: action.payload
                };
    

        case QUESTIONS_BY_TOPIC_ID_ERROR:
            return {
                ...state,
                quetionsByTopicLoading: false,
                quetionsByTopicError: action.payload,
                quetionsByTopic: []
            };

        default:
            return { ...state };
    }
};