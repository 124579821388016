import {
    REGISTER_STUDENT,
    REGISTER_STUDENT_SUCCESSFUL,
    REGISTER_STUDENT_FAILED,
    REGISTER_USER_OTP_SUCCESSFUL, API_REGISTER_OTP_ERROR,
    REGISTER_PARENT_SUCCESSFUL, REGISTER_PARENT_FAILED,
    REGISTER_PARENT_OTP_SUCCESSFUL, API_REGISTER_PARENT_OTP_ERROR
} from '../../actions';

// export const registerStudent = () => {
//     return {
//         type: REGISTER_STUDENT
//     }
// }

export const registerStudentSuccessful = (student) => {
    return {
        type: REGISTER_STUDENT_SUCCESSFUL,
        payload: student
    }
}

export const registerStudentFailed = (error) => {
    return {
        type: REGISTER_STUDENT_FAILED,
        payload: error
    }
}

export const registerUserOTPSuccessful = (user) => {
    return {
        type: REGISTER_USER_OTP_SUCCESSFUL,
        payload: user
    }
}

export const apiregisterOTPError = (error) => {
    return {
        type: API_REGISTER_OTP_ERROR,
        payload: error
    }
}

export const registerParentSuccessful = (student) => {
    return {
        type: REGISTER_PARENT_SUCCESSFUL,
        payload: student
    }
}

export const registerParentFailed = (error) => {
    return {
        type: REGISTER_PARENT_FAILED,
        payload: error
    }
}

export const registerParentOTPSuccessful = (user) => {
    return {
        type: REGISTER_PARENT_OTP_SUCCESSFUL,
        payload: user
    }
}

export const apiregisterParentOTPError = (error) => {
    return {
        type: API_REGISTER_PARENT_OTP_ERROR,
        payload: error
    }
}


export const registerNewStudent = (postData) => {
    return (dispatch, getState, { axios, baseUrl, endpoints }) => {

        return new Promise((resolve, reject) => {
            // dispatch(registerStudent());
            axios.post(baseUrl + endpoints.postStudent, postData)
                .then(response => {
                    return response.data;
                }).then(data => {
                    dispatch(registerStudentSuccessful(data.response.data[0]));
                    resolve();
                }).catch((error) => {
                    dispatch(registerStudentFailed('Something went wrong. Try again after sometime.'));
                    resolve(error);
                });
        });
    }
};

export const registerStudent = (postData) => {
    return (dispatch, getState, { axios, baseUrl, endpoints }) => {
        return axios.post(baseUrl + endpoints.registerStudent, postData)
            .then(response => {
                return response.data;
            }).then(data => {
                dispatch(registerUserOTPSuccessful(data.response.data[0]));
            }).catch((error) => {
                dispatch(apiregisterOTPError('user already exists'));
            });
    }
};

export const registerParent = (postData) => {
    return (dispatch, getState, { axios, baseUrl, endpoints }) => {
        return axios.post(baseUrl + endpoints.parentsendotp, postData)
            .then(response => {
                return response.data;
            }).then(data => {
                dispatch(registerParentSuccessful(data.response.data[0]));
            }).catch((error) => {
                dispatch(registerParentFailed('user already exists'));
            });
    }
};

export const registerParentOTP = (postData) => {
    return (dispatch, getState, { axios, baseUrl, endpoints }) => {
        return axios.post(baseUrl + endpoints.parent, postData)
            .then(response => {
                return response.data;
            }).then(data => {
                dispatch(registerParentOTPSuccessful(data.response.data[0]));
            }).catch((error) => {
                dispatch(apiregisterParentOTPError('user already exists'));
            });
    }
};