import {
    NOTES_GET_DETAILS,
    NOTES_GET_DETAILS_SUCCESS,
    NOTES_GET_DETAILS_ERROR,
    NOTES_ADD_NEW,
    NOTES_GET_DETAILS_BY_TOPICID_AND_STUDENTID
} from '../actions';

const INIT_STATE = {
    notes: [],
    notesLoading: false,
    notesError: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case NOTES_ADD_NEW:
            return {
                ...state,
                notes: [...state.notes, action.payload]
            }
        case NOTES_GET_DETAILS:
            return { ...state, loading: true };

        case NOTES_GET_DETAILS_SUCCESS:
            return { ...state, loading: false, topics: action.payload, error: '' };

        case NOTES_GET_DETAILS_ERROR:
            return { ...state, loading: false, error: action.payload, notes: [] };

        case NOTES_GET_DETAILS_BY_TOPICID_AND_STUDENTID: {

            return {
                ...state,
                notes: state.notes.filter(nts => nts.studentId && nts.topicId !== action.payload)

            }
        }

        default:
            return { ...state };
    }
};