import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from "reactstrap";
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

const NotAuth = ({
}) => {
    return (
        <React.Fragment>
            <div>
                <h1>
                    YOU ARE NOT AUTHORIZED TO VIEW THIS PAGE
                </h1>
            </div>
        </React.Fragment>
    );
};

const mapStatetoProps = ({ }) => {
    return {};
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default withRouter(connect(mapStatetoProps,
    mapDispatchToProps, null, {})(withNamespaces()(NotAuth)));
