import {
    SUBJECTS_GET_DETAILS,
    SUBJECTS_GET_DETAILS_SUCCESS,
    SUBJECTS_GET_DETAILS_ERROR
} from '../actions';

const INIT_STATE = {
    subjects: [],
    subjLoading: false,
    subjError: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SUBJECTS_GET_DETAILS:
            return { ...state, loading: true };

        case SUBJECTS_GET_DETAILS_SUCCESS:
            return { ...state, loading: false, subjects: action.payload, error: '' };

        case SUBJECTS_GET_DETAILS_ERROR:
            return { ...state, loading: false, error: action.payload, boards: [] };

        default:
            return { ...state };
    }
};