import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { Link, useLocation, withRouter } from 'react-router-dom';
import Moment from 'moment';

import backIcon from "../../assets/images/icons/back.png";
import MathsIcon from "../../assets/images/icons/Maths.png";
import MultiSelect from "react-multi-select-component";
import Session from '../../session';
import {
    fetchClassesDetailsByBoard,
    fetchSubjectsDetailsByClass,
    fetchFeesDetailsByClass,
    fetchClassesDetails,

    fetchClassesFeesDetailsByBoard,
    fetchSubscriptionsByStudent,
    addSubscriptions,

    fetchActiveOffers,

} from "../../store/actions";
import { REACT_APP_RAZOR_PAY_KEY } from "../../env-config";


const Pricing = ({
    history,
    onFetchClassesFeesDetailsByBoard,
    classesWithFees,
    onfetchSubscriptionsByStudent,
    subscriptionsByStudent,
    onAddSubscriptions,
    onFetchActiveOffers,
    offersActive,
    subscriptionsAdd
}) => {
    const userSession = Session.get('authUser');
    const [selected, setSelected] = useState([]);
    const [multiSelectData, setMultiSelectData] = useState([]);
    const [promoCodeData, setPromoCodeData] = useState({
        promoCode: '',
        promoSuccessMessage: '',
        promoErrorMessage: ''
    });
    const [totalAmount, setTotalAmount] = useState(0);
    const [expiryDate, setExpiryDate] = useState('');
    const [promoAmount, setPromoAmount] = useState(0);
    const [finalAmount, setFinalAmount] = useState(0);
    const [promoCode, setPromoCode] = useState('');
    const [applyButtonDisabled, setApplyButtonDisabled] = useState(false);

    const [classNamesMap, setClassNamesMap] = useState({});

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [mySubscriptions, setMySubscriptions] = useState([]);

    useEffect(() => {
        // Read boardId from User Session Module
        onFetchClassesFeesDetailsByBoard({
            boardId: userSession.boardId
        });

        // Read studentId from User Session Module
        // onfetchSubscriptionsByStudent({
        //     studentId: userSession.studentId
        // });

        onFetchActiveOffers();
    }, []);

    useEffect(() => {
    }, [classesWithFees]);

    useEffect(() => {

        if (classesWithFees && classesWithFees.length) {
            // Get only valid subscriptions
            var classIdsAry = [];

            var mapClassIdsWithNames = {};
            classesWithFees.forEach(function (cwf) {
                mapClassIdsWithNames[cwf.classId] = cwf.className;
            });

            setClassNamesMap(mapClassIdsWithNames);

            let mySubsList = Session.get("studentSubscriptionsList");
            mySubsList && mySubsList.length && mySubsList.forEach(function (obj) {
                const dueDate = Moment(obj.subrDueDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
                const now = Moment();
                if (
                    dueDate.isValid() &&
                    now.isBefore(dueDate) &&
                    obj.paymentstatus === 's'
                ) {
                    classIdsAry.push(...obj.subrClassesIds);
                }
            });

            let classsesArray = classesWithFees.filter((item) =>
                !classIdsAry.includes(item.classId.toString()),
            );

            classsesArray.forEach((item) => {
                item.label = item.className;
                item.value = item.classId;
            });
            setMultiSelectData(classsesArray);
        }
    }, [mySubscriptions, classesWithFees]);

    useEffect(() => {

    }, [classNamesMap]);

    useEffect(() => {
        // Session.set("studentSubscriptionsList", JSON.stringify(subscriptionsByStudent));
        var stdSubsList = Session.get("studentSubscriptionsList");
        setMySubscriptions(stdSubsList);
    }, [subscriptionsByStudent]);

    useEffect(() => {
        if (selected && selected.length > 0) {
            if (promoCode !== '') {
                setPromoCode('');
                setPromoAmount(0);
                setApplyButtonDisabled(false);
            }
            handleBillingInfo(selected);
        }
    }, [selected]);

    const handleBillingInfo = (selectedClasses) => {
        setExpiryDate(getExpiryDate(selectedClasses));
        setTotalAmount(displayBillingAmount(selectedClasses));
        setFinalAmount(displayBillingAmount(selectedClasses));
    };

    useEffect(() => {
        if (subscriptionsAdd && subscriptionsAdd.length) {
            let addSubResp = subscriptionsAdd[0];

            if (addSubResp && addSubResp.order) {
                var subrData = addSubResp.subrDetails;
                var razorOrderDbData = addSubResp.order;

                if (razorOrderDbData.id) {
                    const options = {
                        key: REACT_APP_RAZOR_PAY_KEY,
                        name: 'Purchase',
                        description: "InfeLearn",
                        currency: razorOrderDbData.currency,
                        amount: razorOrderDbData.amount,
                        order_id: razorOrderDbData.id,
                        handler: async function (response) {
                            // const data = {
                            //     orderCreationId: razorOrderDbData.id,
                            //     razorpayPaymentId: response.razorpay_payment_id,
                            //     razorpayOrderId: response.razorpay_order_id,
                            //     razorpaySignature: response.razorpay_signature,
                            // };

                            subrData.paymentstatus = 's';
                            var existingSubs = Session.get("studentSubscriptionsList");
                            existingSubs.push(subrData);
                            Session.set("studentSubscriptionsList", JSON.stringify(existingSubs));

                            setTimeout(() => {
                                setMySubscriptions(Session.get("studentSubscriptionsList"));
                            }, 2000);

                            // window.location = window.location;
                            // setSelected([]);
                            // setPromoCode('');
                            // history.push({
                            //     pathname: '/pricing'
                            // });
                            window.location.reload();
                        },
                        prefill: {},
                        theme: {
                            color: "#686CFD",
                        },
                        modal: {
                            ondismiss: function () {
                                // console.log("Modal closed");
                            },
                        },
                    };
                    const rzp1 = new window.Razorpay(options);

                    rzp1.open();
                } else {
                    alert("Seems payment gateway is down");
                }
            } else {
                // alert("Seems payment gateway is down");
            }
        }
    }, [subscriptionsAdd]);

    const handleSubscribe = async () => {
        const subrClassIds = function () {
            var classIdsList = [];
            selected.forEach((sele) => {
                classIdsList.push(sele.classId);
            });
            return classIdsList;
        };

        const subsrData = {
            studentId: userSession.studentId,
            subrClassesIds: subrClassIds(),
            subrDueDate: expiryDate,
            feesPaid: (finalAmount + Number((Number(finalAmount) * 18) / 100)).toFixed(2),
            studentStatus: true,
            promoCode: promoCode
        };

        onAddSubscriptions(subsrData);

        // const addSubResp = await onAddSubscriptions(subsrData);
        // onAddSubscriptions(subsrData)

        // history.push({
        //     pathname: '/student'
        // });
    };
    const displayBillingAmount = (selected) => {
        var totalBill = 0;

        selected.map((sele) => {
            totalBill += Number(sele.finalFees);
        });

        return totalBill;
        // return totalBill.toFixed(2);
    };
    const getExpiryDate = (selected) => {
        var currYear = new Date().getFullYear();
        var futureYear = currYear + selected.length;

        return new Date(futureYear, '04', '30');
    };
    const displayExpiryDate = (date) => {
        const dDate = new Date(date);

        return `30th May ${dDate.getFullYear()}`;
    };

    const handleInputChange = (e) => {
        if (e && e.target && e.target.value) {
            e.preventDefault();

            var targetVal = e.target.value;
            setPromoCodeData({
                promoCode: targetVal
            });
            setPromoCode(targetVal);
        } else {
            setPromoCode('');
        }
    };

    const handlePromocode = () => {
        setApplyButtonDisabled(true);
        const prCode = promoCodeData.promoCode;
        const appliedOfferList = offersActive.filter((offer) => offer.offerPromoCode === prCode);

        if (appliedOfferList && appliedOfferList.length) {
            const appliedOffer = appliedOfferList[0];

            if (appliedOffer.numOfClasses == selected.length) {
                setPromoCodeData({
                    promoCode: prCode,
                    promoSuccessMessage: 'Success!! Promocode applied',
                    promoErrorMessage: ''
                });
                const offerPercent = appliedOffer.offerPercentage;
                const amountToDeduct1 = Number((offerPercent / 100 * (finalAmount)).toFixed(2));
                const amountAfterPromo1 = Number(((finalAmount) - amountToDeduct1).toFixed(2));

                setPromoAmount(amountToDeduct1);
                setFinalAmount(amountAfterPromo1);
            } else {
                setPromoCodeData({
                    promoErrorMessage: `Select at least ${appliedOffer.numOfClasses} classes to apply this Promocode`,
                    promoSuccessMessage: ''
                });
            }
        } else {
            setPromoCodeData({
                promoErrorMessage: 'Promocode doesn\'t exists',
                promoSuccessMessage: ''
            });
            setApplyButtonDisabled(false);
        }
    };

    const handleRemovePromocode = (e) => {
        setPromoAmount(0);
        setFinalAmount(finalAmount + promoAmount);
        setPromoCode('');
        setPromoCodeData({
            promoCode: '',
            promoSuccessMessage: '',
            promoErrorMessage: ''
        });
        setApplyButtonDisabled(false);
    };

    const displayClassNames = (list) => {
        var classNameStr = '';

        if (classNamesMap) {
            if (list && list.length) {
                list.forEach((item, index) => {
                    if (classNamesMap[item]) {
                        classNameStr += ' ' + classNamesMap[item] + ',';
                    } else {
                        classNameStr += ' ' + 'Class' + ',';
                    }
                });
            }
        }

        if (classNameStr.length > 0) {
            classNameStr = classNameStr.substring(0, classNameStr.length - 1);
        }

        return classNameStr;
    };

    const displaySubDueDate = (date) => {
        return Moment(date).format('DD MMMM YYYY');
    };

    const displaySubState = (date) => {
        let subState = Moment(date).isAfter(Moment.now());

        return subState ? (<h6 style={{ color: 'green', fontWeight: 'bold' }}>Active</h6>) :
            (<h6 style={{ color: 'red', fontWeight: 'bold' }}>Expired</h6>);
    };

    // 
    const handleBillWithTaxes = (finalAmount) => {
        return (finalAmount + Number((Number(finalAmount) * 18) / 100)).toFixed(2);
    };

    return (

        <div className="page-content">
            <Container fluid>
                <div className="row">
                    <div className="col-sm-4">
                        <div className="courseHeadigMaindiv">
                            <div className="arrowandIcondiv">
                                <Link to="student"><img src={backIcon} /></Link>
                                <img className="card-img-top" style={{ width: "46%" }} src={MathsIcon} alt="Card image cap" />
                            </div>
                            <div className="courseHeadingdiv">
                                <span className="subjHeading">View Pricing</span><br />
                                <span className="cardTextFont">Select Package</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-2">
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-sm-8">
                        <div className="row">
                            <div className="col-sm-5">
                                <label>Select Class</label>
                                <MultiSelect className="dropdownborderradius"
                                    options={multiSelectData}
                                    value={selected}
                                    onChange={setSelected}
                                    labelledBy="Select"
                                />
                                <span className="multipleSelectText">You can select multiple classes at a time</span>
                            </div>
                            <div className="col-sm-4">
                                <label>Add Promo Code</label>
                                <input type="text" className="form-control dropdownborderradius"
                                    value={promoCode}
                                    onChange={(e) => handleInputChange(e)} />
                                <div style={{
                                    position: "absolute", right: 0, top: "29px"
                                }} hidden={promoCode.length <= 0}>
                                    <button className="btn btn-default buttonCss"
                                        onClick={() => handleRemovePromocode()}>
                                        X
                                    </button>
                                </div>
                                <span className="invalid-feedback d-block">
                                    {promoCodeData.promoErrorMessage}</span>
                                <span className="multipleSelectText">
                                    {promoCodeData.promoSuccessMessage}</span>
                            </div>
                            <div className="col-sm-3">
                                {/* <span className="multipleSelectText">View Offers</span> */}
                                <a className="multipleSelectText" href="#"
                                    onClick={handleShow}>View Offers</a>
                                {/* <Button variant="primary" onClick={handleShow}>
                                    Launch demo modal
                                </Button> */}
                                <button
                                    className="btn btn-default buttonCss" style={{ marginTop: "7px" }}
                                    onClick={() => handlePromocode()} hidden={applyButtonDisabled}>
                                    Apply Promocode
                                </button>

                                {/* <button className="btn btn-default buttonCss" style={{ marginTop: "27px" }}
                                    onClick={() => handleRemovePromocode()} hidden={promoCode.length <= 0}>
                                    Remove Promocode
                                </button> */}
                            </div>
                        </div>
                        {selected.length > 0 ? <div className="billingInfoDiv">
                            <div className="row">
                                <div className="col-sm-12 selectedClassesText">
                                    Selected classes:  {
                                        selected.map((sel, index) => {
                                            return (
                                                <span key={index}>
                                                    {sel.className}
                                                </span>
                                            )
                                        })
                                    }
                                </div>
                                <div className="col-sm-12 validDateText">
                                    <div>{displayExpiryDate(expiryDate)}</div>
                                </div>
                                <div className="col-sm-12 billingInfoText">Billing Information </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-8">
                                    <div className="row subTotalRow">
                                        <div className="col-sm-6 subTotalHeading">Sub total</div>
                                        <div className="col-sm-6 subTotalAmount">
                                            {totalAmount}
                                        </div>
                                    </div>
                                    <div className="row totalRow">
                                        <div className="col-sm-6">Discount Amount</div>
                                        <div className="col-sm-6 totalAmount">{promoAmount}</div>
                                    </div>
                                    <div className="row totalRow">
                                        <div className="col-sm-6 totalHeading">Amount to pay</div>
                                        <div className="col-sm-6 totalAmount">{handleBillWithTaxes(finalAmount)}</div>
                                    </div>
                                    <div className="row taxesRow">
                                        <div className="col-sm-6 taxesHeading">Taxes</div>
                                        <div className="col-sm-6 taxesAmount">
                                            {Number((finalAmount * 18) / 100).toFixed(2)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row col-sm-6">
                                <button className="btn btn-default buttonCss" onClick={() => handleSubscribe()}>Subscribe</button>
                            </div>
                        </div> : ''}

                    </div>
                    <div className="col-sm-4 offersColumn">
                        <span className="offersHeading">My Subscriptions</span>
                        {
                            (mySubscriptions || subscriptionsByStudent) && (mySubscriptions || subscriptionsByStudent).length > 0 ?
                                <>
                                    {Array.isArray(mySubscriptions || subscriptionsByStudent) &&
                                        (mySubscriptions || subscriptionsByStudent).map((substd, index) => {
                                            return (
                                                <div className="row" key={index}>
                                                    <div className="col-sm-12">
                                                        <div className="card cardDivInPricing">
                                                            <span className="promoCodeHeadinginPricing">
                                                                {displayClassNames(substd.subrClassesIds)}
                                                            </span>
                                                            <span className="promocodeInfo">
                                                                Valid till: {displaySubDueDate(substd.subrDueDate)}</span>
                                                            <div style={{ position: "absolute", right: "13px" }}>
                                                                {displaySubState(substd.subrDueDate)}
                                                                {/* {displaySubState('2021-05-27T18:30:00.000Z')} */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </> : (<div className="row">
                                    <div className="col-sm-12">
                                        <div className="card cardDivInPricing">
                                            <h6>You don't have any subscriptions yet!!</h6>
                                        </div>
                                    </div>
                                </div>)
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <Modal isOpen={show} tabIndex="-1" role="dialog">
                            <ModalHeader>
                                <div className="row">
                                    <div className="col-sm-11">
                                        <strong>Offers</strong>
                                    </div>
                                    <div className="col-sm-1" style={{ position: "absolute", right: 0, cursor: "pointer" }}>
                                        <span onClick={handleClose}>X</span>
                                    </div>
                                </div>
                            </ModalHeader>
                            <ModalBody>
                                {
                                    offersActive && offersActive.length > 0 ?
                                        <>
                                            {offersActive.map((offer, index) => {
                                                return (
                                                    <div className="row" key={index}>
                                                        <div className="col-sm-12">
                                                            <div className="card cardDivInPricing">
                                                                <span className="promoCodeHeadinginPricing">{offer.offerPromoCode}</span>
                                                                <span className="promocodeInfo">{offer.offerDescription}</span>
                                                                <span className="promoValidDate">Valid until {offer.offerEndDate}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </> : (<div className="row">
                                            <div className="col-sm-12">
                                                <div className="card cardDivInPricing">
                                                    <h3>No active offers</h3>
                                                </div>
                                            </div>
                                        </div>)
                                }
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                </div>
            </Container>

        </div>


    );

}


const mapStateToProps = ({ ClassesApp,
    SubjectsApp,
    PricingApp,
    ClassFeeDetailsApp,
    SubscriptionsApp,
    OffersApp
}) => {
    const { classes, classesLoading, classesError } = ClassesApp;
    const { subjects, subjLoading, subjError } = SubjectsApp;
    const { feesDetailsByClass, feesLoading, feesError } = PricingApp;
    const { classesWithFees, classesWithFeesLoading, classesWithFeesError } = ClassFeeDetailsApp;
    const { subscriptionsByStudent, subscriptionsByStudentLoading,
        subscriptionsByStudentError, subscriptionsAdd } = SubscriptionsApp;
    const { offersActive, offersActiveLoading, offersActiveError } = OffersApp;

    return {
        classes, classesLoading, classesError,
        subjects, subjLoading, subjError,
        feesDetailsByClass, feesLoading, feesError,
        classesWithFees, classesWithFeesLoading, classesWithFeesError,
        subscriptionsByStudent,
        subscriptionsByStudentLoading,
        subscriptionsByStudentError,

        offersActive,

        subscriptionsAdd
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

        onFetchClassesDetailsByBoard: (boardData) => dispatch(fetchClassesDetailsByBoard(boardData)),
        onFetchSubjectsDetailsByClass: (classData) => dispatch(fetchSubjectsDetailsByClass(classData)),
        onFetchFeesDetailsByClass: (classData) => dispatch(fetchFeesDetailsByClass(classData)),
        onFetchClassesDetails: () => dispatch(fetchClassesDetails()),


        onFetchClassesFeesDetailsByBoard: (boardData) => dispatch(fetchClassesFeesDetailsByBoard(boardData)),
        onfetchSubscriptionsByStudent: (studentData) => dispatch(fetchSubscriptionsByStudent(studentData)),

        onAddSubscriptions: (subscribeData) => dispatch(addSubscriptions(subscribeData)),
        onFetchActiveOffers: () => dispatch(fetchActiveOffers()),
    }
};



export default withRouter(connect(mapStateToProps, mapDispatchToProps, null, {})(Pricing));