import React, { Component, useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
//i18n
import { withNamespaces } from "react-i18next";
// users
import avatar2 from '../../../assets/images/users/avatar-2.jpg';
import Session from '../../../session';

const ProfileMenu = ({

}) => {
    let [userName, setUserName] = useState('');
    let [studentPhoto, setStudentPhoto] = useState();
    let [menu, setMenu] = useState(false);

    useEffect(() => {
        const stdData = Session.get('authUser');
        setStudentPhoto(stdData.studentPhoto)
        if (Session.get("authUser")) {
            const obj = localStorage.getItem("authUser");
            if (obj) {
                setUserName(obj.studentName);
            }
        }
    }, []);

    const toggle = () => {
        setMenu(!menu);
    };

    return (
        <React.Fragment>
            <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block user-dropdown">
                <DropdownToggle tag="button" className="btn header-item waves-effect" id="page-header-user-dropdown">
                    <img className="rounded-circle header-profile-user mr-1" src={studentPhoto} />
                    <span className="d-none d-xl-inline-block ml-1 text-transform">{userName}</span>
                    <i className="mdi mdi-chevron-down d-none ml-1 d-xl-inline-block"></i>
                </DropdownToggle>

                <DropdownMenu right>
                    {/* <DropdownItem href="#"><i className="ri-user-line align-middle mr-1"></i> {this.props.t('Profile')}</DropdownItem>
                                <DropdownItem href="#"><i className="ri-wallet-2-line align-middle mr-1"></i> {this.props.t('My Wallet')}</DropdownItem>
                                <DropdownItem className="d-block" href="#"><span className="badge badge-success float-right mt-1">11</span><i className="ri-settings-2-line align-middle mr-1"></i> {this.props.t('Settings')}</DropdownItem>
                                <DropdownItem href="#"><i className="ri-lock-unlock-line align-middle mr-1"></i> {this.props.t('Lock screen')}</DropdownItem>
                                <DropdownItem divider /> */}
                    <DropdownItem href="/editstudent"><i className="ri-user-line align-middle mr-1"></i>Edit Profile</DropdownItem>
                    <DropdownItem className="text-danger" href="/logout">
                        <i className="ri-shut-down-line align-middle mr-1 text-danger"></i>
                        {/* {this.props.t('Logout')} */}
                        Logout
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    )
};

export default withNamespaces()(ProfileMenu);
