import React, { Component, useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Session from '../../session/'

const Logout = ({
    history
}) => {
    useEffect(() => {
        Session.clear();
        history.push({
            pathname: '/login'
        });
    }, []);

    return (
        <>
        </>
    )
};

const mapStatetoProps = () => {
    return {};
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default withRouter(connect(mapStatetoProps, mapDispatchToProps, null, {})(withNamespaces()(Logout)));