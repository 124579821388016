import React, { useEffect, useState } from "react";
import { Row, Col, Button, Alert, Container, Label, FormGroup } from "reactstrap";
import SelectClassVector from '../../assets/images/Select_Class_Vector.png';

// Redux
import { connect } from "react-redux";
import { Link, useLocation, withRouter } from "react-router-dom";
import {
    fetchBoardsDetails,
    fetchClassesDetailsByBoard,
    registerNewStudent, registerStudentOTP, updateStudent, resetDefault
} from "../../store/actions";

// import images
import logodark from "../../assets/images/black.svg";
import Session from '../../session';


const SelectBoardandClass = ({
    history,
    onFetchBoardsDetails,
    boards, boardsLoading, boardsError,
    onFetchClassesDetailsByBoard,
    classes, classesLoading, classesError,
    onRegisterNewStudent,
    onResetDefault,
    student, studentError, studentLoading,
    loginUser,
    loginError,
    loginMessage,
    onRegisterStudentOTP,
    onUpdateStudent
}) => {
    const location = useLocation();
    const [errors, setError] = useState({});
    const [enableUpdate, setUpdate] = useState(false);

    useEffect(() => {
        onFetchBoardsDetails();
        document.body.classList.add("auth-body-bg");
    }, [location]);

    useEffect(() => {
        if ((loginMessage == 'register auth' || loginMessage == 'update user') && loginError == '' && loginUser && loginUser.length) {
            (loginMessage == 'update user') ?  Session.set('authUser', JSON.stringify(location.state)) : Session.set('authUser', JSON.stringify(loginUser[0]));
            (loginMessage == 'update user') ?  onResetDefault(JSON.stringify(location.state)) : onResetDefault(JSON.stringify(loginUser[0]));
            history.push({
                pathname: '/student'
            });
        }
    }, [loginUser, loginError]);

    const handleDropdownBoardChange = (e) => {
        if (e && e.target && e.target.value && e.target.value !== '0') {
            const ddlBoardValue = e.target.value;
            location.state.boardName = e.target.selectedOptions[0].title;
            location.state.boardId = ddlBoardValue;
            onFetchClassesDetailsByBoard({ boardId: ddlBoardValue });
            setError(prevState => ({
                ...prevState,
                board: '',
            }));
        } else {
            location.state.boardId = undefined;
            setError(prevState => ({
                ...prevState,
                board: 'Select a valid Board',
            }));
            // alert('Select a valid Board');
        }
    }

    const handleDropdownClassChange = (e) => {
        if (e && e.target && e.target.value && e.target.value !== '0') {
            const studentClassId = e.target.value;
            location.state.className = e.target.selectedOptions[0].title;
            location.state.studentClassId = studentClassId;
            setError(prevState => ({
                ...prevState,
                class: '',
            }));
        } else {
            location.state.studentClassId = undefined;
            setError(prevState => ({
                ...prevState,
                class: 'Select a valid Class',
            }));
            // alert('Select a valid Class');
        }
    }

    useEffect(() => {
        if (student && student.length) {
            Session.set('authUser', JSON.stringify(student[0]));
            history.push({
                pathname: '/student'
            });
        }
    }, [student, studentError]);

    useEffect(() => {
        if(location.state.boardId && location.state.studentClassId) {
            setUpdate(true);
        }
    }, []);

    const handleStartLearning = (e) => {
        e.preventDefault();
        if (location && location.state) {
            setError(prevState => ({
                ...prevState,
                board: (location.state.boardId)? "" : 'Select a valid Board',
                class: (location.state.studentClassId)? "" : 'Select a valid Class'
            }));
        }
        if (location && location.state && location.state.boardId && location.state.studentClassId) {
            let studentData = location.state;
            onRegisterStudentOTP(studentData);
        }

        // if (location && location.state) {
        //     let studentData = location.state;
        //     await onRegisterNewStudent(studentData);
        //     if (studentError) {
        //         alert(JSON.stringify(studentError));
        //     } else {
        //         console.log(JSON.stringify(student));
        //         history.push({
        //             pathname: '/student',
        //             state: student
        //         });
        //     }
        // }
    }

    const handleUpdateClass = (e) => {
        e.preventDefault();
        if (location && location.state) {
            setError(prevState => ({
                ...prevState,
                board: (location.state.boardId)? "" : 'Select a valid Board',
                class: (location.state.studentClassId)? "" : 'Select a valid Class'
            }));
        }
        if (location && location.state && location.state.boardId && location.state.studentClassId) {
            let studentData = {
                "studentId": location.state.studentId,
                "studentName": location.state.studentName,
                "boardId": location.state.boardId,
                "boardName": location.state.boardName,
                "className": location.state.className
            }

            onUpdateStudent(studentData);
        }
    }

    return (
        <React.Fragment>
            <div>
                <Container fluid className="p-0">
                    <Row className="no-gutters">
                        <Col lg={4}>
                            <div className="">
                                <div className="w-100">
                                    <Row className="justify-content-center">
                                        <Col lg={9}>
                                            <div>
                                                <div className="text-center">
                                                    <div>
                                                        <Link to="/" className="logoImg"><img src={logodark} height="50" alt="logo" /></Link>
                                                    </div>

                                                    <h4 className="font-size-18 welbackFontWeight">Welcome Back !
                                                        {JSON.stringify(student)}
                                                    </h4>
                                                    <p className="text-muted">Choose board and class to continue.</p>
                                                </div>

                                                <div id="registrationSelectBoardandClassDiv">
                                                    <div className="p-2 mt-5">
                                                        <h6 className="font-size-15 welbackFontWeight">Select Board</h6>
                                                        <select className="form-control iputFildBorderRadius" onChange={(e) => handleDropdownBoardChange(e)} value={location.state.boardId}>
                                                            <option value="0" title="">Select Board</option>
                                                            {boards.map((board, index) => {
                                                                return (
                                                                    <option key={index} value={board.boardId} title={board.boardName}>{board.boardName}</option>
                                                                )
                                                            })}
                                                        </select>
                                                        {errors.board ? (
                                                                <div className="invalid-feedback d-block">
                                                                {errors.board}
                                                                </div>
                                                            ) : ''}
                                                        <br />
                                                        <h6 className="font-size-15 welbackFontWeight">Select Class</h6>
                                                        <select className="form-control iputFildBorderRadius" onChange={(e) => handleDropdownClassChange(e)} value={location.state.studentClassId}>
                                                            <option value="0" title="">Select Board</option>
                                                            {classes.map((cls, index) => {
                                                                return (
                                                                    <option key={index} value={cls.classId} title={cls.className}>{cls.className}</option>
                                                                )
                                                            })}
                                                        </select>
                                                        {errors.class ? (
                                                                <div className="invalid-feedback d-block">
                                                                {errors.class}
                                                                </div>
                                                            ) : ''}
                                                        <br />
                                                        {/* <Link to="/student"> <button className="btn btn-default buttonCss">Start Learning</button></Link><br /> */}
                                                        {enableUpdate? (<button className="btn btn-default buttonCss"
                                                            onClick={handleUpdateClass}>
                                                            Update
                                                        </button>): (<button className="btn btn-default buttonCss"
                                                            onClick={handleStartLearning}>
                                                            Start Learning
                                                        </button>)}
                                                    </div>
                                                </div>

                                            </div>

                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <img src={SelectClassVector} style={{ width: "100%" }}></img>
                            {/* <div className="selectClassVector">
                            </div> */}
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );

}

const mapStateToProps = ({ BoardsApp, ClassesApp, Account, Login }) => {
    const { boards, boardsLoading, boardsError } = BoardsApp;
    const { classes, classesLoading, classesError } = ClassesApp;
    const { student, studentError, studentLoading } = Account;
    const { loginUser, loginError, loginMessage } = Login;

    return {
        boards, boardsLoading, boardsError,
        classes, classesLoading, classesError,
        student, studentError, studentLoading,
        loginUser, loginError, loginMessage
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchBoardsDetails: () => dispatch(fetchBoardsDetails()),
        onFetchClassesDetailsByBoard: (boardData) => dispatch(fetchClassesDetailsByBoard(boardData)),
        onRegisterNewStudent: (studentData) => dispatch(registerNewStudent(studentData)),
        onRegisterStudentOTP: (postData) => dispatch(registerStudentOTP(postData)),
        onUpdateStudent: (postData) => dispatch(updateStudent(postData)),
        onResetDefault: (postData) => dispatch(resetDefault(postData))
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps, null, {})(SelectBoardandClass));
