import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Container, } from "reactstrap";


import Session from "../../session/";
import { startLiveClass } from "../../store/liveclass/actions";
import { fetchBoardsDetails, fetchClassesDetailsByBoard, fetchSubjectsDetailsByClass } from "../../store/actions";

const TeacherClass = ({
  history,
  boards,
  classes,
  subjects,
  fetchBoardsDetails,
  fetchClassesDetailsByBoard,
  fetchSubjectsDetailsByClass,
  startLiveClass,
}) => {
  const [teacherData, setTeacherData] = useState({});
  const [selectedBoard, setSelectedBoard] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);

  useEffect(() => {
    const teacherData = Session.get("authUser");
    if (!teacherData) {
      history.push({
        pathname: "/login",
      });
      return
    }

    setTeacherData(teacherData);
    fetchBoardsDetails();
  }, []);

  useEffect(() => {
    setSelectedClass(null)
  }, [selectedBoard])

  const handleChangeBoard = (e) => {
    const boardId = e.target.value;
    if (!boardId) return
    const boardData = boards.find((board) => board.boardId === parseInt(boardId));
    fetchClassesDetailsByBoard(boardData)
    setSelectedBoard(boardData);
  };

  const handleChangeClass = (e) => {
    const classId = e.target.value;
    if (!classId) return
    const classData = classes.find((classData) => classData.classId === parseInt(classId));
    fetchSubjectsDetailsByClass(classData)
    setSelectedClass(classData);
  };

  const handleStartOrJoinLiveClass = async (subj) => {
    // call join-live-class api
    const data = await startLiveClass(subj.subjectId)

    console.log({ data })

    // push to liveclass page
    history.push({
      pathname: "/liveclass/" + subj.subjectId,
      state: {
        subject: subj,
        data,
      },
    });
  }

  return (
    <div className="page-content">
      <Container fluid>
        <div className="row">
          <div className="col-sm-4 studentNameandClassDiv">
            <h4>
              Hello{" "}
              {teacherData && teacherData.name
                ? teacherData.name
                : ""}
            </h4>
            <p>
              {selectedBoard?.boardName}
              {" "}
              {selectedClass?.className}
            </p>

          </div>
          <div>
            <select className="form-control iputFildBorderRadius" onChange={handleChangeBoard} value={selectedBoard?.boardId || ""}>
              <option value="">Select Board</option>
              {boards.map(board => (
                <option
                  key={board.boardId}
                  value={board.boardId}
                >
                  {board.boardName}
                </option>
              ))}
            </select>
            <select className="form-control iputFildBorderRadius" onChange={handleChangeClass} value={selectedClass?.classId || ""}>
              <option value="">Select Class</option>
              {classes.map(c => (
                <option
                  key={c.classId}
                  value={c.classId}
                >
                  {c.className}
                </option>
              ))}
            </select>
          </div>
        </div>
        <br />
        <br />

        <h4>Courses</h4>
        {!(selectedBoard && selectedClass) ? <div>
          Please select board and class to see courses
        </div> : (
          <div className="row">
            {subjects.map((subj, index) => {
              return (
                <div key={index} className="col-sm-2">
                  <div className="card cardDiv">
                    <img
                      className="card-img-top cardImgTop"
                      src={subj.subjectImageUrl}
                      alt=""
                    />
                    <div className="card-body">
                      <h5
                        className="card-title textSubjectName"
                        title={subj.subjectName}
                      >
                        {subj.subjectName}
                      </h5>
                      {/* <p className="card-text cardTextFont">38 Chapters | 80 Lessons</p> */}
                      <div className="subjectCardBtns">
                        <button
                          className="btn btn-defult btnViewCourse"
                          onClick={(e) => handleStartOrJoinLiveClass(subj)}
                        >
                          {subj.isLive ? "Join Class" : "Start Class"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </Container>
    </div>
  );
};

const mapStateToProps = ({
  SubjectsApp,
  BoardsApp,
  ClassesApp
}) => {
  const { subjects } = SubjectsApp;
  const { boards, } = BoardsApp;
  const { classes } = ClassesApp;

  return {
    boards,
    classes,
    subjects,
  };
};

const mapDispatchToProps = ({
  fetchBoardsDetails,
  fetchClassesDetailsByBoard,
  fetchSubjectsDetailsByClass,
  startLiveClass
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, {})(TeacherClass)
);
