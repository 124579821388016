import {
    CHAPTERS_GET_DETAILS,
    CHAPTERS_GET_DETAILS_ERROR,
    CHAPTERS_GET_DETAILS_SUCCESS
} from '../actions';

const INIT_STATE = {
    chapters: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CHAPTERS_GET_DETAILS:
            return { ...state };

        case CHAPTERS_GET_DETAILS_SUCCESS:
            return { ...state, chapters: action.payload, error: '' };

        case CHAPTERS_GET_DETAILS_ERROR:
            return { ...state, chapters: [] };

        default:
            return { ...state };
    }
};