/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { toast } from 'react-toastify';
import {
  QUESTIONS_BY_TOPIC_ID,
  QUESTIONS_BY_TOPIC_ID_SUCCESS,
  QUESTIONS_BY_TOPIC_ID_ERROR, SUBMIT_QUESTIONS_BY_TOPIC_ID_SUCCESS, SUBMIT_QUESTIONS_BY_TOPIC_ID_ERROR,
  SUBMIT_VIEWED_BY_TOPIC_SUCCESS, SUBMIT_VIEWED_BY_TOPIC_ERROR
} from '../actions';

export const getQuestionsByTopicId = () => ({
  type: QUESTIONS_BY_TOPIC_ID
});

export const getQuestionsByTopicSuccess = (items) => (
  {
    type: QUESTIONS_BY_TOPIC_ID_SUCCESS,
    payload: items
  }
);

export const getQuestionsByTopicError = (error) => (
  {
    type: QUESTIONS_BY_TOPIC_ID_ERROR,
    payload: error,
  }
);

export const submitQuestionsByTopicSuccess = (items) => (
  {
    type: SUBMIT_QUESTIONS_BY_TOPIC_ID_SUCCESS,
    payload: items
  }
);

export const submitQuestionsByTopicError = (error) => (
  {
    type: SUBMIT_QUESTIONS_BY_TOPIC_ID_ERROR,
    payload: error,
  }
);

export const submitViewdDataSuccess = (items) => (
  {
    type: SUBMIT_VIEWED_BY_TOPIC_SUCCESS,
    payload: items
  }
);

export const submitViewedDataError = (error) => (
  {
    type: SUBMIT_VIEWED_BY_TOPIC_ERROR,
    payload: error,
  }
);

export const fetchQuestionsByTopic = (data) => (dispatch, getState, { axios, baseUrl, endpoints }) => {
  dispatch(getQuestionsByTopicId());
  return axios.post(baseUrl + endpoints.getquestionsfortopic, data)
    .then(response => {
      return response.data;
    }).then(data => {
      dispatch(getQuestionsByTopicSuccess(data.response.data));
      // return data.response.data;
    }).catch(() => {
      dispatch(getQuestionsByTopicError('Something went wrong. Try again after sometime.'));
    });
}

export const fetchQuestionsByTopic1 = (data) => {
  return (dispatch, getState, { axios, baseUrl, endpoints }) => {
    // dispatch(getQuestionsByTopicId());
    // const response = await axios.post(baseUrl + endpoints.getquestionsfortopic, data);
    // return new Promise((resolve, reject) => {
    //   dispatch(getQuestionsByTopicSuccess(response.data.data.response.data));
    //   resolve();
    // });

    return new Promise((resolve, reject) => {
      return axios.post(baseUrl + endpoints.getquestionsfortopic, data)
        .then(response => {
          return response.data;
        }).then(data => {
          // dispatch(getQuestionsByTopicSuccess(data.response.data));
          resolve(dispatch(getQuestionsByTopicSuccess(data.response.data)));
        }).catch(() => {
          // dispatch(getQuestionsByTopicError('Something went wrong. Try again after sometime.'));
          resolve(dispatch(getQuestionsByTopicError('Something went wrong. Try again after sometime.')));
        });
    });
  }
};

export const submitAnswerForTopic = (data) => {
  return (dispatch, getState, { axios, baseUrl, endpoints }) => {
    return new Promise((resolve, reject) => {
      return axios.post(baseUrl + endpoints.submitanswerfortopic, data)
        .then(response => {
          return response.data;
        }).then(data => {
          // dispatch(getQuestionsByTopicSuccess(data.response.data));
          resolve(dispatch(submitQuestionsByTopicSuccess(data.response.data)));
        }).catch(() => {
          // dispatch(getQuestionsByTopicError('Something went wrong. Try again after sometime.'));
          resolve(dispatch(submitQuestionsByTopicError('Something went wrong. Try again after sometime.')));
        });
    });
  }
};

export const submitViewdDataByStudent = (data) => {
  return (dispatch, getState, { axios, baseUrl, endpoints }) => {
    return new Promise((resolve, reject) => {
      return axios.post(baseUrl + endpoints.addtorecent, data)
        .then(response => {
          return response.data;
        }).then(data => {
          // dispatch(getQuestionsByTopicSuccess(data.response.data));
          resolve(dispatch(submitViewdDataSuccess(data.response.data)));
        }).catch(() => {
          // dispatch(getQuestionsByTopicError('Something went wrong. Try again after sometime.'));
          resolve(dispatch(submitViewedDataError('Something went wrong. Try again after sometime.')));
        });
    });
  }
};

export const fetchQuestionsByChapter = (data) => (dispatch, getState, { axios, baseUrl, endpoints }) => {
  dispatch(getQuestionsByTopicId());
  return axios.post(baseUrl + endpoints.getquestionsforchapter, data)
    .then(response => {
      return response.data;
    }).then(data => {
      dispatch(getQuestionsByTopicSuccess(data.response.data));
      // return data.response.data;
    }).catch(() => {
      dispatch(getQuestionsByTopicError('Something went wrong. Try again after sometime.'));
    });
}

export const fetchQuestionsByMode = (data) => (dispatch, getState, { axios, baseUrl, endpoints }) => {
  dispatch(getQuestionsByTopicId());
  return axios.post(baseUrl + endpoints.getquestionsbymode, data)
    .then(response => {
      return response.data;
    }).then(data => {
      dispatch(getQuestionsByTopicSuccess(data.response.data));
      // return data.response.data;
    }).catch(() => {
      dispatch(getQuestionsByTopicError('Something went wrong. Try again after sometime.'));
    });
}

export const submitAnswerForChapter = (data) => {
  return (dispatch, getState, { axios, baseUrl, endpoints }) => {
    return new Promise((resolve, reject) => {
      return axios.post(baseUrl + endpoints.submitanswerforchapter, data)
        .then(response => {
          return response.data;
        }).then(data => {
          // dispatch(getQuestionsByTopicSuccess(data.response.data));
          resolve(dispatch(submitQuestionsByTopicSuccess(data.response.data)));
        }).catch(() => {
          // dispatch(getQuestionsByTopicError('Something went wrong. Try again after sometime.'));
          resolve(dispatch(submitQuestionsByTopicError('Something went wrong. Try again after sometime.')));
        });
    });
  }
};

export const submitAnswerForMode = (data) => {
  return (dispatch, getState, { axios, baseUrl, endpoints }) => {
    return new Promise((resolve, reject) => {
      return axios.post(baseUrl + endpoints.submitanswerbymode, data)
        .then(response => {
          return response.data;
        }).then(data => {
          // dispatch(getQuestionsByTopicSuccess(data.response.data));
          resolve(dispatch(submitQuestionsByTopicSuccess(data.response.data)));
        }).catch(() => {
          // dispatch(getQuestionsByTopicError('Something went wrong. Try again after sometime.'));
          resolve(dispatch(submitQuestionsByTopicError('Something went wrong. Try again after sometime.')));
        });
    });
  }
};