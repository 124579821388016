/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { toast } from 'react-toastify';
import {
  TOPICS_GET_DETAILS,
  TOPICS_GET_DETAILS_SUCCESS,
  TOPICS_GET_DETAILS_ERROR,
  TOPICS_GET_RECENTS_SUCCESS, TOPICS_GET_RECENTS_ERROR
} from '../actions';

export const getTopicsDetails = () => ({
  type: TOPICS_GET_DETAILS
});

export const getTopicsDetailsSuccess = (items) => ({
  type: TOPICS_GET_DETAILS_SUCCESS,
  payload: items
});

export const getTopicsDetailsError = (error) => ({
  type: TOPICS_GET_DETAILS_ERROR,
  payload: error,
});

export const getRecentsSuccess = (items) => ({
  type: TOPICS_GET_RECENTS_SUCCESS,
  payload: items
});

export const getRecentsError = (error) => ({
  type: TOPICS_GET_RECENTS_ERROR,
  payload: error,
});

export const fetchTopicsDetails = () => {
  return (dispatch, getState, { axios, baseUrl, endpoints }) => {

    dispatch(getTopicsDetails());

    return axios.get(baseUrl + endpoints.getTopics)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(getTopicsDetailsSuccess(data.response.data));
      }).catch(() => {
        dispatch(getTopicsDetailsError('Something went wrong. Try again after sometime.'));
      });
  }
};

export const fetchTopicsDetailsByChapter = (chapterData) => {
  return (dispatch, getState, { axios, baseUrl, endpoints }) => {
    return axios.post(baseUrl + endpoints.getTopicsByChapter, { "chapterId": parseInt(chapterData.chapterId) })
      .then(response => {

        return response.data;
      }).then(data => {
        dispatch(getTopicsDetailsSuccess(data.response.data));
      }).catch(() => {

        dispatch(getTopicsDetailsError('Something went wrong. Try again after sometime.'));
      });
  }
};

export const getRecents = (postData) => {
  return (dispatch, getState, { axios, baseUrl, endpoints }) => {
      return axios.post( baseUrl + endpoints.getrecents, postData)
          .then(response => {
              return response.data;
          }).then(data => {
              dispatch(getRecentsSuccess(data.response.data));
          }).catch((error) => {
              dispatch(getRecentsError('No recent videos to show'));
          });
  }
};