import React from 'react';

class Utility extends React.Component {

    constructor(props) {
        super(props);
    }

    getSubscriptionFlag(subClasses, id) {
        let subscribed = false;
        if (subClasses.length > 0) {
            subClasses.map((val) => {
                if (Array.isArray(val.subrClassesIds)) {
                    val.subrClassesIds.map((value) => {
                        if (value == id) {
                            subscribed = true;
                        }
                    })
                }
            });
            return subscribed;
        }
    }

}
export default new Utility();
