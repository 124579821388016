import React, { Component, useState, useEffect } from "react";
import { Link, useLocation, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col, Media } from "reactstrap";
import SimpleBar from "simplebar-react";

//i18b
import { withNamespaces } from "react-i18next";
import Session from '../../../session/';
import notificationsIcon from "../../../assets/images/icons/notifications.png";


const NotificationDropdown = ({
    notificationsData, notificationsDataLoading, notificationsDataError
}) => {

    let [menu, setMenu] = useState(false);
    let [notifications, setNotifications] = useState([]);
    const [isParent, setIsParent] = useState(false);

    useEffect(() => {
        const data = Session.get('authUser');
        if (data) {
            if(data.studentId) {
                setIsParent(false);
            } else {
                setIsParent(true);
            }
        }
    }, []);

    useEffect(() => {
        if (notificationsData.length) {
            setNotifications(notificationsData);
        }
    }, [notificationsData, notificationsDataError]);


    const toggle = () => {
        setMenu(!menu);
    };
    return (
        <React.Fragment>
            <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
                <DropdownToggle tag="button" className="btn header-item noti-icon waves-effect" id="page-header-notifications-dropdown">
                    <i className="ri-notification-3-line"></i>
                    <span className="noti-dot"></span>
                </DropdownToggle>
                <DropdownMenu right className="dropdown-menu-lg p-0"
                    aria-labelledby="page-header-notifications-dropdown">
                    <div className="p-3">
                        <Row className="align-items-center">
                            <Col>
                                <h6 className="m-0"> Notifications </h6>
                            </Col>
                            <div className="col-auto">
                                    <Link to="/notifications" className="small"> View All</Link>
                            </div>
                        </Row>
                    </div>
                    {isParent ? (
                        <Link className="text-reset notification-item">
                            <Media>
                                    <Media body>
                                            <h6 className="mt-0 mb-1 subjectError">No notifications to display</h6>
                                        </Media>
                            </Media>
                        </Link>
                    ) : (
                        <SimpleBar style={{ maxHeight: "230px" }}>
                        {notifications && notifications.length ? (
                            notifications.map((data, index) => {
                                return (
                                    <Link key={index} className="text-reset notification-item">
                                        <Media>
                                            <div className="avatar-xs mr-3">
                                                <img src={notificationsIcon} style={{ width: "100%", height: "100%", background: "black", padding: "5px" }} />
                                            </div>
                                            <Media body>
                                                <h6 className="mt-0 mb-1">{data.notifytitle}</h6>
                                                <div className="font-size-12 text-muted">
                                                    <p className="mb-1">{data.notifytext}</p>
                                                </div>
                                            </Media>
                                        </Media>
                                    </Link>
                                )
                            })
                        ) : (
                            <Link className="text-reset notification-item">
                                <Media>
                                        <Media body>
                                                <h6 className="mt-0 mb-1 subjectError">No notifications to display</h6>
                                            </Media>
                                </Media>
                            </Link>
                        )}
                        
                    </SimpleBar>
                    )}
                    {/* <div className="p-2 border-top">
                            <Link to="#" className="btn btn-sm btn-link font-size-14 btn-block text-center">
                                <i className="mdi mdi-arrow-right-circle mr-1"></i>{this.props.t(' View More')}
                            </Link>
                        </div> */}
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );

}

const mapStateToProps = ({ NotificationsApp }) => {
    const { notificationsData, notificationsDataLoading, notificationsDataError } = NotificationsApp;
    return {
        notificationsData, notificationsDataLoading, notificationsDataError
    };
};
export default withRouter(connect(mapStateToProps, null, null, {})(NotificationDropdown));
