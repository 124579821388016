import {
    SUBSCRIPTIONS_BY_STUDENT,
    SUBSCRIPTIONS_BY_STUDENT_SUCCESS,
    SUBSCRIPTIONS_BY_STUDENT_ERROR,
    ADD_SUBSCRIPTIONS
} from '../actions';
import Session from '../../session';

const INIT_STATE = {
    subscriptionsByStudent: Session.get("studentSubscriptionsList") || [],
    subscriptionsByStudentLoading: true,
    subscriptionsByStudentError: '',
    subscriptionsAdd: []
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case SUBSCRIPTIONS_BY_STUDENT:
            return { ...state };

        case SUBSCRIPTIONS_BY_STUDENT_SUCCESS:
            return {
                ...state,
                subscriptionsByStudentLoading: false,

                subscriptionsByStudent: action.payload,
                subscriptionsByStudentError: ''
            };

        case ADD_SUBSCRIPTIONS:
            return {
                ...state,
                subscriptionsByStudentLoading: false,
                subscriptionsAdd: action.payload,
                subscriptionsByStudentError: ''
            };

        case SUBSCRIPTIONS_BY_STUDENT_ERROR:
            return {
                ...state, subscriptionsByStudentLoading: false,
                subscriptionsByStudentError: action.payload, subscriptionsByStudent: []
            };

        default:
            return { ...state };
    }
};