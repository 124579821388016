import React, { useEffect, useState, Fragment } from 'react';
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Container } from "reactstrap";
import Session from '../../session/';
import MathsIcon from "../../assets/images/icons/Maths.png";
import backIcon from "../../assets/images/icons/back.png";
import {
    fetchSubjectsDetailsByClass,
    fetchAssignmentsBySubject
} from '../../store/actions';


const Student = ({
    history,
    onFetchSubjectsDetailsByClass,
    subjects,
    onFetchAssignmentsBySubject,
    stdAssignments
}) => {
    const [userAssignments, setUserAssignments] = useState([]);

    useEffect(() => {
        const stdData = Session.get('authUser');

        if (stdData && stdData.studentClassId) {
            let cId = stdData.studentClassId;

            onFetchSubjectsDetailsByClass({
                classId: cId
            });
        } else {
            history.push({
                pathname: '/login'
            });
        }
    }, []);

    useEffect(() => {
        setUserAssignments(stdAssignments);
    }, [stdAssignments]);

    const handleSubjChange = (e) => {
        if (e && e.target && e.target.value != "Select Subject") {
            const subjId = e.target.value;
            const postData = { "subjectId": subjId };

            onFetchAssignmentsBySubject(postData);
        } else {
            setUserAssignments([]);
        }
    };

    return (
        <div className="page-content">
            <Container fluid>
                {/* <h3>Assignments</h3>
                <br /> */}
                <div className="row">
                    <div className="col-sm-8">
                        <div className="courseHeadigMaindiv">
                            <div className="arrowandIcondiv">
                                <Link to="/student"><img src={backIcon} /></Link>
                                <img className="card-img-top" style={{ width: "15%" }} src={MathsIcon} alt="Card image cap" /> <b>Assignments</b>
                            </div>
                        </div>
                    </div>
                </div>

                <hr />
                <h6>Select Subject:</h6>
                <div className="row">
                    <div className="col-sm-3">
                        {subjects && subjects.length > 0 ?
                            <select className="form-control dropdownborderradius" onChange={(e) => handleSubjChange(e)}>
                                <option>Select Subject</option>
                                {subjects.map((subjs, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <option value={subjs.subjectId}>{subjs.subjectName}</option>
                                        </Fragment>
                                    )
                                })}
                            </select> : <h5>No subjects to show</h5>}

                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-sm-12">
                        <h6>Assignments: </h6>
                    </div>
                    {userAssignments && userAssignments.length > 0 ?
                        <div className="col-sm-4">
                            {userAssignments.map((assignment, index) => {
                                return (
                                    <div className="card"
                                        style={{ textTransform: "capitalize", padding: "20px 20px 20px 10px" }} key={index}>
                                        <a target="__blank" href={assignment.mediaUrl} style={{ color: 'inherit' }}>
                                            {index + 1}. {assignment.title ? assignment.title : 'Previous paper'}
                                        </a>
                                    </div>
                                )
                            })}
                        </div>
                        : <div className="col-sm-12">
                            <p>No previous papers available.</p>
                        </div>}
                </div>
            </Container>
        </div>
    );
}

const mapStateToProps = ({ SubjectsApp, AssignmentsApp }) => {
    const { subjects, subjLoading, subjError } = SubjectsApp;
    const { stdAssignments } = AssignmentsApp;

    return {
        subjects,
        subjLoading,
        subjError,
        stdAssignments
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchSubjectsDetailsByClass: (classData) => dispatch(fetchSubjectsDetailsByClass(classData)),
        onFetchAssignmentsBySubject: (data) => dispatch(fetchAssignmentsBySubject(data))
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps, null, {})(Student));