/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { toast } from 'react-toastify';
import Session from '../../session';

import {
  BOARDS_GET_DETAILS,
  BOARDS_GET_DETAILS_SUCCESS,
  BOARDS_GET_DETAILS_ERROR, API_REGISTER_OTP_AUTH_ERROR, REGISTER_USER_OTP_AUTH_SUCCESSFUL,
  UPDATE_USER_SUCCESSFUL, API_UPDATE_ERROR, UPDATE_USER_PROFILE_SUCCESSFUL, RESET_DEFAULT_STATUS
} from '../actions';

export const getBoardsDetails = () => ({
  type: BOARDS_GET_DETAILS
});

export const getBoardsDetailsSuccess = (items) => ({
  type: BOARDS_GET_DETAILS_SUCCESS,
  payload: items
});

export const getBoardsDetailsError = (error) => ({
  type: BOARDS_GET_DETAILS_ERROR,
  payload: error,
});

export const registerUserOTPAuthSuccessful = (user) => {
  return {
      type: REGISTER_USER_OTP_AUTH_SUCCESSFUL,
      payload: user
  }
}


export const apiRegisterOTPAuthError = (error) => {
  return {
      type: API_REGISTER_OTP_AUTH_ERROR,
      payload: error
  }
}

export const updateUserSuccessful = (user) => {
  return {
      type: UPDATE_USER_SUCCESSFUL,
      payload: user
  }
}

export const updateUserProfileSuccessful = (user) => {
  return {
      type: UPDATE_USER_PROFILE_SUCCESSFUL,
      payload: user
  }
}

export const apiUpdateError = (error) => {
  return {
      type: API_UPDATE_ERROR,
      payload: error
  }
}

export const resetDefaultStatus = (user) => {
  return {
      type: RESET_DEFAULT_STATUS,
      payload: user
  }
}

export const fetchBoardsDetails = () => {
  return (dispatch, getState, { axios, baseUrl, endpoints }) => {

    dispatch(getBoardsDetails());

    return axios.get(baseUrl + endpoints.getBoards)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(getBoardsDetailsSuccess(data.response.data));
      }).catch(() => {
        dispatch(getBoardsDetailsError('Something went wrong. Try again after sometime.'));
      });
  }
};

export const registerStudentOTP = (postData) => {
  return (dispatch, getState, { axios, baseUrl, endpoints }) => {
      return axios.post(baseUrl + endpoints.registerStudentotp, postData)
          .then(response => {
              return response.data;
          }).then(data => {
              dispatch(registerUserOTPAuthSuccessful(data.response.data[0]));
          }).catch((error) => {
              dispatch(apiRegisterOTPAuthError('Invalid OTP'));
          });
  }
};

export const updateStudent = (postData) => {
  return (dispatch, getState, { axios, baseUrl, endpoints }) => {
      return axios.post(baseUrl + endpoints.updatestudent, postData)
          .then(response => {
              return response.data;
          }).then(data => {
              dispatch(updateUserSuccessful(data.response.data[0]));
          }).catch((error) => {
              dispatch(apiUpdateError('something went wrong, try again later'));
          });
  }
};

export const updateStudentProfile = (postData) => {
  return (dispatch, getState, { axios, baseUrl, endpoints }) => {
      return axios.post(baseUrl + endpoints.updatestudent, postData)
          .then(response => {
              return response.data;
          }).then(data => {
              dispatch(updateUserProfileSuccessful(data.response.data[0]));
          }).catch((error) => {
              dispatch(apiUpdateError('something went wrong, try again later'));
          });
  }
};

export const resetDefault = (postData) => {
  return (dispatch, getState, { axios, baseUrl, endpoints }) => {
    dispatch(resetDefaultStatus(postData));
  }
};