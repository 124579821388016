import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from "reactstrap";
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import { loginStudent, loginSendOTP, loginStudentOTP, loginSendPwd, resetDefault } from '../../store/actions';
import logodark from "../../assets/images/black.svg";

import Session from '../../session';
import Roles from '../../routes/roles';

const Login = ({
    history,
    onLoginStudent,
    onLoginSendOTP,
    onLoginStudentOTP,
    onLoginSendPwd,
    loginUser,
    loginError,
    loginMessage,
    onResetDefault
}) => {
    // const { t } = useTranslation();
    const [loginFormData, setLoginFormData] = useState({});
    const [errors, setError] = useState([]);
    const [otpErrors, setOTPError] = useState([]);

    useEffect(() => {
        // onFetchBoardsDetails();
        // console.log(t);
        document.body.classList.add("auth-body-bg");
    }, []);

    useEffect(() => {
        if (loginMessage == 'otp login' && loginError == '') {
            setError([]);
            document.getElementById("loginWithOTPDiv").style.display = 'none';
            document.getElementById("loginWithPasswordDiv").style.display = 'none';
            document.getElementById("passwordEnterDiv").style.display = 'none';
            document.getElementById("otpEnterDiv").style.display = 'block';
        }
        if (loginMessage == 'pwd login' && loginError == '') {
            setError([]);
            document.getElementById("loginWithOTPDiv").style.display = 'none';
            document.getElementById("loginWithPasswordDiv").style.display = 'none';
            document.getElementById("passwordEnterDiv").style.display = 'block';
            document.getElementById("otpEnterDiv").style.display = 'none';
        }
        if ((loginMessage == 'otp login' || loginMessage == 'pwd login') && loginError) {
            let data = [{ error: loginError }];
            setError(data);
        }
        if ((loginMessage == 'otp auth' || loginMessage == 'pwd auth') && loginError) {
            let data = [{ error: loginError }];
            setOTPError(data);
        }
        if ((loginMessage == 'otp auth' || loginMessage == 'pwd auth') && loginError == '' && loginUser && loginUser.length) {
            Session.set('authUser', JSON.stringify(loginUser[0]));
            let currentUserRole = Session.userRole();
            history.push({
                pathname: currentUserRole === Roles.TEACHER ? "/teacher" : '/student'
            });
        }
    }, [loginUser, loginError]);

    const hadleOTPDiv = () => {
        setError([]);
        onResetDefault([]);
        document.getElementById("loginWithOTPDiv").style.display = 'block';
        document.getElementById("loginWithPasswordDiv").style.display = 'none';
        document.getElementById("passwordEnterDiv").style.display = 'none';
        document.getElementById("otpEnterDiv").style.display = 'none';
    }
    const hadlePasswordDiv = () => {
        setError([]);
        onResetDefault([]);
        document.getElementById("loginWithOTPDiv").style.display = 'none';
        document.getElementById("loginWithPasswordDiv").style.display = 'block';
        document.getElementById("passwordEnterDiv").style.display = 'none';
        document.getElementById("otpEnterDiv").style.display = 'none';
    }
    const handleEnterPasswordDiv = () => {
        var input = document.getElementById("txtMobileNumber").value;
        if (document.getElementById("txtMobileNumber").value == '') {
            let data = [{ error: "Please enter mobile number" }];
            setError(data);
            // alert('Please enter mobile number');
        }
        else if (input.length < 10) {
            let data = [{ error: "Please enter valid number" }];
            setError(data);
            // alert('Please enter valid number');
        }
        else {
            setError([]);
            onLoginSendPwd({ "studentPhone": input });
        }
    }
    const handleEnterOTPDiv = () => {
        var input = document.getElementById("txtMobileNumberOTP").value;
        if (document.getElementById("txtMobileNumberOTP").value == '') {
            let data = [{ error: "Please enter mobile number" }];
            setError(data);
        }
        else if (input.length < 10) {
            let data = [{ error: "Please enter valid number" }];
            setError(data);
        } else {
            setError([]);
            onLoginSendOTP({ "studentPhone": input });
        }
    }

    const handleInputChange = (e, type) => {
        e.preventDefault();
        const re = /^[0-9\b]+$/;
        if (!e.target.value) {
            let data = [{ error: "Please enter a value" }];
            setError(data);
        } else {
            setError([]);
        }
        if (e.target.value === '' || re.test(e.target.value)) {
            const inputValue = e.target.value;
            switch (type) {
                case 'txtmobilechange': {
                    setLoginFormData(prevState => ({
                        ...prevState,
                        studentPhone: inputValue
                    }));
                    break;
                }
                case 'txtwithpwdchange': {
                    setLoginFormData(prevState => ({
                        ...prevState,
                        studentPassword: inputValue
                    }));
                    break;
                }
                case 'txtwithotpchange': {
                    setLoginFormData(prevState => ({
                        ...prevState,
                        studentOTP: inputValue
                    }));
                }
            }
        }
        else {
            e.target.value = '';
        }
    }

    const handleStudenLogin = () => {
        if (loginFormData.studentPassword == "" || loginFormData.studentPassword == undefined) {
            let data = [{ error: "Please enter password" }];
            setOTPError(data);
            // alert('Please enter password');
        }
        else {
            // setOTPError([]);
            onLoginStudent(loginFormData);
        }
        // if (loginFormData.studentPhone && loginFormData.studentPassword) {
        //     onLoginStudent(loginFormData);
        //     // history.push({
        //     //     pathname: '/student'
        //     // });
        // }
    }

    const handleStudentOTPLogin = () => {
        if (loginFormData.studentOTP == "" || loginFormData.studentOTP == undefined) {
            let data = [{ error: "Please enter OTP" }];
            setOTPError(data);
        }
        else {
            // setOTPError([]);
            onLoginStudentOTP({ "studentPhone": loginFormData.studentPassword, "studentOTP": loginFormData.studentOTP });
        }
    }

    return (
        <React.Fragment>
            <div>
                <Container fluid className="p-0">
                    <Row className="no-gutters">
                        <Col lg={4}>
                            <div className="mt-4">
                                <div className="w-100">
                                    <Row className="justify-content-center">
                                        <Col lg={9}>
                                            <div>
                                                <div className="text-center">
                                                    <div>
                                                        <Link to="" className="logoImg"><img src={logodark} height="50" alt="logo" /></Link>
                                                    </div>

                                                    <h4 className="font-size-18 welbackFontWeight" >Welcome Back !</h4>
                                                    <p className="text-muted">Sign in to continue to Infelearn.</p>
                                                </div>
                                                <div id="loginWithPasswordDiv">
                                                    <div className="p-2 mt-5">
                                                        <h6 className="font-size-15 welbackFontWeight">Enter your mobile number</h6>
                                                        <input type="text" minLength="10" id="txtMobileNumber" maxLength="10" className="form-control iputFildBorderRadius"
                                                            onChange={(e) => handleInputChange(e, 'txtmobilechange')} />
                                                        {errors.map((error, index) => {
                                                            return (
                                                                <div className="invalid-feedback d-block">
                                                                    {error.error}
                                                                </div>
                                                            )
                                                        })}
                                                        <br />
                                                        {/* <p>By signing up, you agree to infelearn terms and conditions</p> */}
                                                        <button className="btn btn-default buttonCss" onClick={handleEnterPasswordDiv}>Login</button><br /><br />
                                                        <h6 className="logiWithOtpAndPwd" onClick={hadleOTPDiv}>LOGIN WITH OTP </h6>

                                                    </div>
                                                </div>

                                                <div id="loginWithOTPDiv" style={{ display: "none" }}>
                                                    <div className="p-2 mt-5">
                                                        <h6 className="font-size-15 welbackFontWeight">Enter your mobile number</h6>
                                                        <input type="text" id="txtMobileNumberOTP" className="form-control iputFildBorderRadius"
                                                            maxLength="10" onChange={(e) => handleInputChange(e, 'txtwithpwdchange')} />
                                                        {errors.map((error, index) => {
                                                            return (
                                                                <div className="invalid-feedback d-block">
                                                                    {error.error}
                                                                </div>
                                                            )
                                                        })}
                                                        <br />
                                                        {/* <p>By signing up, you agree to infelearn terms and conditions</p> */}
                                                        <button className="btn btn-default buttonCss" onClick={handleEnterOTPDiv}>Login</button><br /><br />
                                                        <h6 className="logiWithOtpAndPwd" onClick={hadlePasswordDiv}>LOGIN WITH PASSWORD </h6>

                                                    </div>
                                                </div>
                                                <div id="passwordEnterDiv" style={{ display: "none" }}>
                                                    <div className="p-2 mt-5">
                                                        <h6 className="font-size-15 welbackFontWeight">Enter your 4 digit password</h6>
                                                        <input type="password" id="txtFourDigitPwd" maxLength="4" className="form-control iputFildBorderRadius" onChange={(e) => handleInputChange(e, 'txtwithpwdchange')} />
                                                        {otpErrors.map((error, index) => {
                                                            return (
                                                                <div className="invalid-feedback d-block">
                                                                    {error.error}
                                                                </div>
                                                            )
                                                        })}
                                                        <br />
                                                        {/* <p><u>Forgot Password</u></p> */}
                                                        {/* <p>By signing up, you agree to infelearn terms and conditions</p> */}
                                                        <button className="btn btn-default buttonCss" onClick={() => handleStudenLogin()}>Login</button><br /><br />
                                                        <h6 className="logiWithOtpAndPwd" onClick={hadleOTPDiv}>LOGIN WITH OTP </h6>
                                                    </div>
                                                </div>
                                                <div id="otpEnterDiv" style={{ display: "none" }}>
                                                    <div className="p-2 mt-5">
                                                        <h6 className="font-size-15 welbackFontWeight">Enter your OTP</h6>
                                                        <input type="text" id="txtOTPPwd" className="form-control iputFildBorderRadius" onChange={(e) => handleInputChange(e, 'txtwithotpchange')} />
                                                        {otpErrors.map((error, index) => {
                                                            return (
                                                                <div className="invalid-feedback d-block">
                                                                    {error.error}
                                                                </div>
                                                            )
                                                        })}
                                                        <br />
                                                        {/* <p><u>Forgot Password</u></p> */}
                                                        {/* <p>By signing up, you agree to infelearn terms and conditions</p> */}
                                                        <button className="btn btn-default buttonCss"
                                                            onClick={() => handleStudentOTPLogin()}>
                                                            Login
                                                        </button><br /><br />


                                                    </div>
                                                </div>

                                                <div className="mt-5 text-center">
                                                    <p><u><Link to="/Register">Sign in as new student</Link></u></p>
                                                    <p><u><Link to="/parentlogin">Log in as parent</Link></u></p>
                                                    <p><u><Link to="/teacherlogin">Log in as teacher</Link></u></p>
                                                </div>
                                            </div>

                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="authentication-bg">

                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStatetoProps = ({ Login }) => {
    const { loginUser, loginError, loginMessage } = Login;
    return { loginUser, loginError, loginMessage };
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLoginStudent: (postData) => dispatch(loginStudent(postData)),
        onLoginSendOTP: (postData) => dispatch(loginSendOTP(postData)),
        onLoginStudentOTP: (postData) => dispatch(loginStudentOTP(postData)),
        onLoginSendPwd: (postData) => dispatch(loginSendPwd(postData)),
        onResetDefault: (postData) => dispatch(resetDefault(postData))
    }
};

export default withRouter(connect(mapStatetoProps,
    mapDispatchToProps, null, {})(withNamespaces()(Login)));

// export default withRouter(connect(mapStatetoProps, {
//     changeLayout,
//     changeSidebarTheme,
//     changeSidebarType,
//     changeLayoutWidth,
//     changePreloader
// })(withNamespaces()(SidebarContent)));