/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import {
    ASSIGNMENTS,
    ASSIGNMENTS_ERROR,
    ASSIGNMENTS_SUCCESS
} from '../actions';

export const getAssignments = () => ({
    type: ASSIGNMENTS
});

export const getAssignmentsSuccess = (items) => ({
    type: ASSIGNMENTS_SUCCESS,
    payload: items
});

export const getAssignmentsError = (error) => ({
    type: ASSIGNMENTS_ERROR,
    payload: error
});

export const fetchAssignmentsBySubject = (subjectData) => {
    return (dispatch, getState, { axios, baseUrl, endpoints }) => {
        return axios.post(baseUrl + endpoints.assignmentsBySubject, { "subjectId": parseInt(subjectData.subjectId) })
            .then(response => {
                return response.data;
            }).then(data => {
                dispatch(getAssignmentsSuccess(data.response.data));
            }).catch(() => {
                dispatch(getAssignmentsError('Something went wrong. Try again after sometime.'));
            });
    }
};