import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation, withRouter } from "react-router-dom";
import { Container } from "reactstrap";
import MathsIcon from "../../assets/images/icons/Maths.png";
import backIcon from "../../assets/images/icons/back.png";


import Session from '../../session';

const TestMode = ({
    topics,
    history
}) => {
    // const [myQues, setMyQues] = useState();
    const location = useLocation();

    const [courseInfoInCourseTopics, setcourseInfoInCourseTopics] = useState({});
    useEffect(() => {
        setcourseInfoInCourseTopics(JSON.parse(Session.get("studentChaptersInfo")));
    }, [location]);

    const handleViewQuestions = (mode) => {
        if(mode == 'chapter') {
            history.push({
                pathname: '/modewisequestions',
                state: {
                    type: mode
                }
            });
        } else {
            history.push({
                pathname: '/typewisetest',
                state: {
                    type: mode
                }
            });
        }
    }

    return (
        <div className="page-content">
            <Container fluid>
                <div className="row">
                    <div className="col-sm-4">
                        <div className="courseHeadigMaindiv">
                            <div className="arrowandIcondiv">
                                <Link to="/coursetopics"><img src={backIcon} /></Link>
                                <img className="card-img-top" style={{ width: "46%" }} src={MathsIcon} alt="Card image cap" />
                            </div>
                            <div className="courseHeadingdiv">
                                <span className="subjHeading">{courseInfoInCourseTopics.chapterName}</span><br />
                                <span className="cardTextFont">38 chapters | 80 lessons</span>
                            </div>
                        </div>
                    </div>


                    <div className="col-sm-2">
                        <Link to="/coursetopics">  <button className="btn btn-default defaultButtonCss">Back to Courses</button></Link>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-sm-4">
                        <div className="card cardDivInCourseTopicsForTestWise" onClick={(e) => handleViewQuestions("topic")}>
                            <span className="topicHeadinginStudentCourse">Topic wise test</span>
                            <span className="testWiseClearInfo"> Contains tests with topic wise where you will be answering five questions.</span>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="card cardDivInCourseTopicsForTestWise" onClick={(e) => handleViewQuestions("easy")}>
                            <span className="topicHeadinginStudentCourse">Mode wise test</span>
                            <span className="testWiseClearInfo"> Contains tests with mode easy, difficult and hard you will be answering.</span>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="card cardDivInCourseTopicsForTestWise" onClick={(e) => handleViewQuestions("chapter")}>
                            <span className="topicHeadinginStudentCourse">Full chapter test</span>
                            <span className="testWiseClearInfo"> Contains tests with all modes easy, difficult and hard where you will be answering 20 questions.</span>
                        </div>
                    </div>
                </div>



            </Container>
        </div>
    );

}


const mapStateToProps = ({ TopicsApp }) => {
    const { topics } = TopicsApp;

    return {
        topics
    };
};


export default withRouter(connect(mapStateToProps)(TestMode));


