import {
    ASSIGNMENTS,
    ASSIGNMENTS_ERROR,
    ASSIGNMENTS_SUCCESS
} from '../actions';

const INIT_STATE = {
    stdAssignments: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ASSIGNMENTS:
            return { ...state };

        case ASSIGNMENTS_SUCCESS:
            return { ...state, stdAssignments: action.payload, error: '' };

        case ASSIGNMENTS_ERROR:
            return { ...state, stdAssignments: [] };

        default:
            return { ...state };
    }
};