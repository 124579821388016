/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { toast } from 'react-toastify';
import {
    CHAPTERS_GET_DETAILS,
    CHAPTERS_GET_DETAILS_ERROR,
    CHAPTERS_GET_DETAILS_SUCCESS
} from '../actions';

export const getChaptersDetails = () => ({
    type: CHAPTERS_GET_DETAILS
});

export const getChaptersDetailsSuccess = (items) => ({
    type: CHAPTERS_GET_DETAILS_SUCCESS,
    payload: items
});

export const getChapetersDetailsError = (error) => ({
    type: CHAPTERS_GET_DETAILS_ERROR,
    payload: error,
});

export const fetchChaptersDetails = () => {
    return (dispatch, getState, { axios, baseUrl, endpoints }) => {

        return axios.get(baseUrl + endpoints.getChapters)
            .then(response => {
                return response.data;
            }).then(data => {
                dispatch(getChaptersDetailsSuccess(data.response.data));
            }).catch(() => {
                dispatch(getChapetersDetailsError('Something went wrong. Try again after sometime.'));
            });
    }
};

export const fetchChaptersDetailsBySubject = (subjectData) => {
    return (dispatch, getState, { axios, baseUrl, endpoints }) => {
        return axios.post(baseUrl + endpoints.getChaptersBySubject, { "subjectId": parseInt(subjectData.subjectId) })
            .then(response => {
                return response.data;
            }).then(data => {
                dispatch(getChaptersDetailsSuccess(data.response.data));
            }).catch(() => {
                dispatch(getChapetersDetailsError('Something went wrong. Try again after sometime.'));
            });
    }
};