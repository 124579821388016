import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from "reactstrap";
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import {
    loginSendPwd,
    resetDefault,
    loginTeacher,
    loginTeacherSendPwd,
} from "../../store/actions";
import logodark from "../../assets/images/black.svg";

import Session from '../../session';

const Login = ({
    history,
    onLoginTeacher,
    onLoginSendPwd,
    loginUser,
    loginError,
    loginMessage,
    onResetDefault
}) => {
    // const { t } = useTranslation();
    const [loginFormData, setLoginFormData] = useState({});
    const [errors, setError] = useState([]);
    const [otpErrors, setOTPError] = useState([]);

    useEffect(() => {
        // onFetchBoardsDetails();
        // console.log(t);
        document.body.classList.add("auth-body-bg");
    }, []);

    useEffect(() => {
        if (loginMessage === 'otp login' && loginError === '') {
            setError([]);
            document.getElementById("loginWithPasswordDiv").style.display = 'none';
            document.getElementById("passwordEnterDiv").style.display = 'none';
        }
        if (loginMessage === 'pwd login' && loginError === '') {
            setError([]);
            document.getElementById("loginWithPasswordDiv").style.display = 'none';
            document.getElementById("passwordEnterDiv").style.display = 'block';
        }
        if ((loginMessage === 'otp login' || loginMessage === 'pwd login') && loginError) {
            let data = [{ error: loginError }];
            setError(data);
        }
        if ((loginMessage === 'otp auth' || loginMessage === 'pwd auth') && loginError) {
            let data = [{ error: loginError }];
            setOTPError(data);
        }
        if ((loginMessage === 'otp auth' || loginMessage === 'pwd auth') && loginError === '' && loginUser && loginUser.length) {
            Session.set('authUser', JSON.stringify(loginUser[0]));
            history.push({
                pathname: '/student'
            });
        }
    }, [loginUser, loginError]);

    const handleEnterPasswordDiv = () => {
        var input = document.getElementById("txtMobileNumber").value;
        if (document.getElementById("txtMobileNumber").value === '') {
            let data = [{ error: "Please enter mobile number" }];
            setError(data);
            // alert('Please enter mobile number');
        }
        else if (input.length < 10) {
            let data = [{ error: "Please enter valid number" }];
            setError(data);
            // alert('Please enter valid number');
        }
        else {
            setError([]);
            onLoginSendPwd({ "phone": input });
        }
    }

    const handleInputChange = (e, type) => {
        e.preventDefault();
        const re = /^[0-9\b]+$/;
        if (!e.target.value) {
            let data = [{ error: "Please enter a value" }];
            setError(data);
        } else {
            setError([]);
        }
        if (e.target.value === '' || re.test(e.target.value)) {
            const inputValue = e.target.value;
            switch (type) {
                case 'txtmobilechange': {
                    setLoginFormData(prevState => ({
                        ...prevState,
                        phone: inputValue
                    }));
                    break;
                }
                case 'txtwithpwdchange': {
                    setLoginFormData(prevState => ({
                        ...prevState,
                        password: inputValue
                    }));
                    break;
                }
                case 'txtwithotpchange': {
                    setLoginFormData(prevState => ({
                        ...prevState,
                        otp: inputValue
                    }));
                }
            }
        }
        else {
            e.target.value = '';
        }
    }

    const handleTeacherLogin = () => {
        // here the fields names are kept as password for the time being
        if (loginFormData.password === "" || loginFormData.password === undefined) {
            let data = [{ error: "Please enter password" }];
            setOTPError(data);
        }
        else {
            onLoginTeacher(loginFormData);
        }
    }

    return (
        <React.Fragment>
            <div>
                <Container fluid className="p-0">
                    <Row className="no-gutters">
                        <Col lg={4}>
                            <div className="mt-4">
                                <div className="w-100">
                                    <Row className="justify-content-center">
                                        <Col lg={9}>
                                            <div>
                                                <div className="text-center">
                                                    <div>
                                                        <Link to="" className="logoImg"><img src={logodark} height="50" alt="logo" /></Link>
                                                    </div>

                                                    <h4 className="font-size-18 welbackFontWeight" >Welcome Back !</h4>
                                                    <p className="text-muted">Sign in to continue to Infelearn.</p>
                                                </div>
                                                <div id="loginWithPasswordDiv">
                                                    <div className="p-2 mt-5">
                                                        <h6 className="font-size-15 welbackFontWeight">Enter your mobile number</h6>
                                                        <input type="text" minLength="10" id="txtMobileNumber" maxLength="10" className="form-control iputFildBorderRadius"
                                                            onChange={(e) => handleInputChange(e, 'txtmobilechange')} />
                                                        {errors.map((error, index) => {
                                                            return (
                                                                <div className="invalid-feedback d-block">
                                                                    {error.error}
                                                                </div>
                                                            )
                                                        })}
                                                        <br />
                                                        {/* <p>By signing up, you agree to infelearn terms and conditions</p> */}
                                                        <button className="btn btn-default buttonCss" onClick={handleEnterPasswordDiv}>Login</button><br /><br />

                                                    </div>
                                                </div>
                                                <div id="passwordEnterDiv" style={{ display: "none" }}>
                                                    <div className="p-2 mt-5">
                                                        <h6 className="font-size-15 welbackFontWeight">Enter your 4 digit password</h6>
                                                        <input type="password" id="txtFourDigitPwd" maxLength="4" className="form-control iputFildBorderRadius" onChange={(e) => handleInputChange(e, 'txtwithpwdchange')} />
                                                        {otpErrors.map((error, index) => {
                                                            return (
                                                                <div className="invalid-feedback d-block">
                                                                    {error.error}
                                                                </div>
                                                            )
                                                        })}
                                                        <br />
                                                        {/* <p><u>Forgot Password</u></p> */}
                                                        {/* <p>By signing up, you agree to infelearn terms and conditions</p> */}
                                                        <button className="btn btn-default buttonCss" onClick={() => handleTeacherLogin()}>Login</button><br /><br />
                                                    </div>
                                                </div>

                                                <div className="mt-5 text-center">
                                                    <p><u><Link to="/Register">Sign in as new student</Link></u></p>
                                                    <p><u><Link to="/login">Log in as student</Link></u></p>
                                                    <p><u><Link to="/parentlogin">Log in as parent</Link></u></p>
                                                </div>
                                            </div>

                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="authentication-bg">

                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStatetoProps = ({ Login }) => {
    const { loginUser, loginError, loginMessage } = Login;
    return { loginUser, loginError, loginMessage };
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLoginTeacher: (postData) => dispatch(loginTeacher(postData)),
        onLoginSendPwd: (postData) => dispatch(loginTeacherSendPwd(postData)),
        onResetDefault: (postData) => dispatch(resetDefault(postData)),
    };
};

export default withRouter(connect(mapStatetoProps,
    mapDispatchToProps, null, {})(withNamespaces()(Login)));

// export default withRouter(connect(mapStatetoProps, {
//     changeLayout,
//     changeSidebarTheme,
//     changeSidebarType,
//     changeLayoutWidth,
//     changePreloader
// })(withNamespaces()(SidebarContent)));