import {
    NOTIFICATIONS_SUCCESS, NOTIFICATIONS_ERROR
} from '../actions';

const INIT_STATE = {
    notificationsData: [],
    notificationsDataLoading: false,
    notificationsDataError: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                notificationsDataLoading: false,
                notificationsData: action.payload,
                notificationsDataError: ''
            };
    
        
        case NOTIFICATIONS_ERROR:
                return {
                    ...state,
                    notificationsDataError: action.payload
                };

        default:
            return { ...state };
    }
};