import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
// Instead of loading axios in every Actions files, we can load it here and pass on.
import axios from 'axios';
import { projectConfig } from '../constants/defaultValues';

projectConfig.axios = axios;

const store = createStore(
    rootReducer,
    compose(applyMiddleware(thunk.withExtraArgument(projectConfig)))
);

export default store;
