import { combineReducers } from 'redux';

// Front
import Layout from './layout/reducer';

// Authentication Module
import Account from './auth/register/reducer';
import Login from './auth/login/reducer';
import Forget from './auth/forgetpwd/reducer';

import BoardsApp from './boards/reducer';
import ClassesApp from './classes/reducer';
import SubjectsApp from './subjects/reducer';
import ChaptersApp from './chapters/reducer';
import TopicsApp from './topics/reducer';
import NotesApp from './notes/reducer';
import ContentsApp from './contents/reducer';
import NotificationsApp from './notifications/reducer';

import QuestionsApp from './questions/reducer';
import PricingApp from './pricing/reducer';

import ClassFeeDetailsApp from './classes-fees/reducer';
import SubscriptionsApp from './subscriptions/reducer';

import OffersApp from './offers/reducer';
import AssignmentsApp from './assignments/reducer';

const rootReducer = combineReducers({
    // public
    Layout,
    // Authentication
    Account,
    Login,
    Forget,

    BoardsApp,
    ClassesApp,
    SubjectsApp,
    ChaptersApp,
    TopicsApp,
    NotesApp,
    ContentsApp,
    NotificationsApp,

    QuestionsApp,
    PricingApp,

    ClassFeeDetailsApp,
    SubscriptionsApp,

    OffersApp,
    AssignmentsApp
});

export default rootReducer;