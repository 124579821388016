import { CHECK_LOGIN, LOGIN_USER_SUCCESSFUL, API_ERROR, LOGOUT_USER, LOGOUT_USER_SUCCESS, LOGIN_USER_OTP_SUCCESSFUL, LOGIN_USER_OTP_AUTH_SUCCESSFUL, API_OTP_ERROR, API_OTP_AUTH_ERROR, 
    REGISTER_USER_OTP_SUCCESSFUL, API_REGISTER_OTP_AUTH_ERROR, REGISTER_USER_OTP_AUTH_SUCCESSFUL, API_REGISTER_OTP_ERROR, LOGIN_USER_PWD_SUCCESSFUL, API_PWD_ERROR, 
    LOGIN_PARENT_OTP_SUCCESSFUL, API_PARENT_OTP_ERROR, LOGIN_PARENT_PWD_SUCCESSFUL, API_PARENT_PWD_ERROR, LOGIN_PARENT_OTP_AUTH_SUCCESSFUL, API_PARENT_OTP_AUTH_ERROR, LOGIN_PARENT_SUCCESSFUL, API_PARENT_ERROR,
    REGISTER_PARENT_SUCCESSFUL, REGISTER_PARENT_FAILED, REGISTER_PARENT_OTP_SUCCESSFUL, API_REGISTER_PARENT_OTP_ERROR, UPDATE_USER_SUCCESSFUL, API_UPDATE_ERROR, UPDATE_USER_PROFILE_SUCCESSFUL, RESET_DEFAULT_STATUS,
    VERIFY_STUDENT_SUCCESSFUL, API_VERIFY_STUDENT_ERROR, ADD_STUDENT_SUCCESSFUL, API_ADD_STUDENT_ERROR, VERIFY_OTP_STUDENT_SUCCESSFUL, SUBJECT_ANALYTICS_STUDENT_SUCCESSFUL, API_SUBJECT_ANALYTICS_ERROR  }
    from '../../actions';

const initialState = {
    loginError: "", loginMessage: null, loginLoading: false,
    loginUser: []
}

const login = (state = initialState, action) => {
    switch (action.type) {
        case CHECK_LOGIN:
            state = {
                ...state,
                loading: true
            }
            break;
        
        case LOGIN_USER_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                loginMessage: 'pwd auth',
                loginError: '',
                loginUser: [action.payload]
            }
            break;

        case VERIFY_STUDENT_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                loginMessage: 'verify student',
                loginError: '',
                loginUser: [action.payload]
            }
            break;
        
        case VERIFY_OTP_STUDENT_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                loginMessage: 'verify resend student',
                loginError: '',
                loginUser: [action.payload]
            }
            break;
    
        case ADD_STUDENT_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                loginMessage: 'verify student auth',
                loginError: '',
                loginUser: [action.payload]
            }
            break;

        case RESET_DEFAULT_STATUS:
            state = {
                ...state,
                loading: false,
                loginMessage: null,
                loginError: '',
                loginUser: [action.payload]
            }
            break;
        
        case LOGIN_PARENT_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                loginMessage: 'parent pwd auth',
                loginError: '',
                loginUser: [action.payload]
            }
            break;
        
        case LOGIN_USER_OTP_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                loginMessage: 'otp login',
                loginError: '',
                loginUser: [action.payload]
            }
            break;
        
        case LOGIN_PARENT_OTP_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                loginMessage: 'otp parent',
                loginError: '',
                loginUser: [action.payload]
            }
            break;

        case LOGIN_PARENT_PWD_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                loginMessage: 'pwd parent',
                loginError: '',
                loginUser: [action.payload]
            }
            break;
        
        case LOGIN_USER_PWD_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                loginMessage: 'pwd login',
                loginError: '',
                loginUser: [action.payload]
            }
            break;
        
        case REGISTER_USER_OTP_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                loginMessage: 'register otp',
                loginError: '',
                loginUser: [action.payload]
                }
            break;

        case REGISTER_PARENT_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                loginMessage: 'register parent otp',
                loginError: '',
                loginUser: [action.payload]
                }
            break;

        case LOGIN_USER_OTP_AUTH_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                loginMessage: 'otp auth',
                loginError: '',
                loginUser: [action.payload]
            }
            break;

        case LOGIN_PARENT_OTP_AUTH_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                loginMessage: 'parent otp auth',
                loginError: '',
                loginUser: [action.payload]
            }
            break;

        case REGISTER_USER_OTP_AUTH_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                loginMessage: 'register auth',
                loginError: '',
                loginUser: [action.payload]
                }
            break;

        case UPDATE_USER_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                loginMessage: 'update user',
                loginError: '',
                loginUser: [action.payload]
                }
            break;
        
        case UPDATE_USER_PROFILE_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                loginMessage: 'update user profile',
                loginError: '',
                loginUser: [action.payload]
                }
            break;

        case REGISTER_PARENT_OTP_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                loginMessage: 'register parent auth',
                loginError: '',
                loginUser: [action.payload]
                }
            break;

        case LOGOUT_USER:
            state = { ...state };
            break;

        case LOGOUT_USER_SUCCESS:
            state = { ...state };
            break;

        case API_ERROR:
            state = {
                ...state,
                loading: false,
                loginMessage: 'pwd auth',
                loginError: action.payload
            }
            break;

        case API_VERIFY_STUDENT_ERROR:
            state = {
                ...state,
                loading: false,
                loginMessage: 'verify student',
                loginError: action.payload
            }
            break;
        
        case API_ADD_STUDENT_ERROR:
            state = {
                ...state,
                loading: false,
                loginMessage: 'verify student auth',
                loginError: action.payload
            }
            break;
            
        case API_PARENT_ERROR:
            state = {
                ...state,
                loading: false,
                loginMessage: 'parent pwd auth',
                loginError: action.payload
            }
            break;
        
        case API_OTP_ERROR:
            state = {
                ...state,
                loading: false,
                loginMessage: 'otp login',
                loginError: action.payload
            }
            break;
        
        case API_PARENT_OTP_ERROR:
            state = {
                ...state,
                loading: false,
                loginMessage: 'otp parent',
                loginError: action.payload
            }
            break;

        case API_PWD_ERROR:
            state = {
                ...state,
                loading: false,
                loginMessage: 'pwd login',
                loginError: action.payload
                }
            break;

        case API_PARENT_PWD_ERROR:
            state = {
                ...state,
                loading: false,
                loginMessage: 'pwd parent',
                loginError: action.payload
                }
            break;

        case API_REGISTER_OTP_ERROR:
            state = {
                ...state,
                loading: false,
                loginMessage: 'register otp',
                loginError: action.payload
            }
            break;

        case REGISTER_PARENT_FAILED:
            state = {
                ...state,
                loading: false,
                loginMessage: 'register parent otp',
                loginError: action.payload
            }
            break;
        
        case API_OTP_AUTH_ERROR:
            state = {
                ...state,
                loading: false,
                loginMessage: 'otp auth',
                loginError: action.payload
            }
            break;
        
        case API_PARENT_OTP_AUTH_ERROR:
            state = {
                ...state,
                loading: false,
                loginMessage: 'parent otp auth',
                loginError: action.payload
            }
            break;
        case API_REGISTER_OTP_AUTH_ERROR:
            state = {
                ...state,
                loading: false,
                loginMessage: 'register auth',
                loginError: action.payload
            }
            break;

        case API_UPDATE_ERROR:
            state = {
                ...state,
                loading: false,
                loginMessage: 'update user',
                loginError: action.payload
            }
            break;
        
        case API_REGISTER_PARENT_OTP_ERROR:
            state = {
                ...state,
                loading: false,
                loginMessage: 'register parent auth',
                loginError: action.payload
            }
            break;

        case SUBJECT_ANALYTICS_STUDENT_SUCCESSFUL:
            return { ...state, loginLoading: false, loading: false, loginUser: [action.payload], loginError: '', loginMessage: 'analytics' };
        
        case API_SUBJECT_ANALYTICS_ERROR:
            return { ...state, loginLoading: false, loading: false, loginError: action.payload, loginUser: [], loginMessage: 'analytics' };

        default:
            state = { ...state };
            break;
    }
    return state;
}

export default login;