import React, { Component, useEffect, useState } from 'react';
import { Link, useLocation, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Card, CardBody, Row, Col } from "reactstrap";
import VidepImage from "../../assets/images/authentication-bg.jpg";
import ReactPlayer from 'react-player';
import backIcon from "../../assets/images/icons/back.png";
import MathsIcon from "../../assets/images/icons/Maths.png";

import {
    getContentsData
} from '../../store/actions';

import Session from '../../session/';

const Contents = ({
    history,
    onFetchContentsData,
    contentsData, contentsDataError
}) => {
    const location = useLocation();
    const [contents, setContents] = useState([]);

    useEffect(() => {
        onFetchContentsData();
    }, []);

    useEffect(() => {
        setContents(contentsData.filter((content) => content.endDate && new Date(content.endDate.slice(0, 10)) <= new Date()));
    }, [contentsData]);

    // useEffect(() => {
    //     contentsData.forEach(function(data) {
    //         const today = new Date();
    //         if(data.endDate && new Date(data.endDate.slice(0,10)) <= today) {
    //             let x = contents;
    //             x.push(data);
    //             setContents(x);
    //         }
    //     });
    // }, [contentsData, contentsDataError]);

    return (
        <div className="page-content">
            <Container fluid>
                <div className="row">
                    <div className="col-sm-8">
                        <div className="courseHeadigMaindiv">
                            <div className="arrowandIcondiv">
                                <Link to="/student"><img src={backIcon} /></Link>
                                <img className="card-img-top" style={{ width: "15%" }} src={MathsIcon} alt="Card image cap" /> <b>INFYLEARN CONTENTS</b>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <br />
                <div className="row">
                    {contents.map((topic, index) => {
                        return (
                            <div className="card topicsCardDIv" key={index} style={{ boxShadow: "4px 4px 9px rgb(0 0 0 / 8%)", borderRadius: "8px" }}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <img src={VidepImage} className="videoImgForTopic" /></div>
                                    <div className="col-sm-6 topicTitleDiv" ><span className="topicTitleSpan">{topic.categoryName} - {topic.classType}</span> <br /><br />
                                        {topic.contentMedia == null ?
                                            <span>No Video</span>
                                            : <ReactPlayer width="100%" height="67%" controls pip
                                                url={topic.contentMedia} />
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })}<br />
                </div><br />
            </Container>
        </div>
    );
}

const mapStateToProps = ({ ContentsApp }) => {
    const { contentsData, contentsDataError } = ContentsApp;

    return {
        contentsData, contentsDataError
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchContentsData: () => dispatch(getContentsData())
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps, null, {})(Contents));