import Session from "../../session";

export const joinLiveClass = (subjectId) => async (
  dispatch, getState, { axios, baseUrlNewBackend, endpoints }
) => {
  const user = Session.get("authUser");
  const res = await axios.post(baseUrlNewBackend + "join-live-class", {
    user, subjectId
  });
  const meetingDetails = res.data;
  return meetingDetails;
};

export const startLiveClass = (subjectId) => async (
  dispatch, getState, { axios, baseUrlNewBackend, endpoints }
) => {
  const user = Session.get("authUser");
  const res = await axios.post(baseUrlNewBackend + "start-live-class", {
    user, subjectId
  });
  const meetingDetails = res.data;
  return meetingDetails;
};