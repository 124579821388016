import React from "react";
import { Redirect } from "react-router-dom";

import '../assets/commonStyles.css';

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ParentLogin from "../pages/Authentication/ParentLogin";
import ParentRegister from "../pages/Authentication/ParentRegister";
//import SelectBoardandClass from "../pages/Authentication/SelectBoardandClass";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import AuthLockScreen from "../pages/Authentication/AuthLockScreen";

// Roles
import Roles from './roles';

// Dashboard
import Dashboard from "../pages/Dashboard/index";

// Pages Calendar
import Calendar from "../pages/Calendar/Calendar";

import Student from '../pages/StudentClass/Student';
import StudentProfile from '../pages/StudentClass/StudentProfile';
import Contents from '../pages/StudentClass/Contents';
import Reports from '../pages/StudentClass/Reports';
import Parent from '../pages/Parent/Parent';
import AddStudent from '../pages/Parent/AddStudent';
import RecentlyViewed from "../pages/Parent/RecentlyViewed";
import Notifications from "../pages/Parent/Notifications";
import StudentCourses from '../pages/StudentCourse/StudentCourses';
import CourseTopics from '../pages/CourseTopics/CourseTopics';
import CourseWiseTopics from '../pages/CourseWiseTopics/CourseWiseTopics';
import TestMode from '../pages/TestMode/TestMode';
import TypeWiseTest from '../pages/TypeWiseTest/TypeWiseTest';
import ModeWiseQuestions from '../pages/ModeWiseQuestions/ModeWiseQuestions';
import Pricing from '../pages/Pricing/Pricing';

import Assignments from '../pages/Assignments';

// Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Register1 from "../pages/AuthenticationInner/Register";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";

import SelectBoardandClass from "../pages/Authentication/SelectBoardandClass";

import NotAuth from "../pages/NotAuth";
import TeacherLogin from "../pages/Authentication/TeacherLogin";
import LiveClass from "../pages/LiveClass";
import TeacherClass from "../pages/TeacherClass/TeacherClass";

const authProtectedRoutes = [
	{ path: "/teacher", component: TeacherClass, roles: [Roles.TEACHER] },
	{ path: "/student", component: Student, roles: [Roles.STUDENT] },
	{ path: "/editstudent", component: StudentProfile, roles: [Roles.STUDENT] },
	{ path: "/studentcourses", component: StudentCourses, roles: [Roles.STUDENT] },
	{ path: "/contents", component: Contents, roles: [Roles.STUDENT] },
	{ path: "/reports", component: Reports, roles: [Roles.STUDENT] },
	{ path: "/coursetopics", component: CourseTopics, roles: [Roles.STUDENT] },
	{ path: "/coursewisetopics", component: CourseWiseTopics, roles: [Roles.STUDENT] },
	{ path: "/testmode", component: TestMode, roles: [Roles.STUDENT] },
	{ path: "/typewisetest", component: TypeWiseTest, roles: [Roles.STUDENT] },
	{ path: "/modewisequestions", component: ModeWiseQuestions, roles: [Roles.STUDENT] },
	{ path: "/pricing", component: Pricing, roles: [Roles.STUDENT] },
	{ path: "/parent", component: Parent, roles: [Roles.PARENT] },
	{ path: "/addstudent", component: AddStudent, roles: [Roles.PARENT] },
	{ path: "/recentlyViewed", component: RecentlyViewed, roles: [Roles.STUDENT, Roles.PARENT] },
	{ path: "/notifications", component: Notifications, roles: [Roles.STUDENT, Roles.PARENT] },
	{ path: "/assignments", component: Assignments, roles: [Roles.STUDENT, Roles.PARENT] },
	{ path: "/liveclass/:subjectId", component: LiveClass, roles: [Roles.STUDENT, Roles.TEACHER] },
	//calendar
	{ path: "/calendar", component: Calendar, roles: [Roles.STUDENT] },

	{ path: "/dashboard", component: Dashboard.apply, roles: [Roles.STUDENT] },

	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/login" /> }
];

const publicRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
	{ path: "/parentlogin", component: ParentLogin },
	{ path: "/teacherlogin", component: TeacherLogin },
	{ path: "/forgot-password", component: ForgetPwd },
	{ path: "/register", component: Register },
	{ path: "/parentregistry", component: ParentRegister },
	{ path: "/selectboardandclass", component: SelectBoardandClass },
	{ path: "/auth-lock-screen", component: AuthLockScreen },

	// { path: "/calendar", component: Calendar },

	// Authentication Inner
	{ path: "/auth-login", component: Login1 },
	{ path: "/auth-selectboardandclass", component: SelectBoardandClass },
	{ path: "/auth-register", component: Register1 },
	{ path: "/auth-recoverpw", component: ForgetPwd1 },

	// { path: "/pages-maintenance", component: Maintenance },
	// { path: "/pages-comingsoon", component: CommingSoon },
	// { path: "/pages-404", component: Error404 },
	// { path: "/pages-500", component: Error500 },
	// { path: "/not-auth", component: NotAuth },
];

export { authProtectedRoutes, publicRoutes };
