const STUDENT = 'STUDENT';
const PARENT = 'PARENT';
const TEACHER = 'TEACHER';

const Roles = {
    STUDENT,
    PARENT,
    TEACHER
}

export default Roles;