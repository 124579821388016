import {
    FETCH_CONTENTS_SUCCESS, FETCH_CONTENTS_ERROR
} from '../actions';

const INIT_STATE = {
    contentsData: [],
    contentsDataLoading: false,
    contentsDataError: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case FETCH_CONTENTS_SUCCESS:
            return {
                ...state,
                contentsDataLoading: false,
                contentsData: action.payload,
                contentsDataError: ''
            };
    
        
        case FETCH_CONTENTS_ERROR:
                return {
                    ...state,
                    contentsDataError: action.payload
                };

        default:
            return { ...state };
    }
};