import React, { Component, useEffect, useState } from 'react';
import { Link, useLocation, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Card, CardBody, Row, Col } from "reactstrap";
import {
    verifyStudent, resetDefault, addStudent, verifyStudentAgain
} from '../../store/actions';
import ProfileIcon from "../../assets/images/icons/profile.png";
import MathsIcon from "../../assets/images/icons/Maths.png";
import backIcon from "../../assets/images/icons/back.png";

import Session from '../../session/';

const AddStudent = ({
    history,
    onVerifyStudent,
    onResetDefault,
    onAddStudent,
    onResendOTP,
    subjects, subjLoading, subjError,
    chapters,
    loginUser, loginError, loginMessage
}) => {
    const [parentData, setParentData] = useState({});
    const [errors, setError] = useState({});
    const [userOTP, setOTP] = useState({});
    const [student, setStudent] = useState({});

    useEffect(() => {
        const prtData = Session.get('authUser');
        if (prtData && prtData.parentId) {
            setParentData(prtData);
            setParentData(prevState => ({
                ...prevState,
                uniqueStundetId: ''
            }));
        } else {
            history.push({
                pathname: '/login'
            });
        }
    }, []);

    useEffect(() => {
        if (loginMessage == 'verify student' && loginError == '' && loginUser && loginUser.length) {
            setOTP(prevState => ({
                ...prevState,
                otp: loginUser[0].mobileOTP
            }));
            document.getElementById("addStudentOTPDiv").style.display = 'block';
            document.getElementById("addStudentDiv").style.display = 'none';
            setStudent(prevState => ({
                ...prevState,
                studentId: loginUser[0].studentId
            }));
        }
        if (loginMessage == 'verify resend student' && loginError == '' && loginUser && loginUser.length) {
            setOTP(prevState => ({
                ...prevState,
                otp: loginUser[0].mobileOTP
            }));
        }
        if (loginMessage == 'verify student' && loginError) {
            setError(prevState => ({
                ...prevState,
                uniqueStundetId: loginError
            }));
        }
        if (loginMessage == 'verify student auth' && loginError == '' && loginUser && loginUser.length) {
            parentData.studentIds = loginUser[0].studentIds;
            parentData.studentInfos = loginUser[0].studentInfos;
            Session.set('authUser', JSON.stringify(parentData));
            onResetDefault(JSON.stringify(parentData));
            history.push({
                pathname: '/parent'
            });
        }
    }, [loginUser, loginError]);

    const handleInputChange = (e, type) => {
        e.preventDefault();
        if (e && e.target && e.target.value) {
            const inputValue = e.target.value;

            switch (type) {
                case 'uniqueStundetId': {
                    setParentData(prevState => ({
                        ...prevState,
                        uniqueStundetId: inputValue
                    }));
                    break;
                }
                case 'txtwithotpchange': {
                    setParentData(prevState => ({
                        ...prevState,
                        txtwithotpchange: inputValue
                    }));
                    break;
                }
            }
        }
    }

    const handleSaveChanges = () => {
        if (parentData.uniqueStundetId) {
            let data = {
                "uniqueStundetId":parentData.uniqueStundetId
            }
            onVerifyStudent(data);
        } else {
            setError(prevState => ({
                ...prevState,
                uniqueStundetId: (parentData.uniqueStundetId)? '' : 'Please enter value',
            }));
        }
    }

    const handleVerifyChanges = (e) => {
        e.preventDefault();
        if (parentData.txtwithotpchange) {
            if (parentData.txtwithotpchange == userOTP.otp) {
                if(parentData.studentIds) {
                    let x = Object.assign([], parentData.studentIds);
                    x.push(student.studentId);
                    let y = [... new Set(x)];
                    const data = {
                        "parentId":parentData.parentId,
                        "studentIds":"{" +y.toString()+ "}"
                    }
                    onAddStudent(data);
                } else {
                    const data = {
                        "parentId":parentData.parentId,
                        "studentIds":"{"+student.studentId+"}"
                    }
                    onAddStudent(data);
                }
            } else {
                setError(prevState => ({
                    ...prevState,
                    txtwithotpchange: 'Invalid OTP',
                }));
            }
        } else {
            setError(prevState => ({
                ...prevState,
                txtwithotpchange: (parentData.txtwithotpchange)? '' : 'Please enter value'
            }));
        }
    }

    const handleResendOTP = (e) => {
        let data = {
            "uniqueStundetId":parentData.uniqueStundetId
        }
        onResendOTP(data);
    }

    return (
        <div className="page-content">
            <Container fluid>
            <div className="row">
                <div className="col-sm-8">
                    <div className="courseHeadigMaindiv">
                        <div className="arrowandIcondiv">
                            <Link to="/parent"><img src={backIcon} /></Link>
                            <img className="card-img-top" style={{ width: "15%" }} src={MathsIcon} alt="Card image cap" /> <b>ADD STUDENT</b>
                        </div>
                    </div>
                </div>
            </div>
            <br />
                <div>
                    <span><img src={ProfileIcon} alt="Profile" /></span> <strong>Hello {parentData && parentData.parentName ? parentData.parentName : ''}</strong>
                    <br />
                    <p>My ID: {parentData && parentData.parentId ? parentData.parentId : ''} </p>
                </div>
                <h4>Add Student</h4>
                <br /><br />
                <div id="addStudentDiv">
                <div className="row">
                <div className="col-sm-3">
                <h6 className="font-size-15 welbackFontWeight">Enter your Student ID</h6>
                <input type="text" className="form-control iputFildBorderRadius"
                                                            placeholder="Student ID"
                                                            maxLength="13"
                                                            onChange={(e) => handleInputChange(e, 'uniqueStundetId')}></input>
                <p>You can find Student ID in student profile</p>
                {errors.uniqueStundetId ? (
                                                                <div className="invalid-feedback d-block">
                                                                {errors.uniqueStundetId}
                                                                </div>
                                                            ) : ''}
                </div>
                <div className="col-sm-9"></div> 
                </div>
                <br />
                <button className="btn btn-default buttonCss" onClick={handleSaveChanges}>Add Student</button><br /> 
                </div>

                <div id="addStudentOTPDiv" style={{ display: "none" }}>
                <div className="row">
                <div className="col">
                <h6 className="font-size-15 welbackFontWeight">Enter your OTP</h6>
                <input type="text" id="txtOTPPwd" className="form-control iputFildBorderRadius" onChange={(e) => handleInputChange(e, 'txtwithotpchange')} />
                <p>OTP has sent to student mobile number</p>
                {errors.txtwithotpchange ? (
                                                                <div className="invalid-feedback d-block">
                                                                {errors.txtwithotpchange}
                                                                </div>
                                                            ) : ''}
                </div>
                <div className="col"></div>
                </div>
                <br />
                <button className="btn btn-default buttonCss" onClick={handleVerifyChanges}>Verify & Continue</button><br />&nbsp;<span onClick={handleResendOTP}><a>Resend OTP</a></span>
                </div>
                <br />
            </Container>
        </div>
    );
}

const mapStateToProps = ({ SubjectsApp, ChaptersApp, Login }) => {
    const { subjects, subjLoading, subjError } = SubjectsApp;
    const { chapters } = ChaptersApp;
    const { loginUser, loginError, loginMessage } = Login;

    return {
        subjects, subjLoading, subjError,
        chapters,
        loginUser, loginError, loginMessage
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onVerifyStudent: (postData) => dispatch(verifyStudent(postData)),
        onResendOTP: (postData) => dispatch(verifyStudentAgain(postData)),
        onAddStudent: (postData) => dispatch(addStudent(postData)),
        onResetDefault: (postData) => dispatch(resetDefault(postData))
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps, null, {})(AddStudent));