export const BOARDS_GET_DETAILS = 'BOARDS_GET_DETAILS';
export const BOARDS_GET_DETAILS_SUCCESS = 'BOARDS_GET_DETAILS_SUCCESS';
export const BOARDS_GET_DETAILS_ERROR = 'BOARDS_GET_DETAILS_ERROR';

export const CLASSES_GET_DETAILS = 'CLASSES_GET_DETAILS';
export const CLASSES_GET_DETAILS_SUCCESS = 'CLASSES_GET_DETAILS_SUCCESS';
export const CLASSES_GET_DETAILS_ERROR = 'CLASSES_GET_DETAILS_ERROR';
export const CLASSES_GET_DETAILS_BY_BOARD = 'CLASSES_GET_DETAILS_BY_BOARD';

export const REGISTER_STUDENT = 'REGISTER_STUDENT';
export const REGISTER_STUDENT_SUCCESSFUL = 'REGISTER_STUDENT_SUCCESSFUL';
export const REGISTER_STUDENT_FAILED = 'REGISTER_STUDENT_FAILED';

export const SUBJECTS_GET_DETAILS = 'SUBJECTS_GET_DETAILS';
export const SUBJECTS_GET_DETAILS_SUCCESS = 'SUBJECTS_GET_DETAILS_SUCCESS';
export const SUBJECTS_GET_DETAILS_ERROR = 'SUBJECTS_GET_DETAILS_ERROR';

export const CHAPTERS_GET_DETAILS = 'CHAPTERS_GET_DETAILS';
export const CHAPTERS_GET_DETAILS_SUCCESS = 'CHAPTERS_GET_DETAILS_SUCCESS';
export const CHAPTERS_GET_DETAILS_ERROR = 'CHAPTERS_GET_DETAILS_ERROR';

export const TOPICS_GET_DETAILS = 'TOPICS_GET_DETAILS';
export const TOPICS_GET_DETAILS_SUCCESS = 'TOPICS_GET_DETAILS_SUCCESS';
export const TOPICS_GET_DETAILS_ERROR = 'TOPICS_GET_DETAILS_ERROR';
export const TOPICS_GET_RECENTS_SUCCESS = 'TOPICS_GET_RECENTS_SUCCESS';
export const TOPICS_GET_RECENTS_ERROR = 'TOPICS_GET_RECENTS_ERROR';
export const SUBMIT_VIEWED_BY_TOPIC_SUCCESS = 'SUBMIT_VIEWED_BY_TOPIC_SUCCESS';
export const SUBMIT_VIEWED_BY_TOPIC_ERROR = 'SUBMIT_VIEWED_BY_TOPIC_ERROR';
export const FETCH_CONTENTS_SUCCESS = 'FETCH_CONTENTS_SUCCESS';
export const FETCH_CONTENTS_ERROR = 'FETCH_CONTENTS_ERROR';

export const CHECK_LOGIN = 'check_login';
export const LOGIN_USER_SUCCESSFUL = 'login_user_successfull';
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGIN_USER_OTP_SUCCESSFUL = 'LOGIN_USER_OTP_SUCCESSFUL';
export const LOGIN_USER_OTP_AUTH_SUCCESSFUL = 'LOGIN_USER_OTP_AUTH_SUCCESSFUL';
export const LOGIN_USER_PWD_SUCCESSFUL = 'LOGIN_USER_PWD_SUCCESSFUL';
export const REGISTER_USER_OTP_SUCCESSFUL = 'REGISTER_USER_OTP_SUCCESSFUL';
export const REGISTER_USER_OTP_AUTH_SUCCESSFUL = 'REGISTER_USER_OTP_AUTH_SUCCESSFUL';
export const LOGIN_PARENT_OTP_SUCCESSFUL = 'LOGIN_PARENT_OTP_SUCCESSFUL';
export const LOGIN_PARENT_PWD_SUCCESSFUL = 'LOGIN_PARENT_PWD_SUCCESSFUL';
export const LOGIN_PARENT_OTP_AUTH_SUCCESSFUL = 'LOGIN_PARENT_OTP_AUTH_SUCCESSFUL';
export const LOGIN_PARENT_SUCCESSFUL = 'LOGIN_PARENT_SUCCESSFUL';
export const REGISTER_PARENT_SUCCESSFUL = 'REGISTER_PARENT_SUCCESSFUL';
export const REGISTER_PARENT_FAILED = 'REGISTER_PARENT_FAILED';
export const REGISTER_PARENT_OTP_SUCCESSFUL = 'REGISTER_PARENT_OTP_SUCCESSFUL';
export const API_REGISTER_PARENT_OTP_ERROR = 'API_REGISTER_PARENT_OTP_ERROR';
export const UPDATE_USER_SUCCESSFUL = 'UPDATE_USER_SUCCESSFUL';
export const UPDATE_USER_PROFILE_SUCCESSFUL = 'UPDATE_USER_PROFILE_SUCCESSFUL';
export const VERIFY_STUDENT_SUCCESSFUL = 'VERIFY_STUDENT_SUCCESSFUL';
export const ADD_STUDENT_SUCCESSFUL = 'ADD_STUDENT_SUCCESSFUL';
export const VERIFY_OTP_STUDENT_SUCCESSFUL = 'VERIFY_OTP_STUDENT_SUCCESSFUL';
export const API_ERROR = 'api_failed';
export const API_OTP_ERROR = "API_OTP_ERROR";
export const API_OTP_AUTH_ERROR = "API_OTP_AUTH_ERROR";
export const API_REGISTER_OTP_ERROR = "API_REGISTER_OTP_ERROR";
export const API_REGISTER_OTP_AUTH_ERROR = "API_REGISTER_OTP_AUTH_ERROR";
export const API_PWD_ERROR = "API_PWD_ERROR";
export const API_PARENT_OTP_ERROR = "API_PARENT_OTP_ERROR";
export const API_PARENT_PWD_ERROR = 'API_PARENT_PWD_ERROR';
export const API_PARENT_OTP_AUTH_ERROR = 'API_PARENT_OTP_AUTH_ERROR';
export const API_PARENT_ERROR = 'API_PARENT_ERROR';
export const API_UPDATE_ERROR = 'API_UPDATE_ERROR';
export const RESET_DEFAULT_STATUS = 'RESET_DEFAULT_STATUS';
export const API_VERIFY_STUDENT_ERROR = 'API_VERIFY_STUDENT_ERROR';
export const API_ADD_STUDENT_ERROR = 'API_ADD_STUDENT_ERROR';
export const NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS_SUCCESS';
export const NOTIFICATIONS_ERROR = 'NOTIFICATIONS_ERROR';

export const NOTES_GET_DETAILS = 'NOTES_GET_DETAILS';
export const NOTES_GET_DETAILS_SUCCESS = 'NOTES_GET_DETAILS_SUCCESS';
export const NOTES_GET_DETAILS_ERROR = 'NOTES_GET_DETAILS_ERROR';
export const NOTES_ADD_NEW = 'NOTES_ADD_NEW';
export const NOTES_GET_DETAILS_BY_TOPICID_AND_STUDENTID = 'NOTES_GET_DETAILS_BY_TOPICID_AND_STUDENTID';
export const QUESTIONS_BY_TOPIC_ID = 'QUESTIONS_BY_TOPIC_ID';
export const QUESTIONS_BY_TOPIC_ID_SUCCESS = 'QUESTIONS_BY_TOPIC_ID_SUCCESS';
export const QUESTIONS_BY_TOPIC_ID_ERROR = 'QUESTIONS_BY_TOPIC_ID_ERROR';
export const SUBMIT_QUESTIONS_BY_TOPIC_ID_SUCCESS = 'SUBMIT_QUESTIONS_BY_TOPIC_ID_SUCCESS';
export const SUBJECT_ANALYTICS_STUDENT_SUCCESSFUL = 'SUBJECT_ANALYTICS_STUDENT_SUCCESSFUL';
export const API_SUBJECT_ANALYTICS_ERROR = 'API_SUBJECT_ANALYTICS_ERROR';
export const SUBMIT_QUESTIONS_BY_TOPIC_ID_ERROR = 'SUBMIT_QUESTIONS_BY_TOPIC_ID_ERROR';

export const FEES_GET_DETAILS_BY_CLASS = 'FEES_GET_DETAILS_BY_CLASS';
export const FEES_GET_DETAILS_BY_CLASS_SUCCESS = 'FEES_GET_DETAILS_BY_CLASS_SUCCESS';
export const FEES_GET_DETAILS_BY_CLASS_ERROR = 'FEES_GET_DETAILS_BY_CLASS_ERROR';

export const CLASS_FEES_DETAILS_BY_BOARD = 'CLASS_FEES_DETAILS_BY_BOARD';
export const CLASS_FEES_DETAILS_BY_BOARD_SUCCESS = 'CLASS_FEES_DETAILS_BY_BOARD_SUCCESS';
export const CLASS_FEES_DETAILS_BY_BOARD_ERROR = 'CLASS_FEES_DETAILS_BY_BOARD_ERROR';

export const SUBSCRIPTIONS_BY_STUDENT = 'SUBSCRIPTIONS_BY_STUDENT';
export const SUBSCRIPTIONS_BY_STUDENT_SUCCESS = 'SUBSCRIPTIONS_BY_STUDENT_SUCCESS';
export const SUBSCRIPTIONS_BY_STUDENT_ERROR = 'SUBSCRIPTIONS_BY_STUDENT_ERROR';
export const ADD_SUBSCRIPTIONS = 'ADD_SUBSCRIPTIONS';

export const OFFERS_ACTIVE = 'OFFERS_ACTIVE';
export const OFFERS_ACTIVE_SUCCESS = 'OFFERS_ACTIVE_SUCCESS';
export const OFFERS_ACTIVE_ERROR = 'OFFERS_ACTIVE_ERROR';

export const ASSIGNMENTS = 'ASSIGNMENTS';
export const ASSIGNMENTS_SUCCESS = 'ASSIGNMENTS_SUCCESS';
export const ASSIGNMENTS_ERROR = 'ASSIGNMENTS_ERROR';

export * from './layout/actions';
// Authentication module
export * from './auth/register/actions';
export * from './auth/login/actions';
export * from './auth/forgetpwd/actions';

export * from './boards/actions';
export * from './classes/actions';
export * from './subjects/actions';
export * from './chapters/actions';
export * from './topics/actions';
export * from './notes/actions';

export * from './questions/actions';
export * from './pricing/actions';

export * from './classes-fees/actions';
export * from './subscriptions/actions';

export * from './offers/actions';
export * from './assignments/actions';