import {
    CLASSES_GET_DETAILS,
    CLASSES_GET_DETAILS_SUCCESS,
    CLASSES_GET_DETAILS_ERROR,
    CLASSES_GET_DETAILS_BY_BOARD
} from '../actions';

const INIT_STATE = {
    classes: [],
    classesLoading: false,
    classesError: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CLASSES_GET_DETAILS:
            return { ...state, classesLoading: true };

        case CLASSES_GET_DETAILS_SUCCESS:
            return { ...state, classesLoading: false, classes: action.payload, classesError: '' };

        case CLASSES_GET_DETAILS_ERROR:
            return { ...state, classesLoading: false, classesError: action.payload, classes: [] };

        case CLASSES_GET_DETAILS_BY_BOARD: {
            return {
                ...state,
                classes: state.classes.filter(cls => cls.boardId !== action.payload)

            }
        }

        default:
            return { ...state };
    }
};