import {
    TOPICS_GET_DETAILS,
    TOPICS_GET_DETAILS_SUCCESS,
    TOPICS_GET_DETAILS_ERROR,
    TOPICS_GET_RECENTS_SUCCESS, TOPICS_GET_RECENTS_ERROR
} from '../actions';

const INIT_STATE = {
    topics: [],
    topicsLoading: false,
    topicsError: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case TOPICS_GET_DETAILS:
            return { ...state, loading: true };

        case TOPICS_GET_DETAILS_SUCCESS:
            return { ...state, loading: false, topics: action.payload, error: '' };

        case TOPICS_GET_DETAILS_ERROR:
            return { ...state, loading: false, error: action.payload, boards: [] };

        case TOPICS_GET_RECENTS_SUCCESS:
                return { ...state, loading: false, topics: action.payload, error: '' };
    
        case TOPICS_GET_RECENTS_ERROR:
                return { ...state, loading: false, error: action.payload, boards: [] };

        default:
            return { ...state };
    }
};