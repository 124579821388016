// INFI LEARN PROJECT CONSTANTS
// import { UserRole } from "../helpers/authHelper";
import { BASE_URL, BASE_URL_NEW_BACKEND, REACT_APP_SITE_ENV } from "../env-config";


// export const baseUrl = '/subj-mgmt/api/v1/';
export const baseUrl = BASE_URL; // 'https://learning.infelearn.com/subj-mgmt/api/v1/';
// export const baseUrl = 'https://www.infelearn.com/subj-mgmt/api/v1/';
// export const baseUrl = 'http://localhost:8085/subj-mgmt/api/v1/';
// export const baseUrl = "http://localhost:3003/subj-mgmt/api/v1/";
// export const baseUrlNewBackend = "http://localhost:8000/subj-mgmt-new/api/v1/";
export const baseUrlNewBackend = BASE_URL_NEW_BACKEND; //'https://www.infelearn.com/subj-mgmt-new/api/v1/';

export const baseUrl2 = 'http://infelearn.com/subj-mgmt/api/v1/';
export const baseUrl1 = 'https://www.infelearn.com/subj-mgmt/api/v1/';

export const endpoints = {
  getBoards: 'boards',
  getClassesByBoard: 'classesbyboards',
  postStudent: 'student',
  teacherlogin: 'login/teacher',
  studentlogin: 'studentlogin',
  studentotpLogin: 'sendotplogin',
  studentloginotp: 'studentloginotp',
  verifystudent: 'verifystudent',
  verifyparent: 'verifyparent',
  verifyteacher: 'verify/teacher',
  verifystudentuniqueid: 'verifystudentuniqueid',
  getrecents: 'student/getrecents',
  addtorecent: 'student/addtorecent',
  getcontents: 'getcontents',
  parent: 'parent',
  parentsendotp: 'parentsendotp',
  parentloginotp: 'parentloginotp',
  parentsendotplogin: 'parentsendotplogin',
  parentlogin: 'parentlogin',
  exambysubject: 'analytics/exambysubject',
  updateparent: 'updateparent',
  registerStudent: 'sendotp',
  registerStudentotp: 'student',
  updatestudent: 'updatestudent',
  getSubjectsByClass: 'subjectsbyclass',
  getChaptersBySubject: 'getchapterbysubjects',
  getTopicsByChapter: 'topicsbychapters',
  submitanswerfortopic: 'submitanswerfortopic',
  submitanswerforchapter: 'submitanswerforchapter',
  submitanswerbymode: 'submitanswerbymode',
  postNotes: 'saveNotes',
  getNotes: 'getNotes',
  getquestionsfortopic: 'getquestionsfortopic',
  getquestionsbymode: 'getquestionsbymode',
  getquestionsforchapter: 'getquestionsforchapter',
  oneclassfeesdetails: 'oneclassfeesdetails',
  stdNotifications: 'notifications/student',

  classfeesdetailsbyboard: 'classfeesdetailsbyboard',
  subscriptionbystudent: 'subscriptionbystudent',
  addSubscriptions: 'subscribe',

  getActiveOffers: 'activeoffers',
  assignmentsBySubject: 'student/papersbysubjectid'
};
export const headers = {
  'Content-Type': 'application/json'
};
export const uploadFileHeaders = {
  headers: {
    'content-type': 'application/x-www-form-urlencoded'
  }
}
export const projectConfig = {
  baseUrl,
  baseUrlNewBackend,
  endpoints,
  headers,
  uploadFileHeaders
};
