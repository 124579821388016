import {
    FEES_GET_DETAILS_BY_CLASS,
    FEES_GET_DETAILS_BY_CLASS_SUCCESS,
    FEES_GET_DETAILS_BY_CLASS_ERROR
}
    from '../actions';

const INIT_STATE = {
    feesDetailsByClass: [],
    feesLoading: false,
    feesError: ''

};


export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case FEES_GET_DETAILS_BY_CLASS_SUCCESS:
            return { ...state, feesLoading: false, feesDetailsByClass: action.payload, feesError: '' };

        case FEES_GET_DETAILS_BY_CLASS_ERROR:
            return { ...state, feesLoading: false, feesError: action.payload, feesDetailsByClass: [] };

        case FEES_GET_DETAILS_BY_CLASS: {
            return {
                ...state,
                feesDetailsByClass: state.feesDetailsByClass.filter(fessDetailsCls => fessDetailsCls.classId !== action.payload)

            }
        }

        default:
            return { ...state };
    }
};