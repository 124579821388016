import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useLocation, withRouter } from "react-router-dom";
import { Container } from "reactstrap";
import MathsIcon from "../../assets/images/icons/Maths.png";
import backIcon from "../../assets/images/icons/back.png";
import Session from "../../session";
// import loader from "../../assets/images/loader.gif";
import {
  fetchQuestionsByTopic,
  submitAnswerForTopic,
  fetchQuestionsByChapter,
  fetchQuestionsByMode,
  submitAnswerForChapter,
  submitAnswerForMode,
} from "../../store/actions";
import ReactApexChart from "react-apexcharts";

const ModeWiseQuestions = ({
  history,
  onFetchQuestionsByTopic,
  onFetchQuestionsByChapter,
  onFetchQuestionsByMode,
  quetionsByTopic,
  quetionsByTopicLoading,
  quetionsByTopicError,
  onSubmitAnswerForTopic,
  onSubmitAnswerForChapter,
  onSubmitAnswerForMode,
  submittopicQuesRes,
  submittopicQuesError,
}) => {
  // const [myQues, setMyQues] = useState();
  const location = useLocation();
  const [queIndex, setQueIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedTopicInfoForQuestions, setSelectedTopicInfoForQuestions] =
    useState([]);
  const [studentDataInModeWiseQuestions, setStudentDataInModeWiseQuestions] =
    useState({});
  const analyticsData = {
    easy: 0,
    easyCorrect: 0,
    medium: 0,
    mediumCorrect: 0,
    hard: 0,
    hardCorrect: 0,
  };
  const donutChart = {
    options: {
      legend: {
        show: true,
        position: "left",
      },
      chart: {
        width: 380,
        type: "donut",
      },
      subtitles: [
        {
          text: "71% Positive",
          verticalAlign: "center",
          fontSize: 24,
          dockInsidePlotArea: true,
        },
      ],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: false,
              },
            },
          },
        },
      },
      colors: ["#626ed4", "#02a499", "#f8b425"],
      series: [],
      labels: ["Easy", "Medium", "Hard"],
    },
  };
  const [chartData, setChartData] = useState(donutChart);
  const [analytics, setAnalytics] = useState(analyticsData);
  const [questions, setQuestions] = useState([]);
  const [second, setSecond] = useState("00");
  const [minute, setMinute] = useState("00");
  const [counter, setCounter] = useState(100);
  // const [myQue, setMyQue] = useState(quetionsByTopic);
  //const [courseInfoInCourseTopics, setcourseInfoInCourseTopics] = useState({});
  useEffect(() => {
    setStudentDataInModeWiseQuestions(Session.get("authUser"));
    //setcourseInfoInCourseTopics(JSON.parse(Session.get("studentChaptersInfo")));
    setSelectedTopicInfoForQuestions(
      JSON.parse(Session.get("topicInfoForQuestions"))
    );
  }, []);

  useEffect(() => {
    setSelectedOption("");
  }, [queIndex]);

  useEffect(() => {
    if (quetionsByTopic.length) {
      setCounter(quetionsByTopic.length * 120);
    }
  }, [quetionsByTopic]);

  // if (quetionsByTopic && quetionsByTopic.length > 0) {
  //     document.getElementById("questionsDivbyTopicWise").style.display = 'block';
  // }

  // if (selectedTopicInfoForQuestions && selectedTopicInfoForQuestions.topicId) {
  //     let topicId = selectedTopicInfoForQuestions.topicId;
  //     onFetchQuestionsByTopic({
  //         topicId: topicId,
  //         studentId: studentDataInModeWiseQuestions.studentId
  //     });
  //     // .then(resp => {
  //     //     console.log(quetionsByTopic);
  //     //     console.log('#########', JSON.stringify(resp));
  //     // });
  // }

  useEffect(() => {
    if (
      selectedTopicInfoForQuestions &&
      selectedTopicInfoForQuestions.topicId
    ) {
      let topicId = selectedTopicInfoForQuestions.topicId;
      if (location && location.state.type === "topic") {
        onFetchQuestionsByTopic({
          topicId: topicId,
          studentId: studentDataInModeWiseQuestions.studentId,
        });
      }
    }
    console.log(JSON.parse(Session.get("studentChaptersInfo")).chapterId);
    if (location && location.state.type === "chapter") {
      onFetchQuestionsByChapter({
        chapterId: JSON.parse(Session.get("studentChaptersInfo")).chapterId,
        studentId: studentDataInModeWiseQuestions.studentId,
      });
    } else if (
      location &&
      (location.state.type === "easy" ||
        location.state.type === "medium" ||
        location.state.type === "hard")
    ) {
      onFetchQuestionsByMode({
        chapterId: JSON.parse(Session.get("studentChaptersInfo")).chapterId,
        studentId: studentDataInModeWiseQuestions.studentId,
        examMode: location.state.type,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTopicInfoForQuestions]);

  useEffect(() => {
    let intervalId;
    if (counter === 1) {
      const postData = {
        topicId:
          selectedTopicInfoForQuestions && selectedTopicInfoForQuestions.topicId
            ? selectedTopicInfoForQuestions.topicId
            : null,
        studentId: studentDataInModeWiseQuestions.studentId,
        subjectId: JSON.parse(Session.get("studentSubjectInfo")).subjectId,
        analytics: analytics,
      };
      if (
        location &&
        location.state.type !== "topic" &&
        location.state.type !== "chapter"
      ) {
        postData["examMode"] = location.state.type;
        postData["chapterId"] = JSON.parse(
          Session.get("studentChaptersInfo")
        ).chapterId;
      }
      if (location && location.state.type === "topic") {
        postData["topicId"] =
          selectedTopicInfoForQuestions && selectedTopicInfoForQuestions.topicId
            ? selectedTopicInfoForQuestions.topicId
            : null;
      }
      if (location && location.state.type === "chapter") {
        postData["chapterId"] = JSON.parse(
          Session.get("studentChaptersInfo")
        ).chapterId;
      }
      const seriesData = [
        (100 * analytics.easyCorrect) / analytics.easy === 0
          ? 10
          : (100 * analytics.easyCorrect) / analytics.easy,
        (100 * analytics.mediumCorrect) / analytics.medium === 0
          ? 10
          : (100 * analytics.mediumCorrect) / analytics.medium,
        (100 * analytics.hardCorrect) / analytics.hard === 0
          ? 10
          : (100 * analytics.hardCorrect) / analytics.hard,
      ];
      setChartData({
        options: {
          ...chartData.options,
          series: seriesData,
        },
      });
      if (location && location.state.type === "topic") {
        onSubmitAnswerForTopic(postData);
      } else if (location && location.state.type === "chapter") {
        onSubmitAnswerForChapter(postData);
      } else {
        onSubmitAnswerForMode(postData);
      }
      document.getElementById("questionsDivbyTopicWise").style.display = "none";
      document.getElementById("testResult").style.display = "block";
      document.getElementById("timerDiv").style.display = "none";
    }
    intervalId = setInterval(() => {
      const secondCounter = counter % 60;
      const minuteCounter = Math.floor(counter / 60);

      const computedSecond =
        String(secondCounter).length === 1
          ? `0${secondCounter}`
          : secondCounter;
      const computedMinute =
        String(minuteCounter).length === 1
          ? `0${minuteCounter}`
          : minuteCounter;

      setSecond(computedSecond);
      setMinute(computedMinute);

      setCounter((counter) => counter - 1);
    }, 1000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);

  const stopTimer = () => {
    setCounter(0);
    setSecond("00");
    setMinute("00");
  };

  const handleNextQuestion = (e, qId) => {
    var ele = document.getElementsByName("choose");
    for (var i = 0; i < ele.length; i++) {
      ele[i].checked = false;
    }
    let nextIndex = queIndex + 1;
    setQueIndex(nextIndex);
    const storeOption = quetionsByTopic.filter((que, index) => {
      return que.questionId === qId;
    });
    let selectedAnswer = questions;
    storeOption[0].selectedAnswer = selectedOption;
    storeOption[0].isCorrect =
      storeOption[0].answerIndex === selectedOption ? true : false;
    selectedAnswer.push(storeOption[0]);
    quetionsByTopic[queIndex].selectedAnswer = selectedOption;
    quetionsByTopic[queIndex].isCorrect =
      storeOption[0].answerIndex === selectedOption ? true : false;
    setQuestions(selectedAnswer);
    if (storeOption[0] && storeOption[0]["questionMode"] === "easy") {
      setAnalytics((prevState) => ({
        ...prevState,
        easy: analytics.easy + 1,
        easyCorrect:
          storeOption[0]["answerIndex"] === selectedOption
            ? analytics.easyCorrect + 1
            : analytics.easyCorrect,
      }));
    } else if (storeOption[0] && storeOption[0]["questionMode"] === "medium") {
      setAnalytics((prevState) => ({
        ...prevState,
        medium: analytics.medium + 1,
        mediumCorrect:
          storeOption[0]["answerIndex"] === selectedOption
            ? analytics.mediumCorrect + 1
            : analytics.mediumCorrect,
      }));
    } else if (storeOption[0] && storeOption[0]["questionMode"] === "hard") {
      setAnalytics((prevState) => ({
        ...prevState,
        hard: analytics.hard + 1,
        hardCorrect:
          storeOption[0]["answerIndex"] === selectedOption
            ? analytics.hardCorrect + 1
            : analytics.mediumCorrect,
      }));
    }
    if (storeOption[0] && storeOption[0]["answerIndex"] === selectedOption) {
      let s = score + 1;
      setScore(s);
    }
  };
  //   const handlePreviousQuestion = (e) => {
  //     let prevIndex = queIndex - 1;
  //     setQueIndex(prevIndex);
  //   };
  const handleOptionChange = (e) => {
    setSelectedOption(Number(e.target.value));
  };
  const handleTestSummary = async (e, qId) => {
    stopTimer();
    const storeOption = quetionsByTopic.filter((que, index) => {
      return que.questionId === qId;
    });
    let selectedAnswer = questions;
    storeOption[0].selectedAnswer = selectedOption;
    storeOption[0].isCorrect =
      storeOption[0].answerIndex === selectedOption ? true : false;
    selectedAnswer.push(storeOption[0]);
    quetionsByTopic[queIndex].selectedAnswer = selectedOption;
    quetionsByTopic[queIndex].isCorrect =
      storeOption[0].answerIndex === selectedOption ? true : false;
    setQuestions(selectedAnswer);
    const finalAnalytics = analytics;
    if (storeOption[0] && storeOption[0]["questionMode"] === "easy") {
      setAnalytics((prevState) => ({
        ...prevState,
        easy: analytics.easy + 1,
        easyCorrect:
          storeOption[0]["answerIndex"] === selectedOption
            ? analytics.easyCorrect + 1
            : analytics.easyCorrect,
      }));
      finalAnalytics.easy = analytics.easy + 1;
      finalAnalytics.easyCorrect =
        storeOption[0]["answerIndex"] === selectedOption
          ? analytics.easyCorrect + 1
          : analytics.easyCorrect;
    } else if (storeOption[0] && storeOption[0]["questionMode"] === "medium") {
      setAnalytics((prevState) => ({
        ...prevState,
        medium: analytics.medium + 1,
        mediumCorrect:
          storeOption[0]["answerIndex"] === selectedOption
            ? analytics.mediumCorrect + 1
            : analytics.mediumCorrect,
      }));
      finalAnalytics.medium = analytics.medium + 1;
      finalAnalytics.mediumCorrect =
        storeOption[0]["answerIndex"] === selectedOption
          ? analytics.mediumCorrect + 1
          : analytics.mediumCorrect;
    } else if (storeOption[0] && storeOption[0]["questionMode"] === "hard") {
      setAnalytics((prevState) => ({
        ...prevState,
        hard: analytics.hard + 1,
        hardCorrect:
          storeOption[0]["answerIndex"] === selectedOption
            ? analytics.hardCorrect + 1
            : analytics.mediumCorrect,
      }));
      finalAnalytics.hard = analytics.hard + 1;
      finalAnalytics.hardCorrect =
        storeOption[0]["answerIndex"] === selectedOption
          ? analytics.hardCorrect + 1
          : analytics.hardCorrect;
    }
    if (storeOption[0] && storeOption[0]["answerIndex"] === selectedOption) {
      let s = score + 1;
      setScore(s);
    }
    const postData = {
      studentId: studentDataInModeWiseQuestions.studentId,
      subjectId: JSON.parse(Session.get("studentSubjectInfo")).subjectId,
      analytics: finalAnalytics,
    };
    if (
      location &&
      location.state.type !== "topic" &&
      location.state.type !== "chapter"
    ) {
      postData["examMode"] = location.state.type;
      postData["chapterId"] = JSON.parse(
        Session.get("studentChaptersInfo")
      ).chapterId;
    }
    if (location && location.state.type === "topic") {
      postData["topicId"] =
        selectedTopicInfoForQuestions && selectedTopicInfoForQuestions.topicId
          ? selectedTopicInfoForQuestions.topicId
          : null;
    }
    if (location && location.state.type === "chapter") {
      postData["chapterId"] = JSON.parse(
        Session.get("studentChaptersInfo")
      ).chapterId;
    }
    const finalData = {
      easy: 0,
      easyCorrect: 0,
      medium: 0,
      mediumCorrect: 0,
      hard: 0,
      hardCorrect: 0,
    };
    finalData.easy = finalData.easy + finalAnalytics.easy;
    finalData.easyCorrect = finalData.easyCorrect + finalAnalytics.easyCorrect;
    finalData.medium = finalData.medium + finalAnalytics.medium;
    finalData.mediumCorrect =
      finalData.mediumCorrect + finalAnalytics.mediumCorrect;
    finalData.hard = finalData.hard + finalAnalytics.hard;
    finalData.hardCorrect = finalData.hardCorrect + finalAnalytics.hardCorrect;
    const seriesData = [
      (100 * finalData.easyCorrect) / finalData.easy === 0
        ? 10
        : (100 * finalData.easyCorrect) / finalData.easy,
      (100 * finalData.mediumCorrect) / finalData.medium === 0
        ? 10
        : (100 * finalData.mediumCorrect) / finalData.medium,
      (100 * finalData.hardCorrect) / finalData.hard === 0
        ? 10
        : (100 * finalData.hardCorrect) / finalData.hard,
    ];
    setChartData({
      options: {
        ...chartData.options,
        series: seriesData,
      },
    });
    if (location && location.state.type === "topic") {
      await onSubmitAnswerForTopic(postData);
    } else if (location && location.state.type === "chapter") {
      await onSubmitAnswerForChapter(postData);
    } else {
      await onSubmitAnswerForMode(postData);
    }
    document.getElementById("questionsDivbyTopicWise").style.display = "none";
    document.getElementById("testResult").style.display = "block";
    document.getElementById("timerDiv").style.display = "none";
  };

  return (
    <div className="page-content">
      <Container fluid>
        <div className="row">
          <div className="col-sm-4">
            <div className="courseHeadigMaindiv">
              <div className="arrowandIcondiv">
                <Link to="/testmode">
                  <img src={backIcon} alt="back-icon" />
                </Link>
                <img
                  className="card-img-top"
                  style={{ width: "46%" }}
                  src={MathsIcon}
                  alt="maths-icon"
                />
              </div>
              <div className="courseHeadingdiv">
                <span className="subjHeading">
                  {selectedTopicInfoForQuestions &&
                  selectedTopicInfoForQuestions.topicTitle
                    ? selectedTopicInfoForQuestions.topicTitle
                    : "Chapter Wise Test"}
                </span>
                <br />
                <span className="cardTextFont">38 chapters | 80 lessons</span>
              </div>
            </div>
          </div>
          {/* <div className="col-sm-2">
                        <Link to="/typewisetest">  <button className="btn btn-default defaultButtonCss">Back to Topics</button></Link>
                    </div> */}
        </div>
        <hr />
        <br />
        <div className="row" style={{ marginLeft: "28%" }} id="timerDiv">
          <h4>
            Your Test Ends in{" "}
            <span className="subjectError">
              {minute} : {second}
            </span>
          </h4>
        </div>
        <br />
        <div id="testResult" style={{ display: "none" }}>
          <div className="row">
            <h1>Your Test Score: {score}</h1>
          </div>
          <br />
          <br />
          {chartData && chartData.options.series.length ? (
            <div className="row">
              <div class="col">
                <div className="card cardDiv">
                  <h5 className="card-title studentSubjectName">
                    Test Analysis
                  </h5>
                  <div className="card-body">
                    <ReactApexChart
                      options={chartData.options}
                      series={chartData.options.series}
                      type="donut"
                      height="220"
                      width="400"
                    />
                  </div>
                </div>
              </div>
              <div class="col"></div>
            </div>
          ) : (
            <div></div>
          )}
          <div className="questionOptionsDiv">
            {quetionsByTopic.length &&
              quetionsByTopic.map((q, index) => {
                return (
                  <div key={index} className="inputQuestionOptionsDiv">
                    <label className="lblOptions">
                      Question - {index + 1}: {q.questionTitle}
                    </label>
                    <br />
                    <label className="lblOptions">
                      Actual Answer: {q.questionOptions[q.answerIndex]}
                    </label>
                    <br />
                    <label className="lblOptions">
                      Submitted Answer:{" "}
                      {q.selectedAnswer === undefined
                        ? "Null"
                        : q.questionOptions[q.selectedAnswer]}
                    </label>
                    <br />
                    <label className="lblOptions">
                      Result:{" "}
                      {q.isCorrect === undefined ? (
                        <span className="subjectError">Un Answered</span>
                      ) : q.isCorrect === true ? (
                        <span className="subjectSuccess">Correct</span>
                      ) : (
                        <span className="subjectError">Wrong</span>
                      )}
                    </label>
                  </div>
                );
              })}
          </div>
        </div>
        <div id="questionsDivbyTopicWise">
          {/* {quetionsByTopicLoading === true ? <img src={loader} /> : ''} */}
          <div className="row">
            <span className="questionNumber">Question: {queIndex + 1}</span>
          </div>
          <div className="row">
            <div className="col-sm-8 questionTitleDiv">
              <b className="questionTitle">
                {quetionsByTopic.length &&
                  quetionsByTopic[queIndex].questionTitle}
              </b>
            </div>
          </div>
          <div className="questionOptionsDiv">
            {quetionsByTopic.length &&
              quetionsByTopic[queIndex].questionOptions.map((option, index) => {
                return (
                  <div
                    key={index}
                    className="inputQuestionOptionsDiv"
                    onChange={(e) => {
                      handleOptionChange(e);
                    }}
                  >
                    <input
                      id={"radio" + index}
                      type="radio"
                      name="choose"
                      value={index}
                      style={{
                        display: "inline-block",
                        marginLeft: "11px",
                      }}
                    />
                    <label
                      htmlFor={"radio" + index}
                      className="lblOptions"
                      style={{
                        width: "97%",
                        padding: "1%",
                        marginBottom: "0px",
                      }}
                    >
                      {option}
                    </label>
                  </div>
                );
              })}
            <div className="prevAndNextBtns row">
              {/* <div className="col-sm-3">
                  <button className="btn btn-default defaultButtonCss" disabled={queIndex === 0}
                      onClick={() => { handlePreviousQuestion() }}>
                      Previous
                  </button>
              </div> */}
              {/* <div className="col-sm-3">
                  <button className="btn btn-default defaultButtonCss" onClick={() => { handleNoData() }}>
                      Dummy
                  </button>
              </div> */}
              <div className="col-sm-5"></div>
              <div className="col-sm-4">
                <button
                  className="btn btn-primary"
                  hidden={queIndex === quetionsByTopic.length - 1}
                  onClick={(e) => {
                    handleNextQuestion(
                      e,
                      quetionsByTopic[queIndex] &&
                        quetionsByTopic[queIndex].questionId
                    );
                  }}
                >
                  Next
                </button>
                <button
                  hidden={!(queIndex === quetionsByTopic.length - 1)}
                  className="btn btn-primary"
                  onClick={(e) => {
                    handleTestSummary(
                      e,
                      quetionsByTopic[queIndex] &&
                        quetionsByTopic[queIndex].questionId
                    );
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = ({ QuestionsApp }) => {
  const {
    quetionsByTopic,
    quetionsByTopicLoading,
    quetionsByTopicError,
    submittopicQuesRes,
    submittopicQuesError,
  } = QuestionsApp;

  return {
    quetionsByTopic,
    quetionsByTopicLoading,
    quetionsByTopicError,
    submittopicQuesRes,
    submittopicQuesError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchQuestionsByTopic: (data) => dispatch(fetchQuestionsByTopic(data)),
    onFetchQuestionsByChapter: (data) =>
      dispatch(fetchQuestionsByChapter(data)),
    onFetchQuestionsByMode: (data) => dispatch(fetchQuestionsByMode(data)),
    onSubmitAnswerForTopic: (data) => dispatch(submitAnswerForTopic(data)),
    onSubmitAnswerForChapter: (data) => dispatch(submitAnswerForChapter(data)),
    onSubmitAnswerForMode: (data) => dispatch(submitAnswerForMode(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, {})(ModeWiseQuestions)
);
