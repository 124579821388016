import { toast } from 'react-toastify';
import {
    NOTES_GET_DETAILS,
    NOTES_GET_DETAILS_SUCCESS,
    NOTES_GET_DETAILS_ERROR,
    NOTES_ADD_NEW,
    NOTES_GET_DETAILS_BY_TOPICID_AND_STUDENTID
} from '../actions';

export const getNotesDetails = () => ({
    type: NOTES_GET_DETAILS
});

export const addNewNotesDetails = (item) => ({
    type: NOTES_ADD_NEW,
    payload: item
});

export const getNotesDetailsSuccess = (items) => ({
    type: NOTES_GET_DETAILS_SUCCESS,
    payload: items
});

export const getNotesDetailsError = (error) => ({
    type: NOTES_GET_DETAILS_ERROR,
    payload: error,
});

export const getNotesDetailsByTopicAndStudent = (item1, item2) => ({
    type: NOTES_GET_DETAILS_BY_TOPICID_AND_STUDENTID,
    payload: item1, item2
});

export const saveNotesDetails = (postData) => {
    return (dispatch, getState, { axios, baseUrl, endpoints }) => {
        return axios.post(baseUrl + endpoints.postNotes, postData)
            .then(response => {
                return response.data;
            }).then(data => {
                dispatch(addNewNotesDetails(data.response.data[0]));
                toast.configure()
                //toast.success('Successfully Added', { autoClose: 3000 })
                alert('Successfully Added')
            }).catch((error) => {
                dispatch(getNotesDetailsError('Something went wrong. Try again after sometime.'));
            });
    }
};

export const fetchNotesDetailsByTopic = (TopicAndStudentData) => {
    return (dispatch, getState, { axios, baseUrl, endpoints }) => {

        dispatch(getNotesDetailsByTopicAndStudent());

        return axios.post(baseUrl + endpoints.getNotes, { "topicId": TopicAndStudentData.topicId, "studentId": TopicAndStudentData.studentId })
            .then(response => {
                console.log("response.data", response.data.response.data);
                return response.data;
            }).then(data => {
                dispatch(getNotesDetailsSuccess(data.response.data));
            }).catch(() => {

                dispatch(getNotesDetailsError('Something went wrong. Try again after sometime.'));
            });
    }
};

