import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";
import { changeSidebarType } from "../../store/actions";

const LiveClass = ({ history }) => {
  const [loading, setLoading] = useState(true)
  const [api, setApi] = useState(null)
  const location = useLocation()
  const dispatch = useDispatch()
  const initalLeftSideBarType = useSelector(({ Layout }) => Layout.leftSideBarType, () => true)

  useEffect(() => {
    if (!api) return;

    api.addListener("readyToClose", leaveScreen)

    return () => {
      api.removeListener("readyToClose", leaveScreen)
    }
  }, [api])

  useEffect(() => {
    const { data } = location.state || {};
    if (!data) {
      history.replace("/")
      return;
    }

    dispatch(changeSidebarType("hide"));
    const JitsiMeetExternalAPI = window.JitsiMeetExternalAPI;
    const api = new JitsiMeetExternalAPI("8x8.vc", {
      roomName: data.room_name,
      jwt: data.jwt,
      width: "100%",
      height: "100%",
      parentNode: document.querySelector("#e-meet"),
    });
    setApi(api)
    setLoading(false)

    return () => {
      dispatch(changeSidebarType(initalLeftSideBarType));
    }
  }, [])

  const leaveScreen = () => {
    history.goBack()
  }

  return <div class="liveclass-container">
    {loading && (<div>
      Loading...
    </div>)}
    <div id="e-meet" style={{ width: "100%", height: "100%" }}></div>
  </div>
}

export default withRouter(LiveClass);