import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation, withRouter } from "react-router-dom";
import { Container, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import MathsIcon from "../../assets/images/icons/Maths.png";
import smallPlayIcon from "../../assets/images/icons/small_play.png";
import backIcon from "../../assets/images/icons/back.png";
import VidepImage from "../../assets/images/authentication-bg.jpg";
import defaultImage from "../../assets/images/defaultImage.png";
import loader from "../../assets/images/loader.gif";
import ReactPlayer from 'react-player';
import {
    fetchSubscriptionsByStudent,
    fetchQuestionsByTopic, submitViewdDataByStudent
} from '../../store/actions';
import Session from '../../session';
import Utility from '../../utils/subscriptions';
import ModalUtil from '../../utils/modal';
import PlayIcon from "../../assets/images/icons/Play.png";


const CourseTopics = ({
    topics,
    history,
    onFetchQuestionsByTopic,
    quetionsByTopic,
    quetionsByTopicLoading,
    quetionsByTopicError,
    onfetchSubscriptionsByStudent,
    subscriptionsByStudent,
    onSubmitViewdDataByStudent
}) => {
    const userSession = Session.get('authUser');
    // const [myQues, setMyQues] = useState();
    const location = useLocation();
    const [queIndex, setQueIndex] = useState(0);
    const [score, setScore] = useState(0);
    const [selectedOption, setSelectedOption] = useState('');
    // const [modal, setModal] = useState(false);
    // const toggle = () => setModal(!modal);

    const [showModal, setModal] = useState(false);
    const modalDetails = {
        heading: '',
        body: "You haven't subscribed to show more topics. Get the best learning from infelearn",
        controls: 'View Pricing'
    };

    // Actual questions display logic
    // if (quetionsByTopic && quetionsByTopic.length > 0) {
    //     document.getElementById("questionsDivbyTopicWise").style.display = 'block';
    // } else {
    //     document.getElementById("questionsDivbyTopicWise").style.display = 'none';
    // }
    const [studentDataInCourseTopics, setStudentDataInCourseTopics] = useState({});
    const [courseInfoInCourseTopics, setcourseInfoInCourseTopics] = useState({});

    useEffect(() => {
        // Read studentId from User Session Module
        onfetchSubscriptionsByStudent({
            studentId: userSession.studentId
        });
    }, []);

    useEffect(() => {
        setStudentDataInCourseTopics(Session.get('authUser'));
        //console.log("location.state", location.state.chapterData);
        setcourseInfoInCourseTopics(JSON.parse(Session.get("studentChaptersInfo")));
    }, [quetionsByTopic,
        quetionsByTopicLoading,
        quetionsByTopicError, location]);

    const handleViewTopics = () => {
        document.getElementById("typesOfTestDiv").style.display = 'block';
        document.getElementById("btnBackToTopicsDiv").style.display = 'block';
        document.getElementById("topicsDivWithVideos").style.display = 'none';
        document.getElementById("btnWriteTestDiv").style.display = 'none';
    }
    const handleViewTopicsByTopicWise = () => {
        document.getElementById("typesOfTestDiv").style.display = 'none';
        document.getElementById("topicsListDivForTest").style.display = 'block';
        document.getElementById("topicsDivWithVideos").style.display = 'none';
        document.getElementById("btnWriteTestDiv").style.display = 'none';
    }
    const handleViewQuestionsByTopic = (e, topic) => {
        e.preventDefault();
        document.getElementById("typesOfTestDiv").style.display = 'none';
        document.getElementById("questionsDivbyTopicWise").style.display = 'block';
        document.getElementById("topicsDivWithVideos").style.display = 'none';
        document.getElementById("btnWriteTestDiv").style.display = 'none';
        document.getElementById("topicsListDivForTest").style.display = 'none';

        if (topic && topic.topicId) {
            let topicId = topic.topicId;
            //let studentId = 31;

            onFetchQuestionsByTopic({
                topicId: topicId,
                studentId: studentDataInCourseTopics.studentId
            });
            // .then(resp => {
            //     console.log(quetionsByTopic);
            //     console.log('#########', JSON.stringify(resp));
            // });
        }
    }

    const handleViewTopicVideo = (e, topic, index) => {
        // const subClasses = [{
        //     classIds: [7, 78, 88]
        // }, {
        //     classIds: [8, 9]
        // }];
        let subscribed = false;
        if (index != 0) {
            subscribed = Utility.getSubscriptionFlag(subscriptionsByStudent, userSession.studentClassId);
        }
        if (subscribed || index == 0) {
            onSubmitViewdDataByStudent({ "studentId": studentDataInCourseTopics.studentId, "topicId": topic.topicId });
            Session.set("topicInfo", JSON.stringify(topic));
            history.push({
                pathname: '/coursewisetopics',
                state: {
                    // topics
                }
            });
        } else {
            // alert("Not Subscribed");
            setModal(true);
        }
    }

    const handleNextQuestion = (e, qId) => {
        let nextIndex = queIndex + 1;
        setQueIndex(nextIndex);

        const storeOption = quetionsByTopic.filter((que, index) => {
            return que.questionId === qId;
        });
        if (storeOption[0]['questionAnswer'] === selectedOption) {
            let s = score + 1;
            setScore(s);
        }
    };
    const handlePreviousQuestion = (e) => {
        let prevIndex = queIndex - 1;
        setQueIndex(prevIndex);
    };
    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };
    const handleTestSummary = (e) => {
        document.getElementById("questionsDivbyTopicWise").style.display = 'none';
        document.getElementById("testResult").style.display = 'block';
    };
    const handleClick = (e) => {
        if (e == true) {
            history.push({
                pathname: '/pricing',
                state: {
                    // topics
                }
            });
        }
        setModal(false);
    };
    return (
        <div className="page-content">
            {showModal ? (<ModalUtil onChildClick={handleClick} data={modalDetails} />) : ('')}
            <Container fluid>
                <div className="row">
                    <div className="col-sm-4">
                        <div className="courseHeadigMaindiv">
                            <div className="arrowandIcondiv">
                                <Link to="/studentcourses"><img src={backIcon} /></Link>
                                <img className="card-img-top" style={{ width: "46%" }} src={MathsIcon} alt="Card image cap" />
                            </div>
                            <div className="courseHeadingdiv">
                                <span className="subjHeading">{courseInfoInCourseTopics.chapterName}</span><br />
                                <span className="cardTextFont">{topics.length} lessons</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-2" id="btnWriteTestDiv">
                        {/* <button className="btn btn-default buttonCss" onClick={handleViewTopics}>Write test</button> */}
                        <Link to="/testmode"> <button className="btn btn-default buttonCss">Write test</button></Link>
                    </div>
                    <div className="col-sm-2" id="btnBackToTopicsDiv" style={{ display: "none" }}>
                        <Link to="/studentcourses">  <button className="btn btn-default defaultButtonCss">Back to Topics</button></Link>
                    </div>
                </div>

                <hr />
                {courseInfoInCourseTopics && courseInfoInCourseTopics.topiccount != 0 ?
                    <div>
                        {topics.map((topic, index) => {
                            return (
                                <div key={index} id="topicsDivWithVideos" className="row" >
                                    <div className="col-sm-1"></div>
                                    <div className="col-sm-9" onClick={(e) => handleViewTopicVideo(e, topic, index)}>
                                        <div className="row CourseTopicCardsDiv">
                                            <div className="col-sm-2 topicThumbnail">
                                                {topic.topicVideoUrl != null ?
                                                    <img src={topic.topicThumbnailUrl} className="videoImgForTopic" />
                                                    : (
                                                        <span>
                                                            <img src={defaultImage} className="videoImgForTopic" />
                                                        </span>
                                                        // <ReactPlayer width="100%" height="67%" controls pip
                                                        //     url={topic.topicVideoUrl} />
                                                    )
                                                }
                                            </div>
                                            <div className="col-sm-9" style={{ paddingTop: "7px" }}>
                                                <span className="topicHeadinginStudentCourse">{topic.topicTitle}</span><br />
                                                {topic.topicVideoUrl == null ?
                                                    <span className="playVideoText">No Video</span>
                                                    : (
                                                        <span className="playVideoText">
                                                            <img src={PlayIcon} className="img-responsive playIcon" style={{ height: "36%", marginLeft: "-15px" }} />&nbsp; Play Video
                                                        </span>
                                                        // <ReactPlayer width="100%" height="67%" controls pip
                                                        //     url={topic.topicVideoUrl} />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    : <h3>No Topics Found</h3>}
                {/* {topics && topics.length > 0 ?
                    <div className="row" id="topicsDivWithVideos">

                        {topics.map((topic, index) => {
                            return (
                                <div className="col-sm-4">
                                    <span className="indexValueCourse">{index + 1}</span>
                                    <div className="card topicsCardDIv" key={index} onClick={(e) => handleViewTopicVideo(e, topic, index)} style={{ boxShadow: "4px 4px 9px rgb(0 0 0 / 8%)", borderRadius: "8px" }}>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                {/* <span style={{ border: "1px solid #dcdcdc", borderRadius: "10px", position: "relative", top: "60px", right: "10px", padding: "2px", fontWeight: "800", boxShadow: "0px 1px 5px 2px #c4c4c4" }}>01</span> }
                                                <img src={VidepImage} className="videoImgForTopic" /></div>

                                            <div className="col-sm-6 topicTitleDiv" ><span className="topicTitleSpan">{topic.topicTitle}</span> <br /><br />
                                                {/* <Link to={topic.topicVideoUrl}><img src={smallPlayIcon} height="25px" /> Play Video</Link> }
                                                {/* <video
                                                src={topic.topicVideoUrl}
                                                style={{ width: '100px', height: '100px' }}
                                                autoPlay
                                            >
                                                <img src={smallPlayIcon} height="25px" /> Play Video
                                            </video> 
                                                {topic.topicVideoUrl == null ?
                                                    <span>No Video</span>
                                                    : (
                                                        <span>
                                                            <img src={PlayIcon} className="img-responsive playIcon" style={{ height: "36%", marginLeft: "-15px" }} />&nbsp; Play Video
                                                        </span>
                                                        // <ReactPlayer width="100%" height="67%" controls pip
                                                        //     url={topic.topicVideoUrl} />
                                                    )
                                                }

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    : <h3>No Topics Found</h3>}

 */}

                {/* <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>Alert</ModalHeader>
                    <ModalBody>
                        <p>You haven't subscribed to show more topics.</p>
                        <h6>Get the best learning from infelearn.</h6>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => {
                            toggle();
                            history.push({
                                pathname: '/pricing'
                            });
                        }}>View Pricing</Button>
                    </ModalFooter>
                </Modal> */}
                {/* <div className="row" id="typesOfTestDiv" style={{ display: "none" }}>
                    <div className="col-sm-4" onClick={handleViewTopicsByTopicWise}>
                        <div className="card cardDivInCourseTopicsForTestWise">
                            <span className="topicHeadinginStudentCourse">Topic wise test</span>
                            <span className="testWiseClearInfo"> Contains tests with topic wise where you will be answering five questions.</span>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="card cardDivInCourseTopicsForTestWise">
                            <span className="topicHeadinginStudentCourse">Mode wise test</span>
                            <span className="testWiseClearInfo"> Contains tests with mode easy, difficult and hard you will be answering.</span>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="card cardDivInCourseTopicsForTestWise">
                            <span className="topicHeadinginStudentCourse">Full chapter test</span>
                            <span className="testWiseClearInfo"> Contains tests with all modes easy, difficult and hard where you will be answering 20 questions.</span>
                        </div>
                    </div>
                </div> */}
                {/* <div className="row" id="topicsListDivForTest" style={{ display: "none" }}>
                    <div className="col-sm-4">
                        {topics.map((topic, index) => {
                            return (
                                <div key={index} className="card cardDivInCourseTopicsForTest" onClick={(e) => handleViewQuestionsByTopic(e, topic)}>
                                    <span className="topicHeadinginStudentCourse">{topic.topicTitle}</span>
                                </div>
                            )
                        })}
                    </div>
                </div> */}
                {/* <div id="testResult" style={{ display: "none" }}>
                    <div className="row">
                        <h1>Your Test Score: {score}</h1>
                    </div>
                </div> */}
                {/* <div id="questionsDivbyTopicWise" style={{ display: "none" }}>
                    {quetionsByTopicLoading == true ? <img src={loader} /> : ''}
                    <div className="row">
                        <span className="questionNumber">{queIndex + 1}</span>
                    </div>
                    <div className="row">
                        <div className="col-sm-8 questionTitleDiv">
                            <b className="questionTitle">
                                {quetionsByTopic.length && quetionsByTopic[queIndex].questionTitle}

                            </b>
                        </div>
                    </div>
                    <div className="questionOptionsDiv">
                        {quetionsByTopic.length &&
                            quetionsByTopic[queIndex].questionOptions.map((option, index) => {
                                return (
                                    <div key={index} className="inputQuestionOptionsDiv"
                                        onChange={(e) => { handleOptionChange(e) }}>
                                        <input id={'radio' + index} type="radio"
                                            name={quetionsByTopic[queIndex].questionId}
                                            value={option}
                                        />
                                        <label htmlFor={'radio' + index} className="lblOptions">
                                            {option}
                                        </label>
                                    </div>
                                )
                            })}
                        <div className="prevAndNextBtns row">
                            <div className="col-sm-3">
                                <button className="btn btn-default defaultButtonCss" disabled={queIndex === 0}
                                    onClick={() => { handlePreviousQuestion() }}>
                                    Previous
                                </button>
                            </div>
                            <div className="col-sm-3"></div>
                            <div className="col-sm-3">
                                <button className="btn btn-primary"
                                    hidden={queIndex === quetionsByTopic.length - 1}
                                    onClick={(e,) => { handleNextQuestion(e, quetionsByTopic[queIndex].questionId) }}>
                                    Next
                                </button>
                                <button hidden={!(queIndex === quetionsByTopic.length - 1)}
                                    className="btn btn-primary"
                                    onClick={(e) => { handleTestSummary(e) }}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div> */}
            </Container>
        </div>
    );

}

const mapStateToProps = ({ TopicsApp, QuestionsApp, SubscriptionsApp }) => {
    const { topics } = TopicsApp;
    const { quetionsByTopic,
        quetionsByTopicLoading,
        quetionsByTopicError } = QuestionsApp;
    const { subscriptionsByStudent, subscriptionsByStudentLoading,
        subscriptionsByStudentError } = SubscriptionsApp;

    return {
        topics,
        quetionsByTopic,
        quetionsByTopicLoading,
        quetionsByTopicError,
        subscriptionsByStudent,
        subscriptionsByStudentLoading,
        subscriptionsByStudentError
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchQuestionsByTopic: (data) => dispatch(fetchQuestionsByTopic(data)),
        onfetchSubscriptionsByStudent: (studentData) => dispatch(fetchSubscriptionsByStudent(studentData)),
        onSubmitViewdDataByStudent: (data) => dispatch(submitViewdDataByStudent(data))
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps, null, {})(CourseTopics));

