import React, { Component, useEffect, useState } from 'react';
import { Link, useLocation, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Card, CardBody, Row, Col } from "reactstrap";
import ReactApexChart from 'react-apexcharts';

import {
    resetDefault, fetchSubjectsDetailsByClass, subjectAnalytics,
    fetchNotificationsByStudent
} from '../../store/actions';
import Session from '../../session/';

import Chemistry from "../../assets/images/icons/Chemistry.png";
import English from "../../assets/images/icons/English.png";
import Mathematics from "../../assets/images/icons/Maths.png";
import Hindi from "../../assets/images/icons/Maths.png";
import Social from "../../assets/images/icons/Social.png";
import Science from "../../assets/images/icons/Physics.png";

const Parent = ({
    history,
    subjects, subjLoading, subjError,
    onFetchSubjectsDetailsByClass,
    chapters,
    loginUser, loginError, loginMessage,
    onResetDefault,
    onSubjectAnalytics,
    student, studentError, studentLoading,
    onFetchNotificationsByStudent
}) => {
    const location = useLocation();
    const [parentData, setParentData] = useState({});
    const [studentData, setStudent] = useState({});
    const [error, setError] = useState({});
    const donutChart = {
        options: {
            legend: {
                show: true,
                position: 'left'
            },
            chart: {
                width: 380,
                type: 'donut'
            },
            plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                      total: {
                        showAlways: true,
                        show: false
                      }
                    }
                  }
                }
              },
            colors: ['#626ed4', '#02a499', '#f8b425'],
            series: [],
            labels: ["Easy", "Medium", "Hard"]
        }
    };
    const [chartData, setChartData] = useState(donutChart);

    useEffect(() => {
        const data = Session.get('authUser');
        if (data) {
            setParentData(data);
            setError({});
            if(data.studentInfos.length) {
                let cId = data.studentInfos[0].studentClassId;
                setStudent({studentName: data.studentInfos[0].studentName, studentId: data.studentInfos[0].studentId});
                onFetchSubjectsDetailsByClass({
                    classId: cId
                });
                onFetchNotificationsByStudent({ "studentId": data.studentInfos[0].studentId });
            }
        } else {
            history.push({
                pathname: '/login'
            });
        }
    }, []);

    useEffect(() => {
        if (loginError && loginMessage == 'analytics') {
            setError({errorData: loginError});   
        } else if (loginError == '' && loginUser && loginMessage == 'analytics') {
            setError({errorData: ''}); 
            calSeriesData(loginUser);
        }
    }, [loginUser, loginError]);

    const handleAddStudent = (e) => {
        onResetDefault(JSON.stringify(parentData));
        history.push({
            pathname: '/addstudent',
            state: parentData
        });
    }

    const handleViewChapters = (e, subjData) => {
        e.preventDefault();
        setChartData({
            options: {
              ...chartData.options,
              series: []
            }
        });
        setStudent(prevState => ({
            ...prevState,
            subjectName: subjData.subjectName
        }));
        onSubjectAnalytics({"studentId": studentData.studentId, "subjectId": subjData.subjectId});
    }

    const calSeriesData = (json) => {
        const finalData = {
            easy: 0,
            easyCorrect: 0,
            medium: 0,
            mediumCorrect: 0,
            hard: 0,
            hardCorrect: 0
        };
        json[0].forEach(function(data) {
            finalData.easy = finalData.easy + data.analytics.easy;
            finalData.easyCorrect = finalData.easyCorrect + data.analytics.easyCorrect;
            finalData.medium = finalData.medium + data.analytics.medium;
            finalData.mediumCorrect = finalData.mediumCorrect + data.analytics.mediumCorrect;
            finalData.hard = finalData.hard + data.analytics.hard;
            finalData.hardCorrect = finalData.hardCorrect + data.analytics.hardCorrect;
        });
        const seriesData = [((100 * finalData.easyCorrect) / finalData.easy) == 0 ? 10 : (100 * finalData.easyCorrect) / finalData.easy, ((100 * finalData.mediumCorrect) / finalData.medium) == 0 ? 10 : (100 * finalData.mediumCorrect) / finalData.medium, ((100 * finalData.hardCorrect) / finalData.hard) == 0 ? 10 : (100 * finalData.hardCorrect) / finalData.hard];
        setChartData({
            options: {
              ...chartData.options,
              series: seriesData
            }
        });
    }

    const handleAnalytics = (e, std) => {
        e.preventDefault();
        setStudent(prevState => ({
            ...prevState,
            studentName: std.studentName,
            studentId: std.studentId,
            subjectName: undefined
        }));
        let cId = std.studentClassId;
        onFetchSubjectsDetailsByClass({
            classId: cId
        });
        setChartData({
            options: {
              ...chartData.options,
              series: []
            }
        });
    }

    const handleViewLearn = () => {
        onResetDefault(JSON.stringify(parentData));
        history.push({
            pathname: '/recentlyViewed',
            state: {
                student: studentData,
                isparent: true
            }
        });
    }

    return (
        <div className="page-content">
            <Container fluid>
                <div className="row">
                <h4>Hello {parentData && parentData.parentName ? parentData.parentName : ''}</h4>
                </div>
                <br />
                <div>
                    {parentData && parentData.studentIds ? (
                        <div>
                            <div className="flex-container">
                                {parentData.studentInfos.map((std, index) => {
                                    return (
                                            <div key={index} onClick={(e) => handleAnalytics(e, std)} className="studentListTile">
                                                <div>
                                                    <p className="card-title highlightCard">{std.studentName}</p>
                                                    <p className="card-title highlightCard">Student ID: {std.uniqueStundetId}</p>
                                                </div>
                                            </div>
                                    )
                                })}
                            </div>
                            <br /><br />
                            <p>To Add another Student, Click on Add Student</p>
                            <button className="btn btn-default buttonCss" onClick={handleAddStudent}>Add Student</button>
                        </div>
                    ) : (
                        <div>
                        <p>You haven't added student in your dasboard.</p>
                        <p>Click on Add Student</p>
                        <button className="btn btn-default buttonCss" onClick={handleAddStudent}>Add Student</button>
                        </div>
                    )}
                </div><br /><br />
                {studentData && studentData.studentName ? (
                <div>
                    <h4 className="studentTile">{studentData && studentData.studentName ? studentData.studentName : ''}</h4> <button className="btn btn-default buttonCss studentTileButton" onClick={handleViewLearn}>View Recently Learned</button><br/>
                    <span>Here are the statistics</span>
                    <div className="flex-container1">
                        {subjects.map((subj, index) => {
                            return (
                                <div key={index}>
                                        <img className="card-img-top cardImgTop" src={Mathematics} alt="Card image cap" onClick={(e) => handleViewChapters(e, subj)}/><br/>
                                        <span className="courseSubject" title={subj.subjectName}>{subj.subjectName}</span>
                                </div>
                            )
                        })}
                    </div>
                    {chartData && chartData.options.series.length ? (
                    <div className="row">
                        <div className="col">
                            <div className="card cardDiv">
                            <h5 className="card-title studentSubjectName">{studentData && studentData.subjectName ? studentData.subjectName : ''}</h5>
                            <div className="card-body">
                                <ReactApexChart options={chartData.options} series={chartData.options.series} type="donut" height="220" width="400"/>
                            </div>
                            </div>
                        </div>
                        <div className="col"></div>
                    </div> 
                    ) : (
                        <div className="row">
                            <p className="subjectError">{error && error.errorData + "for " ? error.errorData : ''}  {studentData && studentData.subjectName ? studentData.subjectName : ''}</p>
                        </div>  
                    )}
                </div>
                ): (
                    <div></div>
                )}
            </Container>
        </div>
    );
}

const mapStateToProps = ({ Account, SubjectsApp, ChaptersApp, Login }) => {
    const { student, studentError, studentLoading } = Account;
    const { subjects, subjLoading, subjError } = SubjectsApp;
    const { chapters } = ChaptersApp;
    const { loginUser, loginError, loginMessage } = Login;

    return {
        student, studentError, studentLoading,
        subjects, subjLoading, subjError,
        chapters,
        loginUser, loginError, loginMessage
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onResetDefault: (postData) => dispatch(resetDefault(postData)),
        onFetchSubjectsDetailsByClass: (classData) => dispatch(fetchSubjectsDetailsByClass(classData)),
        onSubjectAnalytics: (postData) => dispatch(subjectAnalytics(postData)),
        onFetchNotificationsByStudent: (postData) => dispatch(fetchNotificationsByStudent(postData)),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps, null, {})(Parent));