import {
    OFFERS_ACTIVE,
    OFFERS_ACTIVE_SUCCESS,
    OFFERS_ACTIVE_ERROR
} from '../actions';

const INIT_STATE = {
    offersActive: [],
    offersActiveLoading: true,
    offersActiveError: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case OFFERS_ACTIVE:
            return { ...state };

        case OFFERS_ACTIVE_SUCCESS:
            return {
                ...state, offersActiveLoading: false,
                offersActive: action.payload, offersActiveError: ''
            };

        case OFFERS_ACTIVE_ERROR:
            return {
                ...state, offersActiveLoading: false,
                offersActiveError: action.payload, offersActive: []
            };

        default:
            return { ...state };
    }
};