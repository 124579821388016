import {
    CLASS_FEES_DETAILS_BY_BOARD,
    CLASS_FEES_DETAILS_BY_BOARD_SUCCESS,
    CLASS_FEES_DETAILS_BY_BOARD_ERROR
} from '../actions';

const INIT_STATE = {
    classesWithFees: [],
    classesWithFeesLoading: true,
    classesWithFeesError: ''
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case CLASS_FEES_DETAILS_BY_BOARD:
            return { ...state };

        case CLASS_FEES_DETAILS_BY_BOARD_SUCCESS:
            return { ...state, classesWithFeesLoading: false, classesWithFees: action.payload, classesWithFeesError: '' };

        case CLASS_FEES_DETAILS_BY_BOARD_ERROR:
            return { ...state, classesWithFeesLoading: false, classesWithFeesError: action.payload, classesWithFees: [] };

        default:
            return { ...state };
    }
};