import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation, withRouter } from "react-router-dom";
import { Container, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import MathsIcon from "../../assets/images/icons/Maths.png";
import backIcon from "../../assets/images/icons/back.png";
import Session from '../../session';
import Utility from '../../utils/subscriptions';
import ModalUtil from '../../utils/modal';
import {
    fetchSubscriptionsByStudent
} from '../../store/actions';


const TypeWiseTest = ({
    topics,
    history,
    onfetchSubscriptionsByStudent,
    subscriptionsByStudent
}) => {
    const userSession = Session.get('authUser');
    // const [myQues, setMyQues] = useState();
    const location = useLocation();
    // const [modal, setModal] = useState(false);
    // const toggle = () => setModal(!modal);
    const [courseInfoInCourseTopics, setcourseInfoInCourseTopics] = useState({});
    const [showModal, setModal] = useState(false);
    const modalDetails = {
        heading: '',
        body: "You haven't subscribed to show more topics. Get the best learning from infelearn",
        controls: 'View Pricing'
    };

    useEffect(() => {
        // Read studentId from User Session Module
        onfetchSubscriptionsByStudent({
            studentId: userSession.studentId
        });
    }, []);

    useEffect(() => {
        setcourseInfoInCourseTopics(JSON.parse(Session.get("studentChaptersInfo")));
    }, [location]);

    const handleViewQuestionsByTopic = (e, topic, index, mode) => {
        // const subClasses = [{
        //     classIds: [7, 78, 88]
        // }, {
        //     classIds: [8, 9]
        // }];
        let subscribed = false;
        if (index != 0) {
            subscribed = Utility.getSubscriptionFlag(subscriptionsByStudent, userSession.classId);
        }
        if (subscribed || index == 0) {
            Session.set("topicInfoForQuestions", JSON.stringify(topic));
            history.push({
                pathname: '/modewisequestions',
                state: {
                   type: mode
                }
            });
        } else {
            // alert("Not Subscribed");
            setModal(true);
        }
        // Session.set("topicInfoForQuestions", JSON.stringify(topic));
        // history.push({
        //     pathname: '/modewisequestions',
        //     state: {
        //         // topics               
        //     }
        // });
    }

    const handleViewQuestionsByMode = (e, mode) => {
        Session.set("topicInfoForQuestions", JSON.stringify(topics[0]));
        history.push({
            pathname: '/modewisequestions',
            state: {
               type: mode
            }
        });
    }

    const handleClick = (e) => {
        if (e == true) {
            history.push({
                pathname: '/pricing',
                state: {
                    // topics
                }
            });
        }
        setModal(false);
    };
    return (
        <div className="page-content">
            { showModal ? (<ModalUtil onChildClick={handleClick} data={modalDetails} />) : ('')}
            <Container fluid>
                <div className="row">
                    <div className="col-sm-4">
                        <div className="courseHeadigMaindiv">
                            <div className="arrowandIcondiv">
                                <Link to="/testmode"><img src={backIcon} /></Link>
                                <img className="card-img-top" style={{ width: "46%" }} src={MathsIcon} alt="Card image cap" />
                            </div>
                            <div className="courseHeadingdiv">
                                <span className="subjHeading">{courseInfoInCourseTopics.chapterName}</span><br />
                                <span className="cardTextFont">38 chapters | 80 lessons</span>
                            </div>
                        </div>
                    </div>


                    <div className="col-sm-2">
                        <Link to="/testmode">  <button className="btn btn-default defaultButtonCss">Back to Courses</button></Link>
                    </div>
                </div>
                <hr />
                <div className="row" id="topicsListDivForTest">
                    <div className="col-sm-4">
                        {location.state.type == 'topic' ? (
                            topics.map((topic, index) => {
                                return (
                                    <div key={index} className="card cardDivInCourseTopicsForTest" onClick={(e) => handleViewQuestionsByTopic(e, topic, index, "topic")}>
                                        <span className="topicHeadinginStudentCourse">{topic.topicTitle}</span>
                                    </div>
                                )
                            })
                        ) : (
                            <div>
                                <div className="card cardDivInCourseTopicsForTest" onClick={(e) => handleViewQuestionsByMode(e, "easy")}>
                                            <span className="topicHeadinginStudentCourse">Easy</span>
                                </div>
                                <div className="card cardDivInCourseTopicsForTest" onClick={(e) => handleViewQuestionsByMode(e, "medium")}>
                                            <span className="topicHeadinginStudentCourse">Medium</span>
                                </div>
                                <div className="card cardDivInCourseTopicsForTest" onClick={(e) => handleViewQuestionsByMode(e, "hard")}>
                                            <span className="topicHeadinginStudentCourse">Hard</span>
                                </div>
                            </div>
                        )}
                        
                    </div>
                </div>
                {/* <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>Alert</ModalHeader>
                    <ModalBody>
                        <p>You haven't subscribed to show more topics.</p>
                        <h6>Get the best learning from infelearn.</h6>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => {
                            toggle();
                            history.push({
                                pathname: '/pricing'
                            });
                        }}>View Pricing</Button>
                    </ModalFooter>
                </Modal> */}
            </Container>
        </div>
    );

}


const mapStateToProps = ({ TopicsApp, SubscriptionsApp }) => {
    const { topics } = TopicsApp;
    const { subscriptionsByStudent, subscriptionsByStudentLoading,
        subscriptionsByStudentError } = SubscriptionsApp;

    return {
        topics,
        subscriptionsByStudent, subscriptionsByStudentLoading,
        subscriptionsByStudentError
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onfetchSubscriptionsByStudent: (studentData) => dispatch(fetchSubscriptionsByStudent(studentData))
    }
};


// export default withRouter(connect(mapStateToProps)(TypeWiseTest));
export default withRouter(connect(mapStateToProps, mapDispatchToProps, null, {})(TypeWiseTest));
