import {
    REGISTER_STUDENT,
    REGISTER_STUDENT_SUCCESSFUL,
    REGISTER_STUDENT_FAILED
} from '../../actions';

const initialState = {
    student: [],
    studentError: null,
    studentLoading: null
}

const StudentApp = (state = initialState, action) => {
    switch (action.type) {
        case REGISTER_STUDENT:
            return { ...state, studentLoading: true };

        case REGISTER_STUDENT_SUCCESSFUL:
            return { ...state, studentLoading: false, student: [action.payload], studentError: '' };

        case REGISTER_STUDENT_FAILED:
            return { ...state, studentLoading: false, studentError: action.payload, student: {} };

        default:
            return { ...state };
    }
}

export default StudentApp;