/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import {
  SUBJECTS_GET_DETAILS,
  SUBJECTS_GET_DETAILS_SUCCESS,
  SUBJECTS_GET_DETAILS_ERROR,
  FETCH_CONTENTS_SUCCESS, FETCH_CONTENTS_ERROR,
  NOTIFICATIONS_SUCCESS, NOTIFICATIONS_ERROR
} from '../actions';
import { toast } from 'react-toastify';

export const getSubjectsDetails = () => ({
  type: SUBJECTS_GET_DETAILS
});

export const getSubjectsDetailsSuccess = (items) => ({
  type: SUBJECTS_GET_DETAILS_SUCCESS,
  payload: items
});

export const getSubjectsDetailsError = (error) => ({
  type: SUBJECTS_GET_DETAILS_ERROR,
  payload: error,
});

export const getContentsDataSuccess = (items) => (
  {
    type: FETCH_CONTENTS_SUCCESS,
    payload: items
  }
);

export const getContentsDataError = (error) => (
  {
    type: FETCH_CONTENTS_ERROR,
    payload: error,
  }
);

export const getNotificationsSuccess = (items) => ({
  type: NOTIFICATIONS_SUCCESS,
  payload: items
});

export const getNotificationsError = (error) => ({
  type: NOTIFICATIONS_ERROR,
  payload: error,
});


export const fetchSubjectsDetails = () => {
  return (dispatch, getState, { axios, baseUrl, endpoints }) => {

    dispatch(getSubjectsDetails());

    return axios.get(baseUrl + endpoints.getSubjects)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(getSubjectsDetailsSuccess(data.response.data));
      }).catch(() => {
        dispatch(getSubjectsDetailsError('Something went wrong. Try again after sometime.'));
      });
  }
};

export const fetchSubjectsDetailsByClass = (classData) => {
  return (dispatch, getState, { axios, baseUrl, endpoints }) => {
    // dispatch(getClassesDetailsByBoard());
    return axios.post(baseUrl + endpoints.getSubjectsByClass, { "classId": parseInt(classData.classId) })
      .then(response => {

        return response.data;
      }).then(data => {
        dispatch(getSubjectsDetailsSuccess(data.response.data));
      }).catch(() => {
        dispatch(getSubjectsDetailsError('Something went wrong. Try again after sometime.'));
      });
  }
};

export const getContentsData = () => {
  return (dispatch, getState, { axios, baseUrl, endpoints }) => {
    return new Promise((resolve, reject) => {
      return axios.get(baseUrl + endpoints.getcontents)
        .then(response => {
          return response.data;
        }).then(data => {
          resolve(dispatch(getContentsDataSuccess(data.response.data)));
        }).catch(() => {
          resolve(dispatch(getContentsDataError('Something went wrong. Try again after sometime.')));
        });
    });
  }
};

export const fetchNotificationsByStudent = (data) => {
  return (dispatch, getState, { axios, baseUrl, endpoints }) => {
    return axios.post(baseUrl + endpoints.stdNotifications, data)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(getNotificationsSuccess(data.response.data));
      }).catch(() => {
        dispatch(getNotificationsError('Something went wrong. Try again after sometime.'));
      });
  }
};

