import React from 'react';
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import logo from './logo.svg';
// import { Counter } from './features/counter/Counter';
import './App.css';

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./theme.scss";


function App() {
  return (
    <>
      <React.Fragment>
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={VerticalLayout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                roles={route.roles}
              />
            ))}
          </Switch>
        </Router>
      </React.Fragment>
    </>
  );
}

const mapStateToProps = state => {
  return {
    layout: state.Layout
  };
};

// export default App;
export default connect(mapStateToProps, null)(App);