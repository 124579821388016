import {
    OFFERS_ACTIVE,
    OFFERS_ACTIVE_SUCCESS,
    OFFERS_ACTIVE_ERROR
} from '../actions';


export const getActiveOffers = () => ({
    type: OFFERS_ACTIVE
});

export const getActiveOffersSuccess = (data) => ({
    type: OFFERS_ACTIVE_SUCCESS,
    payload: data
});

export const getActiveOffersError = (error) => ({
    type: OFFERS_ACTIVE_ERROR,
    payload: error
});

export const fetchActiveOffers = () => {
    return (dispatch, getState, { axios, baseUrl, endpoints }) => {
        dispatch(getActiveOffers());

        return axios.get(baseUrl + endpoints.getActiveOffers)
            .then(response => {
                return response.data;
            }).then(data => {
                dispatch(getActiveOffersSuccess(data.response.data));
            }).catch(() => {
                dispatch(getActiveOffersError('Something went wrong. Try again after sometime.'));
            });
    }
};