import React, { Component, useEffect, useState } from 'react';
import { Link, useLocation, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Card, CardBody, Row, Col } from "reactstrap";
import MathsIcon from "../../assets/images/icons/Maths.png";
import backIcon from "../../assets/images/icons/back.png";
import VidepImage from "../../assets/images/authentication-bg.jpg";
import ReactPlayer from 'react-player';
import PlayIcon from "../../assets/images/icons/Play.png";

import {
    resetDefault, getRecents
} from '../../store/actions';
import Session from '../../session/';

const RecentlyViewed = ({
    history,
    subjects, subjLoading, subjError,
    chapters,
    loginUser, loginError, loginMessage,
    onResetDefault,
    onGetRecents,
    student, studentError, studentLoading,
    topics, topicsLoading, topicsError
}) => {
    const location = useLocation();
    const [isParent, setIsParent] = useState(false);
    const [studentData, setStudent] = useState({});
    const [todaysData, setTodaysData] = useState([]);
    const [previousData, setPreviousData] = useState([]);
    const [topicsData, setTopicsData] = useState([]);

    useEffect(() => {
        if (location.state.student) {
            const data = location.state.student;
            setStudent(data);
            onGetRecents({studentId: data.studentId});
            setIsParent(location.state.isparent);
        } else {
            history.push({
                pathname: '/login'
            });
        }
    }, []);

    useEffect(() => {
        if(topics.length && !topicsData.length) {
            setTopicsData(topics);
            topics.forEach(function(data) {
                const recentDate = new Date(data.recentDate);
                const todayDate = new Date();
                if(recentDate.getDate() == todayDate.getDate() && recentDate.getDay() == todayDate.getDay() && recentDate.getFullYear() == todayDate.getFullYear()) {
                    let x = todaysData;
                    x.push(data);
                    setTodaysData(x);
                } else {
                    let y = previousData;
                    y.push(data);
                    setPreviousData(y);
                }
            });
        }
    }, [topics, topicsError]);

    const handleClickVideo = (e, topic) => {
        if (isParent) {
            return;
        }
        Session.set("topicInfo", JSON.stringify(topic));
            history.push({
                pathname: '/coursewisetopics',
                state: {
                   redirectTo: '/recentlyViewed' 
                }
            });
    }

    return (
        <div className="page-content">
            <Container fluid>
            <div className="row">
                <div className="col-sm-8">
                    <div className="courseHeadigMaindiv">
                        <div className="arrowandIcondiv">
                            {isParent? (<Link to="/parent"><img src={backIcon} /></Link>): (<Link to="/student"><img src={backIcon} /></Link>)}
                            <img className="card-img-top" style={{ width: "15%" }} src={MathsIcon} alt="Card image cap" /> <b>RECENTLY LEARNED</b>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            {topicsData && topicsData.length ? (
            <div className="row">
                <div className="col-sm-6">
                    <h4>Today</h4>
                    {todaysData.map((topic, index) => {
                            return (
                                    <div className="card topicsCardDIv" key={index} onClick={(e) => handleClickVideo(e, topic)} style={{ boxShadow: "4px 4px 9px rgb(0 0 0 / 8%)", borderRadius: "8px" }}>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <img src={VidepImage} className="videoImgForTopic" /></div>
                                                <div className="col-sm-8 topicTitleDiv" ><span className="topicTitleSpan">{topic.topicTitle}</span> <br />
                                                <span className="RecentTitles">{topic.subjectName}</span><br />
                                                <span className="RecentTitles">{topic.chapterName}</span><br />
                                                {topic.topicVideoUrl == null ?
                                                    <span>No Video</span>
                                                    : (
                                                        <span>
                                                        <img src={PlayIcon} className="img-responsive playIcon" disabled={isParent} style={{height: "36%", marginLeft: "-15px"}}/>&nbsp; Play Video
                                                        </span>
                                                    // <ReactPlayer width="100%" height="67%" controls pip disabled={isParent}
                                                    //     url={topic.topicVideoUrl} />
                                                    )
                                                }<br />
                                                </div>
                                        </div>
                                    </div>
                            )
                })}
                </div>
                <div className="col-sm-6">
                    <h4>Previous</h4>
                    {previousData.map((topic, index) => {
                            return (
                                    <div className="card topicsCardDIv" key={index} onClick={(e) => handleClickVideo(e, topic)} style={{ boxShadow: "4px 4px 9px rgb(0 0 0 / 8%)", borderRadius: "8px" }}>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <img src={VidepImage} className="videoImgForTopic" /></div>
                                                <div className="col-sm-8 topicTitleDiv" ><span className="topicTitleSpan">{topic.topicTitle}</span> <br />
                                                <span className="RecentTitles">{topic.subjectName}</span><br />
                                                <span className="RecentTitles">{topic.chapterName}</span><br />
                                                {topic.topicVideoUrl == null ?
                                                    <span>No Video</span>
                                                    : (
                                                        <span>
                                                        <img src={PlayIcon} className="img-responsive playIcon" disabled={isParent} style={{height: "36%", marginLeft: "-15px"}}/>&nbsp; Play Video
                                                        </span>
                                                    // <ReactPlayer width="100%" height="67%" controls pip disabled={isParent}
                                                    //     url={topic.topicVideoUrl} />
                                                    )
                                                }<br />
                                                </div>
                                        </div>
                                    </div>
                            )
                })}
                </div>
            </div>
            ): (
                <div>
                    <p className="subjectError">No recent videos to show</p>
                </div>
            )}
            </Container>
        </div>
    );
}

const mapStateToProps = ({ Account, SubjectsApp, ChaptersApp, Login, TopicsApp }) => {
    const { student, studentError, studentLoading } = Account;
    const { subjects, subjLoading, subjError } = SubjectsApp;
    const { chapters } = ChaptersApp;
    const { loginUser, loginError, loginMessage } = Login;
    const { topics, topicsLoading, topicsError } = TopicsApp;

    return {
        student, studentError, studentLoading,
        subjects, subjLoading, subjError,
        chapters,
        loginUser, loginError, loginMessage,
        topics, topicsLoading, topicsError
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onResetDefault: (postData) => dispatch(resetDefault(postData)),
        onGetRecents: (postData) => dispatch(getRecents(postData))
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps, null, {})(RecentlyViewed));