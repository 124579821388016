import {
    FEES_GET_DETAILS_BY_CLASS,
    FEES_GET_DETAILS_BY_CLASS_SUCCESS,
    FEES_GET_DETAILS_BY_CLASS_ERROR
}
    from '../actions';

export const getFeesDetailsByClass = () => ({
    type: FEES_GET_DETAILS_BY_CLASS
});
export const getFeesDetailsByClassSuccess = (items) => ({
    type: FEES_GET_DETAILS_BY_CLASS_SUCCESS,
    payload: items
});

export const getFeesDetailsByClassError = (error) => ({
    type: FEES_GET_DETAILS_BY_CLASS_ERROR,
    payload: error,
});
export const fetchFeesDetailsByClass = (classData) => {
    return (dispatch, getState, { axios, baseUrl, endpoints }) => {
        dispatch(getFeesDetailsByClass());
        return axios.post(baseUrl + endpoints.oneclassfeesdetails, { "classId": (classData.classId) })
            .then(response => {
                return response.data;
            }).then(data => {
                dispatch(getFeesDetailsByClassSuccess(data.response.data));
            }).catch(() => {
                dispatch(getFeesDetailsByClassError('Something went wrong. Try again after sometime.'));
            });
    }
};