import React from "react";
import { Route, Redirect } from "react-router-dom";
import Session from "../session/";
import Roles from "./roles";

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  roles = [],
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      let currentUserRole = Session.userRole();
      if (isAuthProtected) {
        let isUserLoggedIn = !Session.isAuthUser();

        if (isUserLoggedIn) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        } else {
          let isValidRole = roles.length && roles.includes(currentUserRole);

          if (!isValidRole) {
            return (
              <Redirect
                to={{ pathname: "/logout", state: { from: props.location } }}
              />
            );
          }
        }
      }

      return (
        <Layout leftSideBarType={currentUserRole === Roles.TEACHER && "hide"}>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

export default AppRoute;
