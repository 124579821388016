import React, { useEffect, useState } from "react";
import { Row, Col, Button, Alert, Container, Label, FormGroup } from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { registerStudent, registerStudentOTP } from '../../store/actions';

// Redux
import { connect } from "react-redux";
import { Link, Redirect, withRouter } from "react-router-dom";
import { withNamespaces } from 'react-i18next';

// import images
import logodark from "../../assets/images/black.svg";
// import { handleInputChange } from "react-select/src/utils";

const Register = ({
    history,
    loginUser,
    loginError,
    loginMessage,
    onRegisterStudent,
    onRegisterStudentOTP
}) => {
    const [regiFormData, setRegiFormData] = useState({});
    const [errors, setError] = useState({});
    const [userOTP, setOTP] = useState({});
    const [checked, setChecked] = useState(true);

    useEffect(() => {
        // onFetchBoardsDetails();
        // console.log(t);
        document.body.classList.add("auth-body-bg");
    }, []);

    useEffect(() => {
        if (loginMessage == 'register otp' && loginError == '' && loginUser && loginUser.length) {
            setOTP(prevState => ({
                ...prevState,
                otp: loginUser[0].mobileOTP
            }));
            document.getElementById("registrationVerificationDiv").style.display = 'block';
            document.getElementById("registrationDiv").style.display = 'none';
        } else if (loginMessage == 'register otp' && loginError) {
            setError(prevState => ({
                ...prevState,
                txtmobile: loginError
            }));
        }
        // if (loginMessage == 'register auth' && loginError) {
        //     setError(prevState => ({
        //         ...prevState,
        //         txtotp: loginError
        //     }));
        // }
        // if (loginMessage == 'register auth' && loginError == '' && loginUser && loginUser.length) {
        //     history.push({
        //         pathname: '/auth-selectboardandclass',
        //         state: regiFormData
        //     });
        // }
    }, [loginUser, loginError]);

    const handleInputChange = (e, type) => {
        e.preventDefault();
        if (e && e.target && e.target.value) {
            const inputValue = e.target.value;

            switch (type) {
                case 'txtmobile': {
                    setRegiFormData(prevState => ({
                        ...prevState,
                        studentPhone: inputValue
                    }));
                    break;
                }
                case 'txtname': {
                    setRegiFormData(prevState => ({
                        ...prevState,
                        studentName: inputValue
                    }));
                    break;
                }
                case 'txtcity': {
                    setRegiFormData(prevState => ({
                        ...prevState,
                        studentCity: inputValue
                    }));
                    break;
                }
                case 'txtotp': {
                    setRegiFormData(prevState => ({
                        ...prevState,
                        studentOTP: inputValue
                    }));
                    break;
                }
                case 'txtpassword': {
                    setRegiFormData(prevState => ({
                        ...prevState,
                        studentPassword: inputValue
                    }));
                    break;
                }
            }
        }
    }

    const handleEnterOTPDiv = (phone) => {
        if (phone == '') {
            setError(prevState => ({
                ...prevState,
                txtmobile: 'Please enter mobile number',
            }));
        }
        else if (phone < 10) {
            setError(prevState => ({
                ...prevState,
                txtmobile: 'Please enter valid number',
            }));
        } else {
            onRegisterStudent({"studentPhone":phone});
        }
    }

    const handleShowRegisterVerification = () => {
        if (!checked) {
            return;
        }
        if (regiFormData.studentPhone && regiFormData.studentName && regiFormData.studentCity && checked) {
            handleEnterOTPDiv(regiFormData.studentPhone);
            // document.getElementById("registrationVerificationDiv").style.display = 'block';
            // document.getElementById("registrationDiv").style.display = 'none';
        } else {
            setError(prevState => ({
                ...prevState,
                txtmobile: (regiFormData.studentPhone)? '' : 'Please enter value',
                txtname: (regiFormData.studentName)? '' : 'Please enter value',
                txtcity: (regiFormData.studentCity)? '' : 'Please enter value',
            }));
            // alert('All inputs are required!!');
        }
    }

    const handleNewStudent = (e) => {
        e.preventDefault();
        if (regiFormData.studentOTP && regiFormData.studentPassword) {
            if(regiFormData.studentOTP == userOTP.otp) {
                history.push({
                pathname: '/auth-selectboardandclass',
                state: regiFormData
            });
            } else {
                setError(prevState => ({
                    ...prevState,
                    txtotp: 'Invalid OTP',
                }));
            }
        } else {
            setError(prevState => ({
                ...prevState,
                txtotp: (regiFormData.studentOTP)? '' : 'Please enter value',
                txtpassword: (regiFormData.studentPassword)? '' : 'Please enter value',
            }));
            // alert('All inputs are required!!');
        }
    }

    return (
        <React.Fragment>
            <div>
                <Container fluid className="p-0">
                    <Row className="no-gutters">
                        <Col lg={4}>
                            <div className="">
                                <div className="w-100">
                                    <Row className="justify-content-center">
                                        <Col lg={9}>
                                            <div>
                                                <div className="text-center">
                                                    <div>
                                                        <Link to="" className="logoImg"><img src={logodark} height="50" alt="logo" /></Link>
                                                    </div>

                                                    <h4 className="font-size-18 welbackFontWeight">Welcome Back !</h4>
                                                    <p className="text-muted">Create an account to continue.</p>
                                                </div>
                                                <div id="registrationDiv">
                                                    <div className="p-2 mt-5">
                                                        <h6 className="font-size-15 welbackFontWeight">Enter the below details</h6>
                                                        <input type="text" className="form-control iputFildBorderRadius"
                                                            placeholder="Mobile Number"
                                                            maxLength="10"
                                                            onChange={(e) => handleInputChange(e, 'txtmobile')} ></input>
                                                        {errors.txtmobile ? (
                                                                <div className="invalid-feedback d-block">
                                                                {errors.txtmobile}
                                                                </div>
                                                            ) : ''}
                                                        <br />
                                                        <input type="text" className="form-control iputFildBorderRadius"
                                                            placeholder="Name" onChange={(e) => handleInputChange(e, 'txtname')} ></input>
                                                        {errors.txtname ? (
                                                                <div className="invalid-feedback d-block">
                                                                {errors.txtname}
                                                                </div>
                                                            ) : ''}
                                                        <br />
                                                        <input type="text" className="form-control iputFildBorderRadius"
                                                            placeholder="City" onChange={(e) => handleInputChange(e, 'txtcity')} ></input>
                                                        {errors.txtcity ? (
                                                                <div className="invalid-feedback d-block">
                                                                {errors.txtcity}
                                                                </div>
                                                            ) : ''}
                                                        <br />
                                                        <input type="checkbox" className="iputFildBorderRadius" defaultChecked={checked}
                                                            placeholder="Aggrement"
                                                            onChange={(e) => setChecked(!checked)} >
                                                        </input>&nbsp; By clicking "Sign Up", you agree to Infelearn <br/><a href="https://infelearn.com/tnc_app.html" target="_blank" className="termsLink">Terms of Use and Privacy Policy.</a>
                                                        {checked ? '' : (
                                                                <div className="invalid-feedback d-block">
                                                                Please accept terms and conditions
                                                                </div>
                                                            )}
                                                        <br />
                                                        <br />
                                                        {/* <p>By signing up, you agree to infelearn terms and conditions</p> */}
                                                        <button className="btn btn-default buttonCss"
                                                            onClick={handleShowRegisterVerification}>Create account</button><br />

                                                    </div>
                                                </div>
                                                <div id="registrationVerificationDiv" style={{ display: "none" }}>
                                                    <div className="p-2 mt-5">
                                                        <h6 className="font-size-15 welbackFontWeight">Verification</h6>
                                                        <input type="text" className="form-control iputFildBorderRadius" placeholder="OTP"
                                                            onChange={(e) => handleInputChange(e, 'txtotp')} ></input>
                                                        {errors.txtotp ? (
                                                                <div className="invalid-feedback d-block">
                                                                {errors.txtotp}
                                                                </div>
                                                            ) : ''}
                                                        <br />
                                                        <h6 className="font-size-15 welbackFontWeight">Set-up a 4 digit password</h6>
                                                        <input type="password" className="form-control iputFildBorderRadius"
                                                            placeholder="Password"
                                                            onChange={(e) => handleInputChange(e, 'txtpassword')} ></input>
                                                        {errors.txtpassword ? (
                                                                <div className="invalid-feedback d-block">
                                                                {errors.txtpassword}
                                                                </div>
                                                            ) : ''}
                                                        <br />
                                                        {/* <p>By signing up, you agree to infelearn terms and conditions</p> */}
                                                        {/* <Link to="/auth-selectboardandclass"> */}
                                                        <button className="btn btn-default buttonCss"
                                                            onClick={handleNewStudent}>
                                                            Verify & Submit</button>
                                                        {/* </Link> */}
                                                        <br />
                                                    </div>
                                                </div>

                                                <div className="mt-5 text-center">
                                                    <p><u><Link to="/login">Login as existing user</Link></u></p>
                                                    <p><u><Link to="/parentlogin">Log in as parent</Link></u></p>
                                                </div>

                                            </div>

                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="authentication-bg">
                                {/* <div className="bg-overlay"></div> */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );

}

// const mapStatetoProps = state => {
//     // const { user, registrationError, loading } = state.Account;
//     return {};
// }

const mapStatetoProps = ({ Login }) => {
    const { loginUser, loginError, loginMessage } = Login;
    return { loginUser, loginError, loginMessage };
}

const mapDispatchToProps = (dispatch) => {
    return {
        onRegisterStudent: (postData) => dispatch(registerStudent(postData))
    }
};
export default withRouter(connect(mapStatetoProps,
    mapDispatchToProps, null, {})(withNamespaces()(Register)));

// export default withRouter(connect(mapStatetoProps, {}, null, {})(Register));
