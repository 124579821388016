import Roles from "../routes/roles";
var CryptoJS = require("crypto-js");

const storageObject = localStorage;
const Session = {
  set: (key, value) => {
    var ciphertext = CryptoJS.AES.encrypt(value, "");
    storageObject.setItem(key, ciphertext.toString());
  },
  get: (key) => {
    if (key === "studentSubscriptionsList") {
      let data = storageObject.getItem(key);
      if (data) {
        var bytes = CryptoJS.AES.decrypt(data.toString(), "");
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      } else {
        return data;
      }
    }
    if (key === "authUser") {
      let data = storageObject.getItem(key);
      if (data) {
        var bytes = CryptoJS.AES.decrypt(data.toString(), "");
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      } else {
        return data;
      }
    }
    let data = storageObject.getItem(key);
    if (data) {
      var bytes = CryptoJS.AES.decrypt(data.toString(), "");
      return bytes.toString(CryptoJS.enc.Utf8);
    } else {
      return data;
    }
  },
  remove: (key) => {
    storageObject.removeItem(key);
  },
  isAuthUser: () => {
    let user = Session.get("authUser");
    return user && Object.keys(user).length ? true : false;
  },
  userRole: () => {
    let user = Session.get("authUser");
    if (user && user.studentId) {
      return Roles.STUDENT;
    } else if (user && user.parentId) {
      return Roles.PARENT;
    } else if (user && user.unique_teacher_id) {
      return Roles.TEACHER;
    } else {
      return "";
    }
  },
  clear: () => {
    Session.remove("authUser");
    Session.remove("studentSubjectInfo");
    Session.remove("studentChaptersInfo");
    Session.remove("topicInfo");
    Session.remove("topicInfoForQuestions");
    Session.remove("studentSubscriptionsList");
  },
};

export default Session;
