import {
    CHECK_LOGIN, LOGIN_USER_SUCCESSFUL, API_ERROR, LOGOUT_USER, LOGOUT_USER_SUCCESS, LOGIN_USER_OTP_SUCCESSFUL, LOGIN_USER_OTP_AUTH_SUCCESSFUL, API_OTP_ERROR, API_OTP_AUTH_ERROR,
    LOGIN_USER_PWD_SUCCESSFUL, API_PWD_ERROR, LOGIN_PARENT_OTP_SUCCESSFUL, API_PARENT_OTP_ERROR, LOGIN_PARENT_PWD_SUCCESSFUL, API_PARENT_PWD_ERROR, LOGIN_PARENT_OTP_AUTH_SUCCESSFUL,
    API_PARENT_OTP_AUTH_ERROR, LOGIN_PARENT_SUCCESSFUL, API_PARENT_ERROR, RESET_DEFAULT_STATUS, VERIFY_STUDENT_SUCCESSFUL, API_VERIFY_STUDENT_ERROR, ADD_STUDENT_SUCCESSFUL, API_ADD_STUDENT_ERROR, VERIFY_OTP_STUDENT_SUCCESSFUL,
    SUBJECT_ANALYTICS_STUDENT_SUCCESSFUL, API_SUBJECT_ANALYTICS_ERROR
}
    from '../../actions';

export const checkLogin = (user, history) => {
    return {
        type: CHECK_LOGIN,
        payload: { user, history }
    }
}

export const loginUserSuccessful = (user) => {
    return {
        type: LOGIN_USER_SUCCESSFUL,
        payload: user
    }
}

export const loginUserOTPSuccessful = (user) => {
    return {
        type: LOGIN_USER_OTP_SUCCESSFUL,
        payload: user
    }
}

export const loginUserPwdSuccessful = (user) => {
    return {
        type: LOGIN_USER_PWD_SUCCESSFUL,
        payload: user
    }
}

export const loginUserOTPAuthSuccessful = (user) => {
    return {
        type: LOGIN_USER_OTP_AUTH_SUCCESSFUL,
        payload: user
    }
}

export const apiError = (error) => {
    return {
        type: API_ERROR,
        payload: error
    }
}

export const apiOTPError = (error) => {
    return {
        type: API_OTP_ERROR,
        payload: error
    }
}

export const apiPwdError = (error) => {
    return {
        type: API_PWD_ERROR,
        payload: error
    }
}

export const apiOTPAuthError = (error) => {
    return {
        type: API_OTP_AUTH_ERROR,
        payload: error
    }
}

export const logoutUser = (history) => {
    return {
        type: LOGOUT_USER,
        payload: { history }
    }
}

export const logoutUserSuccess = () => {
    return {
        type: LOGOUT_USER_SUCCESS,
        payload: {}
    }
}

export const loginParentOTPSuccessful = (user) => {
    return {
        type: LOGIN_PARENT_OTP_SUCCESSFUL,
        payload: user
    }
}

export const parentOTPError = (error) => {
    return {
        type: API_PARENT_OTP_ERROR,
        payload: error
    }
}

export const loginParentPwdSuccessful = (user) => {
    return {
        type: LOGIN_PARENT_PWD_SUCCESSFUL,
        payload: user
    }
}

export const parentPwdError = (error) => {
    return {
        type: API_PARENT_PWD_ERROR,
        payload: error
    }
}

export const loginParentOTPAuthSuccessful = (user) => {
    return {
        type: LOGIN_PARENT_OTP_AUTH_SUCCESSFUL,
        payload: user
    }
}

export const apiParentOTPAuthError = (error) => {
    return {
        type: API_PARENT_OTP_AUTH_ERROR,
        payload: error
    }
}

export const loginParentSuccessful = (user) => {
    return {
        type: LOGIN_PARENT_SUCCESSFUL,
        payload: user
    }
}

export const apiParentPwdError = (error) => {
    return {
        type: API_PARENT_ERROR,
        payload: error
    }
}

export const resetDefaultStatus = (user) => {
    return {
        type: RESET_DEFAULT_STATUS,
        payload: user
    }
}

export const verifyStudentSuccessful = (user) => {
    return {
        type: VERIFY_STUDENT_SUCCESSFUL,
        payload: user
    }
}

export const verifyAgainStudentSuccessful = (user) => {
    return {
        type: VERIFY_OTP_STUDENT_SUCCESSFUL,
        payload: user
    }
}

export const apiVerifyStdError = (error) => {
    return {
        type: API_VERIFY_STUDENT_ERROR,
        payload: error
    }
}

export const addStudentSuccessful = (user) => {
    return {
        type: ADD_STUDENT_SUCCESSFUL,
        payload: user
    }
}

export const apiAddStdError = (error) => {
    return {
        type: API_ADD_STUDENT_ERROR,
        payload: error
    }
}

export const subjectAnalyticsSuccessful = (user) => {
    return {
        type: SUBJECT_ANALYTICS_STUDENT_SUCCESSFUL,
        payload: user
    }
}

export const apiSubjectAnalyticsError = (error) => {
    return {
        type: API_SUBJECT_ANALYTICS_ERROR,
        payload: error
    }
}

export const loginStudent = (postData) => {
    return (dispatch, getState, { axios, baseUrl, endpoints }) => {
        return axios.post(baseUrl + endpoints.studentlogin, postData)
            .then(response => {
                return response.data;
            }).then(data => {
                dispatch(loginUserSuccessful(data.response.data[0]));
            }).catch((error) => {
                dispatch(apiError('Invalid Password'));
            });
    }
};

export const loginTeacher = (postData) => {
    return (dispatch, getState, { axios, baseUrlNewBackend, endpoints }) => {
        return axios
            .post(baseUrlNewBackend + endpoints.teacherlogin, postData)
            .then((response) => {
                return response.data;
            })
            .then((data) => {
                dispatch(loginUserSuccessful(data.response.data));
            })
            .catch((error) => {
                dispatch(apiError("Invalid Password"));
            });
    };
};

export const loginTeacherSendPwd = (postData) => {
    return (dispatch, getState, { axios, baseUrlNewBackend, endpoints }) => {
        return axios
            .post(baseUrlNewBackend + endpoints.verifyteacher, postData)
            .then((response) => {
                return response.data;
            })
            .then((data) => {
                dispatch(loginUserPwdSuccessful(data.response.data));
            })
            .catch((error) => {
                dispatch(apiPwdError("user does not exists"));
            });
    };
};

export const loginSendOTP = (postData) => {
    return (dispatch, getState, { axios, baseUrl, endpoints }) => {
        return axios.post(baseUrl + endpoints.studentotpLogin, postData)
            .then(response => {
                return response.data;
            }).then(data => {
                dispatch(loginUserOTPSuccessful(data.response.data[0]));
            }).catch((error) => {
                dispatch(apiOTPError('user does not exists'));
            });
    }
};

export const loginStudentOTP = (postData) => {
    return (dispatch, getState, { axios, baseUrl, endpoints }) => {
        return axios.post(baseUrl + endpoints.studentloginotp, postData)
            .then(response => {
                return response.data;
            }).then(data => {
                dispatch(loginUserOTPAuthSuccessful(data.response.data[0]));
            }).catch((error) => {
                dispatch(apiOTPAuthError('Invalid OTP'));
            });
    }
};

export const loginSendPwd = (postData) => {
    return (dispatch, getState, { axios, baseUrl, endpoints }) => {
        return axios.post(baseUrl + endpoints.verifystudent, postData)
            .then(response => {
                return response.data;
            }).then(data => {
                dispatch(loginUserPwdSuccessful(data.response.data[0]));
            }).catch((error) => {
                dispatch(apiPwdError('user does not exists'));
            });
    }
};

export const loginParentOTP = (postData) => {
    return (dispatch, getState, { axios, baseUrl, endpoints }) => {
        return axios.post(baseUrl + endpoints.parentsendotplogin, postData)
            .then(response => {
                return response.data;
            }).then(data => {
                dispatch(loginParentOTPSuccessful(data.response.data[0]));
            }).catch((error) => {
                dispatch(parentOTPError('user does not exists'));
            });
    }
};

export const loginParentPwd = (postData) => {
    return (dispatch, getState, { axios, baseUrl, endpoints }) => {
        return axios.post(baseUrl + endpoints.verifyparent, postData)
            .then(response => {
                return response.data;
            }).then(data => {
                dispatch(loginParentPwdSuccessful(data.response.data[0]));
            }).catch((error) => {
                dispatch(parentPwdError('please register'));
            });
    }
};

export const loginParentOTPAuth = (postData) => {
    return (dispatch, getState, { axios, baseUrl, endpoints }) => {
        return axios.post(baseUrl + endpoints.parentloginotp, postData)
            .then(response => {
                return response.data;
            }).then(data => {
                dispatch(loginParentOTPAuthSuccessful(data.response.data[0]));
            }).catch((error) => {
                dispatch(apiParentOTPAuthError('Invalid OTP'));
            });
    }
};

export const loginParentPwdAuth = (postData) => {
    return (dispatch, getState, { axios, baseUrl, endpoints }) => {
        return axios.post(baseUrl + endpoints.parentlogin, postData)
            .then(response => {
                return response.data;
            }).then(data => {
                dispatch(loginParentSuccessful(data.response.data[0]));
            }).catch((error) => {
                dispatch(apiParentPwdError('Invalid Password'));
            });
    }
};

export const resetDefault = (postData) => {
    return (dispatch, getState, { axios, baseUrl, endpoints }) => {
        dispatch(resetDefaultStatus(postData));
    }
};

export const verifyStudent = (postData) => {
    return (dispatch, getState, { axios, baseUrl, endpoints }) => {
        return axios.post(baseUrl + endpoints.verifystudentuniqueid, postData)
            .then(response => {
                return response.data;
            }).then(data => {
                dispatch(verifyStudentSuccessful(data.response.data[0]));
            }).catch((error) => {
                dispatch(apiVerifyStdError('No student with that unique code'));
            });
    }
};

export const addStudent = (postData) => {
    return (dispatch, getState, { axios, baseUrl, endpoints }) => {
        return axios.post(baseUrl + endpoints.updateparent, postData)
            .then(response => {
                return response.data;
            }).then(data => {
                dispatch(addStudentSuccessful(data.response.data[0]));
            }).catch((error) => {
                dispatch(apiAddStdError('Something went wrong. Try again after sometime.'));
            });
    }
};

export const verifyStudentAgain = (postData) => {
    return (dispatch, getState, { axios, baseUrl, endpoints }) => {
        return axios.post(baseUrl + endpoints.verifystudentuniqueid, postData)
            .then(response => {
                return response.data;
            }).then(data => {
                dispatch(verifyAgainStudentSuccessful(data.response.data[0]));
            }).catch((error) => {
                dispatch(apiVerifyStdError('No student with that unique code'));
            });
    }
};

export const subjectAnalytics = (postData) => {
    return (dispatch, getState, { axios, baseUrl, endpoints }) => {
        return axios.post(baseUrl + endpoints.exambysubject, postData)
            .then(response => {
                return response.data;
            }).then(data => {
                dispatch(subjectAnalyticsSuccessful(data.response.data));
            }).catch((error) => {
                dispatch(apiSubjectAnalyticsError('No exams taken till now'));
            });
    }
};
