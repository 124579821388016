import {
    BOARDS_GET_DETAILS,
    BOARDS_GET_DETAILS_SUCCESS,
    BOARDS_GET_DETAILS_ERROR
} from '../actions';

const INIT_STATE = {
    boards: [],
    boardsLoading: false,
    boardsError: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case BOARDS_GET_DETAILS:
            return { ...state, boardsLoading: true };

        case BOARDS_GET_DETAILS_SUCCESS:
            return { ...state, boardsLoading: false, boards: action.payload, boardsError: '' };

        case BOARDS_GET_DETAILS_ERROR:
            return { ...state, boardsLoading: false, boardsError: action.payload, boards: [] };

        default:
            return { ...state };
    }
};