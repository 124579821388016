import React, { Component, useEffect, useState } from 'react';
import { Link, useLocation, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Card, CardBody, Row, Col } from "reactstrap";
import {
    fetchSubjectsDetailsByClass, updateStudentProfile, resetDefault
} from '../../store/actions';

import ProfileIcon from "../../assets/images/icons/profile.png";

import Session from '../../session/';

const StudentProfile = ({
    history,
    student, studentError, studentLoading,
    onFetchSubjectsDetailsByClass,
    onResetDefault,
    onUpdateStudent,
    subjects, subjLoading, subjError,
    chapters,
    loginUser, loginError, loginMessage
}) => {
    const [studentData, setStudentData] = useState({});
    const [errors, setError] = useState({});
    const [file, setFile] = useState({});

    useEffect(() => {
        const stdData = Session.get('authUser');
        //console.log(JSON.stringify(stdData));
        if (stdData && stdData.studentClassId) {
            let cId = stdData.studentClassId;
            onFetchSubjectsDetailsByClass({
                classId: cId
            });
            setStudentData(stdData);
        } else {
            history.push({
                pathname: '/login'
            });
        }
    }, []);

    useEffect(() => {
        if (loginMessage == 'update user profile' && loginError == '' && loginUser && loginUser.length) {
            Session.set('authUser', JSON.stringify(loginUser[0]));
            onResetDefault(JSON.stringify(loginUser[0]));
            history.push({
                pathname: '/student'
            });
        }
    }, [loginUser, loginError]);

    const handleInputChange = (e, type) => {
        e.preventDefault();
        if (e && e.target && e.target.value) {
            const inputValue = e.target.value;

            switch (type) {
                case 'txtname': {
                    setStudentData(prevState => ({
                        ...prevState,
                        studentName: inputValue
                    }));
                    break;
                }
                case 'txtcity': {
                    setStudentData(prevState => ({
                        ...prevState,
                        studentCity: inputValue
                    }));
                    break;
                }
                case 'txtparentname': {
                    setStudentData(prevState => ({
                        ...prevState,
                        studentParentName: inputValue
                    }));
                    break;
                }
                case 'txtschoolname': {
                    setStudentData(prevState => ({
                        ...prevState,
                        studentSchoolName: inputValue
                    }));
                    break;
                }
            }
        }
    }

    const handleSaveChanges = () => {
        if (studentData.studentPhone && studentData.studentName && studentData.studentCity) {
            let formData = new FormData();

            formData.append('studentId', studentData.studentId);
            formData.append('studentName', studentData.studentName);
            formData.append('studentCity', studentData.studentCity);
            formData.append('studentParentName', studentData.studentParentName);
            formData.append('studentSchoolName', studentData.studentSchoolName);

            if (file && file.name) {
                formData.append('file', file);
            }

            onUpdateStudent(formData);
        } else {
            setError(prevState => ({
                ...prevState,
                txtname: (studentData.studentName) ? '' : 'Please enter value',
                txtcity: (studentData.studentCity) ? '' : 'Please enter value',
            }));
        }
    }

    const handleImageUpload = e => {
        let data = e.target.files;
        if (data.length) {
            setFile(data[0]);
        } else {
            setFile({});
        }
    };

    return (
        <div className="page-content">
            <Container fluid>
                <h4>Edit Your Profile</h4>
                <br /><br />
                <div>
                    <span>
                        {studentData && studentData.studentPhoto ? <img src={studentData.studentPhoto} alt="Profile" style={{ width: "50px", height: "50px", borderRadius: "50%" }} /> : <img src={ProfileIcon} alt="Profile" />}
                    </span>
                    <br />
                    <p>My ID: {studentData && studentData.uniqueStundetId ? studentData.uniqueStundetId : ''} </p>
                </div>
                <div>
                    <div className="row">
                        <div className="col-sm-3">
                            <input type="text" className="form-control iputFildBorderRadius"
                                placeholder="Mobile Number"
                                maxLength="10"
                                value={studentData.studentPhone} disabled="true"></input>
                        </div>
                        <div className="col-sm-3">
                            <input type="text" className="form-control"
                                placeholder="Name" onChange={(e) => handleInputChange(e, 'txtname')} value={studentData.studentName}></input>
                            {errors.txtname ? (
                                <div className="invalid-feedback d-block">
                                    {errors.txtname}
                                </div>
                            ) : ''}
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-sm-3">
                            <input type="text" className="form-control"
                                placeholder="City" onChange={(e) => handleInputChange(e, 'txtcity')} value={studentData.studentCity}></input>
                            {errors.txtcity ? (
                                <div className="invalid-feedback d-block">
                                    {errors.txtcity}
                                </div>
                            ) : ''}
                        </div>
                        <div className="col-sm-3">
                            <input type="file" className="form-control" accept="image/*" onChange={handleImageUpload} placeholder="Upload Profile Picture" style={{ paddingTop: "4px" }} />
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-sm-3">
                            <input type="text" className="form-control"
                                placeholder="Parent name" onChange={(e) => handleInputChange(e, 'txtparentname')} value={studentData.studentParentName}></input>
                            {errors.txtparentname ? (
                                <div className="invalid-feedback d-block">
                                    {errors.txtparentname}
                                </div>
                            ) : ''}
                        </div>
                        <div className="col-sm-3">
                            <input type="text" className="form-control"
                                placeholder="School name" onChange={(e) => handleInputChange(e, 'txtschoolname')} value={studentData.studentSchoolName}></input>
                            {errors.txtschoolname ? (
                                <div className="invalid-feedback d-block">
                                    {errors.txtschoolname}
                                </div>
                            ) : ''}
                        </div>
                    </div>
                    <br />
                    <button className="btn btn-default buttonCss" onClick={handleSaveChanges}>Save Changes</button><br />
                </div>
            </Container>
        </div>
    );
}

const mapStateToProps = ({ Account, SubjectsApp, ChaptersApp, Login }) => {
    const { student, studentError, studentLoading } = Account;
    const { subjects, subjLoading, subjError } = SubjectsApp;
    const { chapters } = ChaptersApp;
    const { loginUser, loginError, loginMessage } = Login;

    return {
        student, studentError, studentLoading,
        subjects, subjLoading, subjError,
        chapters,
        loginUser, loginError, loginMessage
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchSubjectsDetailsByClass: (classData) => dispatch(fetchSubjectsDetailsByClass(classData)),
        onUpdateStudent: (postData) => dispatch(updateStudentProfile(postData)),
        onResetDefault: (postData) => dispatch(resetDefault(postData))
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps, null, {})(StudentProfile));