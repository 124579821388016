import React, { Component, useEffect, useState, Fragment } from "react";
import { Link, useLocation, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Card, CardBody, Row, Col } from "reactstrap";

import {
  fetchSubjectsDetailsByClass,
  fetchChaptersDetailsBySubject,
  fetchNotificationsByStudent,
  fetchSubscriptionsByStudent,
} from "../../store/actions";

import arrowIcon from "../../assets/images/icons/arrow.png";
import vector_1 from "../../assets/images/icons/vector_1.png";
import MathsIcon from "../../assets/images/icons/Maths.png";
import vector_2 from "../../assets/images/icons/vector_2.png";
import PhysicsIcon from "../../assets/images/icons/Physics.png";

import Session from "../../session/";
import { joinLiveClass } from "../../store/liveclass/actions";

const Student = ({
  history,
  student,
  studentError,
  studentLoading,
  onFetchSubjectsDetailsByClass,
  subjects,
  subjLoading,
  subjError,
  onFetchChaptersDetailsBySubject,
  onFetchNotifications,
  chapters,
  loginUser,
  joinLiveClass,
  onfetchSubscriptionsByStudent,
  subscriptionsByStudent,
}) => {
  const location = useLocation();
  const [studentData, setStudentData] = useState({});
  const [enableTopicButton, setTopicButton] = useState(true);
  const [enableTestButton, setTestButton] = useState(true);

  useEffect(() => {
    const stdData = Session.get("authUser");
    if (stdData && stdData.studentClassId) {
      let cId = stdData.studentClassId;
      onFetchSubjectsDetailsByClass({
        classId: cId,
      });
      onFetchNotifications({ studentId: stdData.studentId });
      setStudentData(stdData);

      onfetchSubscriptionsByStudent({
        studentId: stdData.studentId,
      });
    } else {
      history.push({
        pathname: "/login",
      });
    }
  }, []);

  useEffect(() => {
    if (subscriptionsByStudent && subscriptionsByStudent.length) {
      Session.set(
        "studentSubscriptionsList",
        JSON.stringify(subscriptionsByStudent)
      );
    } else {
      Session.set("studentSubscriptionsList", JSON.stringify([]));
    }
  }, [subscriptionsByStudent]);

  const handleViewChapters = (e, subjData) => {
    onFetchChaptersDetailsBySubject(subjData);
    Session.set("studentSubjectInfo", JSON.stringify(subjData));
    history.push({
      pathname: "/studentcourses",
      state: {
        // chapters,
        // subjectInfo: subjData
      },
    });
  };

  const handleChangeClass = (e) => {
    history.push({
      pathname: "/auth-selectboardandclass",
      state: studentData,
    });
  };

  const handleViewLearn = () => {
    history.push({
      pathname: "/recentlyViewed",
      state: {
        student: studentData,
        isparent: false,
      },
    });
  };

  const handleTopicButton = (e) => {
    if (e.target.value != "Select Subject") {
      setTopicButton(false);
      const subjData = subjects.filter(
        (data) => data.subjectId == e.target.value
      );
      const postData = { subjectId: e.target.value };
      onFetchChaptersDetailsBySubject(postData);
      Session.set("studentSubjectInfo", JSON.stringify(subjData[0]));
    } else {
      setTopicButton(true);
      setTestButton(true);
    }
  };

  const handleTopicsData = (e) => {
    if (e.target.value != "Select Chapter") {
      setTestButton(false);
      const chapData = chapters.filter(
        (data) => data.chapterId == e.target.value
      );
      Session.set("studentChaptersInfo", JSON.stringify(chapData[0]));
    } else {
      setTestButton(true);
    }
  };

  const handleTest = () => {
    history.push({
      pathname: "/modewisequestions",
      state: {
        type: "chapter",
      },
    });
  };
  const handleViewPracticeTestDiv = () => {
    document.getElementById("practiceTestDiv").style.display = "block";
  };

  const handleJoinLiveClass = async (subj) => {
    // call join-live-class api
    const data = await joinLiveClass(subj.subjectId)

    // push to liveclass page
    history.push({
      pathname: "/liveclass/" + subj.subjectId,
      state: {
        subject: subj,
        data,
      },
    });
  }

  return (
    <div className="page-content">
      <Container fluid>
        <div className="row">
          <div className="col-sm-4 studentNameandClassDiv">
            <h4>
              Hello{" "}
              {studentData && studentData.studentName
                ? studentData.studentName
                : ""}
            </h4>
            <p>
              {studentData && studentData.boardName
                ? studentData.boardName
                : ""}{" "}
              {studentData && studentData.className
                ? studentData.className
                : ""}
            </p>
            <button
              className="btn btn-default buttonCss ChangeClass"
              onClick={handleChangeClass}
            >
              Change Class
            </button>
            {/* <Link to='/pricing'><button className="btn btn-default buttonCss" style={{ float: "right" }}>Pricing</button></Link> */}
          </div>
          <div
            className="col-sm-3 testChallengeDiv"
            onClick={handleViewPracticeTestDiv}
          >
            <div className="flex-container">
              <div className="testChallengeArrowIconDiv">
                <img
                  className="card-img-top"
                  src={arrowIcon}
                  alt="Card image cap"
                />
              </div>
              <div className="testChallengeDivText">
                <b>Test Challeges!</b>
                <br />
                <p style={{ fontSize: "10px" }}>
                  Play Quick and Mock tests for practices
                </p>
              </div>
              <div className="testChallengeDivImage">
                <img
                  className="card-img-top arrowImage"
                  src={vector_1}
                  alt="Card image cap"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-3 testChallengeDiv">
            <Link to="/assignments">
              {" "}
              <div className="flex-container">
                <div className="testChallengeArrowIconDiv">
                  <img
                    className="card-img-top"
                    src={arrowIcon}
                    alt="Card image cap"
                  />
                </div>
                <div className="testChallengeDivText">
                  <b>Question Papers!</b>
                  <br />
                  <p style={{ fontSize: "8.5px" }}>
                    View and practice assignments with previous year question
                    paper
                  </p>
                </div>
                <div className="testChallengeDivImage">
                  <img
                    className="card-img-top arrowImage"
                    src={vector_2}
                    alt="Card image cap"
                  />
                </div>
              </div>
            </Link>
          </div>

          {/* <div className="col-sm-1"></div> */}
          {/* <div className="col-sm-2 courseInfoDiv">
                        <p className="courseInfoText"><span className="courseCount">11</span> Courses <br /> Completed</p>
                    </div> */}
          {/* <div className="col-sm-1"></div> */}
          {/* <div className="col-sm-2 courseInfoDiv">
                        <p className="courseInfoText"><span className="courseCount">9</span> Courses <br /> In Progress</p>
                    </div> */}
          {/* <div className="col-sm-2">
                        <button className="btn btn-default buttonCss studentTileButton" onClick={handleViewLearn}>View Recently Learned</button>
                    </div> */}
        </div>
        <br />
        <br />
        <div id="practiceTestDiv" style={{ display: "none" }}>
          <h4>Practice Test</h4>
          <div className="row">
            <div className="col-sm-3">
              <select
                className="form-control dropdownborderradius"
                onChange={(e) => handleTopicButton(e)}
              >
                <option>Select Subject</option>
                {subjects.map((subjs, index) => {
                  return (
                    <Fragment key={index}>
                      <option value={subjs.subjectId}>
                        {subjs.subjectName}
                      </option>
                    </Fragment>
                  );
                })}
              </select>
            </div>
            <div className="col-sm-3">
              <select
                className="form-control dropdownborderradius"
                disabled={enableTopicButton}
                onChange={(e) => handleTopicsData(e)}
              >
                <option>Select Chapter</option>
                {chapters.map((topic, index) => {
                  return (
                    <Fragment key={index}>
                      <option value={topic.chapterId}>
                        {topic.chapterName}
                      </option>
                    </Fragment>
                  );
                })}
              </select>
            </div>
            <div className="col-sm-2">
              <button
                className="btn btn-default buttonCss studentTileButton"
                disabled={enableTestButton}
                onClick={handleTest}
              >
                Go to Test
              </button>
            </div>
          </div>
        </div>
        <br />
        <br />

        <h4>Courses</h4>
        <div className="row">
          {subjects.map((subj, index) => {
            return (
              <div key={index} className="col-sm-2">
                <div className="card cardDiv">
                  <img
                    className="card-img-top cardImgTop"
                    src={subj.subjectImageUrl}
                    alt="Card image cap"
                  />
                  <div className="card-body">
                    <h5
                      className="card-title textSubjectName"
                      title={subj.subjectName}
                    >
                      {subj.subjectName}
                    </h5>
                    {/* <p className="card-text cardTextFont">38 Chapters | 80 Lessons</p> */}
                    <div className="subjectCardBtns">
                      <a
                        className="btn btn-defult btnViewCourse"
                        onClick={(e) => handleViewChapters(e, subj)}
                      >
                        View Chapters
                      </a>
                      {subj.isLive && (
                        <a
                          className="btn btn-defult btnViewCourse"
                          onClick={(e) => handleJoinLiveClass(subj)}
                        >
                          Join
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = ({
  Account,
  SubjectsApp,
  ChaptersApp,
  Login,
  SubscriptionsApp,
}) => {
  const { student, studentError, studentLoading } = Account;
  const { subjects, subjLoading, subjError } = SubjectsApp;
  const { chapters } = ChaptersApp;
  const { loginUser } = Login;
  const {
    subscriptionsByStudent,
    subscriptionsByStudentLoading,
    subscriptionsByStudentError,
    subscriptionsAdd,
  } = SubscriptionsApp;

  return {
    student,
    studentError,
    studentLoading,
    subjects,
    subjLoading,
    subjError,
    chapters,
    loginUser,
    subscriptionsByStudent,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchSubjectsDetailsByClass: (classData) =>
      dispatch(fetchSubjectsDetailsByClass(classData)),
    onFetchChaptersDetailsBySubject: (subjData) =>
      dispatch(fetchChaptersDetailsBySubject(subjData)),
    onFetchNotifications: (data) => dispatch(fetchNotificationsByStudent(data)),
    onfetchSubscriptionsByStudent: (studentData) =>
      dispatch(fetchSubscriptionsByStudent(studentData)),
    joinLiveClass: (subjectId) =>
      dispatch(joinLiveClass(subjectId)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, {})(Student)
);
