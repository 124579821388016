import React, { Component, useEffect, useState } from 'react';
import { Link, useLocation, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Card, CardBody, Row, Col, Media } from "reactstrap";
import ReactApexChart from 'react-apexcharts';

import {
    resetDefault, fetchSubjectsDetailsByClass, subjectAnalytics,
    fetchNotificationsByStudent
} from '../../store/actions';
import Session from '../../session/';
import notificationsIcon from "../../assets/images/icons/notifications.png";
import MathsIcon from "../../assets/images/icons/Maths.png";
import backIcon from "../../assets/images/icons/back.png";

const Notifications = ({
    history,
    onFetchNotificationsByStudent,
    notificationsData, notificationsDataLoading, notificationsDataError
}) => {
    const location = useLocation();
    const [parentData, setParentData] = useState({});
    const [studentData, setStudent] = useState({});
    const [error, setError] = useState({});
    const [notifications, setNotifications] = useState([]);
    const [isParent, setIsParent] = useState(false);


    useEffect(() => {
        const data = Session.get('authUser');
        if (data) {
            if (data.studentId) {
                setStudent(data);
            } else {
                setIsParent(true);
                setParentData(data);
                if (data.studentInfos.length) {
                    setStudent({ studentName: data.studentInfos[0].studentName, studentId: data.studentInfos[0].studentId });
                    onFetchNotificationsByStudent({ "studentId": data.studentInfos[0].studentId });
                }
            }
        } else {
            history.push({
                pathname: '/login'
            });
        }
    }, []);

    useEffect(() => {
        if (notificationsData.length) {
            setNotifications(notificationsData);
        }
    }, [notificationsData, notificationsDataError]);

    const handleAnalytics = (e, std) => {
        e.preventDefault();
        setStudent(prevState => ({
            ...prevState,
            studentName: std.studentName,
            studentId: std.studentId
        }));
        onFetchNotificationsByStudent({ "studentId": std.studentId })
    }

    return (
        <div className="page-content">
            <Container fluid>
                <div className="row">
                    <div className="col-sm-8">
                        <div className="courseHeadigMaindiv">
                            <div className="arrowandIcondiv">
                                {isParent ? (<Link to="/parent"><img src={backIcon} /></Link>) : (<Link to="/student"><img src={backIcon} /></Link>)}
                                <img className="card-img-top" style={{ width: "15%" }} src={MathsIcon} alt="Card image cap" /> <b>NOTIFICATIONS</b>
                            </div>
                        </div>
                    </div>
                </div>

                <hr />
                {/* <div className="row">
                    <div className="col-sm-8">
                        <h4>Hello {isParent ? parentData.parentName : studentData.studentName}</h4>
                    </div>
                </div> */}
                <br />
                <div>
                    {isParent && parentData && parentData.studentIds ? (
                        <div>
                            <div className="flex-container">
                                {parentData.studentInfos.map((std, index) => {
                                    return (
                                        <div key={index} onClick={(e) => handleAnalytics(e, std)} className="studentListTile">
                                            <div>
                                                <p className="card-title highlightCard">{std.studentName}</p>
                                                <p className="card-title highlightCard">Student ID: {std.uniqueStundetId}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <br /><br />
                        </div>
                    ) : (
                        ''
                    )}
                </div><br />
                {/* {notifications && notifications.length ? (
                    notifications.map((data, index) => {
                        return (
                            <Link key={index} className="text-reset notification-item">
                                <Media>
                                    <div className="avatar-xs mr-3">
                                        <img src={notificationsIcon} style={{ width: "100%", height: "100%", background: "black", padding: "5px" }} />
                                    </div>
                                    <Media body>
                                        <h6 className="mt-0 mb-1">{data.notifytitle}</h6>
                                        <div className="font-size-12 text-muted">
                                            <p className="mb-1">{data.notifytext}</p>
                                        </div>
                                        <div className="font-size-12 text-muted">
                                            <p className="mb-1">{data.createdat.slice(0, 10)}</p>
                                        </div>
                                    </Media>
                                </Media>
                            </Link>
                        )
                    })
                ) : (
                    'No notifications to display'
                )} */}
                {notifications && notifications.length ?
                    <div>
                        {
                            notifications.map((data, index) => {
                                return (
                                    <div key={index} className="row">
                                        <div className="col-sm-1"></div>
                                        <div className="col-sm-9">
                                            <div className="row notificationCardsDiv">
                                                <div className="col-sm-1">
                                                    <span className="indexValueInTopics">{index + 1}</span>
                                                </div>
                                                <div className="col-sm-9" >
                                                    <span className="topicHeadinginStudentCourse">{data.notifytitle}</span> <br />
                                                    <span>{data.notifytext}</span> <br />

                                                </div>
                                                <div className="col-sm-2">
                                                    <span>{data.createdat.slice(0, 10)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                )
                            })
                        }
                    </div> :
                    'No notifications to display'
                }
            </Container>
        </div>
    );
}

const mapStateToProps = ({ NotificationsApp }) => {

    const { notificationsData, notificationsDataLoading, notificationsDataError } = NotificationsApp;
    return {
        notificationsData, notificationsDataLoading, notificationsDataError
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchNotificationsByStudent: (postData) => dispatch(fetchNotificationsByStudent(postData))
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps, null, {})(Notifications));