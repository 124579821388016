import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Card, CardBody, Row, Col } from "reactstrap";
import MathsIcon from "../../assets/images/icons/Maths.png";
import smallPlayIcon from "../../assets/images/icons/small_play.png";
import backIcon from "../../assets/images/icons/back.png";
import VidepImage from "../../assets/images/authentication-bg.jpg";
import VidepImage1 from "../../assets/images/mathstest.jpg";
import VidepImage2 from "../../assets/images/echsvideo.mp4";
import { Link, useLocation, withRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import ReactPlayer from 'react-player';
import Session from '../../session/';
import {
    saveNotesDetails,
    fetchNotesDetailsByTopic
} from '../../store/actions';

const CourseWiseTopics = ({
    history,
    onSaveNotesDetails,
    onFetchNotesDetailsByTopic
}) => {
    //const [topicsInfo, setTopicsInfo] = useState([]);
    const [selectedTopicInfo, setSelectedTopicInfo] = useState([]);
    const [studentData, setStudentData] = useState({});
    const [txtNote, setTxtNote] = useState(null);
    const location = useLocation();

    useEffect(() => {
        //const stdData = Session.get('authUser');
        setStudentData(Session.get('authUser'));
        // setTopicsInfo(location.state.topics);
        setSelectedTopicInfo(JSON.parse(Session.get("topicInfo")));
        onFetchNotesDetailsByTopic({ topicId: selectedTopicInfo.topicId, studentId: studentData.studentId });
    }, [location]);

    const handleTxtNoteChange = (e) => {
        setTxtNote(e.target.value);
    }
    const handleSaveNoteClick = (e) => {
        e.preventDefault();
        if (txtNote) {
            onSaveNotesDetails({ topicNotes: txtNote, studentId: studentData.studentId, topicId: selectedTopicInfo.topicId })
        } else {
            alert('Please enter note');
        }
    };
    const handleBackButton = (e) => {
        if (location.state.redirectTo) {
            history.push({
                pathname: location.state.redirectTo
            });
        } else {
            history.push({
                pathname: "/coursetopics"
            });
        }
    }

    return (

        <div className="page-content">
            <Container fluid>
                <div className="row">
                    <div className="col-sm-4">
                        <div className="courseHeadigMaindiv">
                            <div className="arrowandIcondiv" style={{ width: "40%", float: "left" }}>
                                {/* <Link to="/coursetopics"><img src={backIcon} /></Link> */}
                                <Link onClick={handleBackButton}><img src={backIcon} /></Link>
                                <img className="card-img-top" style={{ width: "46%" }} src={MathsIcon} alt="Card image cap" />
                            </div>
                            <div className="courseHeadingdiv" style={{ width: "60%", float: "left", marginTop: "7px" }}>
                                <span style={{ fontSize: "16px", fontWeight: "800", color: "black" }}>{selectedTopicInfo.topicTitle}</span><br />
                                <span style={{ fontSize: "10px", }}>38 chapters | 80 lessons</span>
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-sm-2">
                        <button className="btn btn-primary" style={buttonCss}>Write test</button>
                    </div> */}
                </div>

                <hr />
                <div className="row">
                    <div className="col-sm-8">
                        {/* <ReactPlayer controls pip={true} url={VidepImage2}></ReactPlayer> */}
                        <ReactPlayer url={selectedTopicInfo.topicVideoUrl}
                            className="react-player"
                            controls={true}
                            pip={false}
                            onContextMenu={e => e.preventDefault()}
                            config={{
                                file: {
                                    attributes: {
                                        controlsList: 'nodownload'
                                    }
                                }
                            }} />
                    </div>
                    <div className="col-sm-4">
                        <textarea rows="17" onChange={handleTxtNoteChange} cols="40" style={{ borderRadius: "12px", border: "1px solid #d6d4d4", boxShadow: "2px 2px 4px 3px #e5e5e5" }} placeholder="You can write notes anytime while watching video and click on add notes button"></textarea>
                        <button className="btn btn-primary" style={{ float: "right" }} onClick={handleSaveNoteClick}>Add Notes</button>
                    </div>
                </div>

                {/* <div className="row">
                    <div className="col-sm-7">
                        <Carousel>
                            <div>
                                <ReactPlayer url='https://www.youtube.com/watch?v=ysz5S6PUM-U' />
                                <p className="legend">Test 1</p>
                            </div>
                            <div>
                                <ReactPlayer url='https://www.youtube.com/watch?v=ysz5S6PUM-U' />
                                <p className="legend">Test 2</p>
                            </div>
                            <div>
                                <ReactPlayer url='https://www.youtube.com/watch?v=ysz5S6PUM-U' />
                                <p className="legend">Test 3</p>
                            </div>

                        </Carousel>
                    </div>
                </div> */}

                {/* Carousel row  starts*/}
                {/* <div className="row">
                    <div className="col-sm-7">
                        <Carousel>
                            <div>
                                <img src={VidepImage} />
                                <p className="legend">Test 1</p>
                            </div>
                            <div>
                                <img src={VidepImage1} />
                                <p className="legend">Test 1</p>
                            </div>
                            <div>
                                <img src={VidepImage2} />
                                <p className="legend">Test 1</p>
                            </div>

                        </Carousel>

                    </div>
                    <div className="col-sm-5">
                        <textarea rows="17" cols="50" style={{borderRadius: "12px", border: "1px solid #d6d4d4", boxShadow: "2px 2px 4px 3px #e5e5e5"}} placeholder="You can write notes anytime while watching video and click on add notes button"></textarea>
                        <button className="btn btn-primary" style={{ float: "right" }}>Add Notes</button>
                    </div>
                </div> */}
                {/* Carousel row  ends*/}

            </Container>
        </div>


    );

}
const mapStateToProps = ({ NotesApp }) => {
    const { notes } = NotesApp;

    return {
        notes
    };
};

const mapDispatchprops = (dispatch) => {
    return {
        onSaveNotesDetails: (notesData) => dispatch(saveNotesDetails(notesData)),
        onFetchNotesDetailsByTopic: (topicandStudentData) => dispatch(fetchNotesDetailsByTopic(topicandStudentData)),
    }
};


export default withRouter(connect(mapStateToProps, mapDispatchprops, null, {})(CourseWiseTopics));

