import React, { Component, useEffect, useState, Fragment } from 'react';
import { Link, useLocation, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Card, CardBody, Row, Col } from "reactstrap";
import ReactApexChart from 'react-apexcharts';
import {
    fetchSubjectsDetailsByClass,
    fetchChaptersDetailsBySubject, subjectAnalytics
} from '../../store/actions';
import Mathematics from "../../assets/images/icons/Maths.png";
import Session from '../../session/';

const Reports = ({
    history,
    student, studentError, studentLoading,
    onFetchSubjectsDetailsByClass,
    subjects, subjLoading, subjError,
    onFetchChaptersDetailsBySubject,
    chapters,
    loginUser, loginError, loginMessage,
    onSubjectAnalytics
}) => {
    const location = useLocation();
    const [studentData, setStudentData] = useState({});
    const [error, setError] = useState({});
    const donutChart = {
        options: {
            legend: {
                show: true,
                position: 'left'
            },
            chart: {
                width: 380,
                type: 'donut'
            },
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            total: {
                                showAlways: true,
                                show: false
                            }
                        }
                    }
                }
            },
            colors: ['#626ed4', '#02a499', '#f8b425'],
            series: [],
            labels: ["Easy", "Medium", "Hard"],
        }
    };
    const [chartData, setChartData] = useState(donutChart);

    useEffect(() => {
        console.log("subjects", subjects)
        const stdData = Session.get('authUser');
        if (stdData && stdData.studentClassId) {
            let cId = stdData.studentClassId;
            onFetchSubjectsDetailsByClass({
                classId: cId
            });
            setStudentData(stdData);
        } else {
            history.push({
                pathname: '/login'
            });
        }
    }, []);

    useEffect(() => {
        if (loginError && loginMessage == 'analytics') {
            setError({ errorData: loginError });
        } else if (loginError == '' && loginUser && loginMessage == 'analytics') {
            setError({ errorData: '' });
            calSeriesData(loginUser);
        }
    }, [loginUser, loginError]);

    const handleViewChapters = (e, subjData) => {
        e.preventDefault();
        setChartData({
            options: {
                ...chartData.options,
                series: []
            }
        });
        setStudentData(prevState => ({
            ...prevState,
            subjectName: subjData.subjectName
        }));
        onSubjectAnalytics({ "studentId": studentData.studentId, "subjectId": subjData.subjectId });
    }

    const calSeriesData = (json) => {
        const finalData = {
            easy: 0,
            easyCorrect: 0,
            medium: 0,
            mediumCorrect: 0,
            hard: 0,
            hardCorrect: 0
        };
        json[0].forEach(function (data) {
            finalData.easy = finalData.easy + data.analytics.easy;
            finalData.easyCorrect = finalData.easyCorrect + data.analytics.easyCorrect;
            finalData.medium = finalData.medium + data.analytics.medium;
            finalData.mediumCorrect = finalData.mediumCorrect + data.analytics.mediumCorrect;
            finalData.hard = finalData.hard + data.analytics.hard;
            finalData.hardCorrect = finalData.hardCorrect + data.analytics.hardCorrect;
        });
        const seriesData = [((100 * finalData.easyCorrect) / finalData.easy) == 0 ? 10 : (100 * finalData.easyCorrect) / finalData.easy, ((100 * finalData.mediumCorrect) / finalData.medium) == 0 ? 10 : (100 * finalData.mediumCorrect) / finalData.medium, ((100 * finalData.hardCorrect) / finalData.hard) == 0 ? 10 : (100 * finalData.hardCorrect) / finalData.hard];
        setChartData({
            options: {
                ...chartData.options,
                series: seriesData
            }
        });
    }

    return (
        <div className="page-content">
            <Container fluid>
                <div className="row">
                    <div className="col-sm-4">
                        <h4>Hello {studentData && studentData.studentName ? studentData.studentName : ''}</h4>
                        <h6>Here are the statistics</h6>
                    </div>
                </div><br /><br />
                <div>
                    <div className="row">
                        {subjects.map((subj, index) => {
                            return (
                                <div key={index} className="col-sm-2" style={{ textAlign: "center" }}>
                                    <img className="card-img-top cardImgTopOnReports" src={subj.subjectImageUrl} alt="Card image cap" onClick={(e) => handleViewChapters(e, subj)} />
                                    <h5 className="card-title textSubjectName" title={subj.subjectName}>{subj.subjectName}</h5>
                                </div>
                            )
                        })}
                    </div>
                    {chartData && chartData.options.series.length ? (
                        <div className="row">
                            <div class="col">
                                <div className="card cardDiv">
                                    <h5 className="card-title studentSubjectName">{studentData && studentData.subjectName ? studentData.subjectName : ''}</h5>
                                    <div className="card-body">
                                        <ReactApexChart options={chartData.options} series={chartData.options.series} type="donut" height="220" width="400" />
                                    </div>
                                </div>
                            </div>
                            <div class="col"></div>
                        </div>
                    ) : (
                        <div className="row">
                            <p className="subjectError">{error && error.errorData + "for " ? error.errorData : ''}  {studentData && studentData.subjectName ? studentData.subjectName : ''}</p>
                        </div>
                    )}
                </div>
            </Container>
        </div>
    );
}

const mapStateToProps = ({ Account, SubjectsApp, ChaptersApp, Login }) => {
    const { student, studentError, studentLoading } = Account;
    const { subjects, subjLoading, subjError } = SubjectsApp;
    const { chapters } = ChaptersApp;
    const { loginUser, loginError, loginMessage } = Login;
    return {
        student, studentError, studentLoading,
        subjects, subjLoading, subjError,
        chapters,
        loginUser, loginError, loginMessage
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchSubjectsDetailsByClass: (classData) => dispatch(fetchSubjectsDetailsByClass(classData)),
        onFetchChaptersDetailsBySubject: (subjData) => dispatch(fetchChaptersDetailsBySubject(subjData)),
        onSubjectAnalytics: (postData) => dispatch(subjectAnalytics(postData)),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps, null, {})(Reports));