import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation, withRouter } from "react-router-dom";
import { Container, Card, CardBody, Row, Col } from "reactstrap";

import {
    fetchTopicsDetailsByChapter
} from '../../store/actions';

import MathsIcon from "../../assets/images/icons/Maths.png";
import PlayIcon from "../../assets/images/icons/Play.png";
import backIcon from "../../assets/images/icons/back.png";
import Session from '../../session';


const StudentCourses = ({
    history,
    chapters,
    onFetchTopicsDetailsByChapter
}) => {
    const location = useLocation();
    const [subjectInfo, setSubjectInfo] = useState({});
    useEffect(() => {
        //console.log("location.state", location.state.subjectInfo);
        //console.log("localstorage", localStorage.getItem(subjectInformation))
        setSubjectInfo(JSON.parse(Session.get("studentSubjectInfo")));
    }, [location]);

    const handleViewTopics = (e, chapterData) => {
        onFetchTopicsDetailsByChapter(chapterData);
        //console.log("chapterData", chapterData)      
        Session.set("studentChaptersInfo", JSON.stringify(chapterData));
        history.push({
            pathname: '/coursetopics',
            state: {
                //chapterData 
            }
        });
    }

    return (

        <div className="page-content">
            <Container fluid>
                <div className="row">
                    <div className="col-sm-4">
                        <div className="courseHeadigMaindiv">
                            <div className="arrowandIcondiv">
                                <Link to="student"><img src={backIcon} /></Link>
                                <img className="card-img-top" style={{ width: "46%" }} src={subjectInfo.subjectImageUrl} alt="Card image cap" />
                            </div>
                            <div className="courseHeadingdiv">
                                <span className="subjHeading">{subjectInfo.subjectName}</span><br />
                                <span className="cardTextFont">{chapters.length} chapters | 80 lessons</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-2">
                        {/* <button className="btn btn-default buttonCss">Write test</button> */}
                    </div>
                </div>
                <hr />
                {/* {chapters && chapters.length > 0 ?
                    <div className="row topicCardsDiv">
                        {chapters.map((chapter, index) => {
                            return (
                                <div key={index} className="col-sm-4">
                                    <div className="card cardDivInStudentCourse">
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <span className="indexValue">{index + 1}</span>
                                                <span onClick={(e) => handleViewTopics(e, chapter)}>
                                                    <img src={PlayIcon} className="img-responsive playIcon" />
                                                </span>
                                            </div>
                                            <div className="col-sm-6 "><span className="topicHeadinginStudentCourse">{chapter.chapterName}</span> <br /> 9 course videos</div>
                                            <div className="col-sm-3"><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjIlVYm4Af5rPVqTPv0S57Ho1ZBz_gEQ7HPg&usqp=CAU" width="65%" height="78%" /></div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    : <h3>No Chapters Found</h3>} */}

                {chapters && chapters.length > 0 ?
                    <div>
                        {chapters.map((chapter, index) => {
                            return (
                                <div key={index} className="row">
                                    <div className="col-sm-1"></div>
                                    <div className="col-sm-9" onClick={(e) => handleViewTopics(e, chapter)}>
                                        <div className="row topicCardsDiv">
                                            <div className="col-sm-1">
                                                <span className="indexValueInTopics">{index + 1}</span>
                                            </div>
                                            <div className="col-sm-9" >
                                                <span className="topicHeadinginStudentCourse">{chapter.chapterName}</span> <br />
                                                {chapter.topiccount == 0 ?
                                                    <span>No Course Videos</span>
                                                    : (
                                                        <span>
                                                            {chapter.topiccount} Course videos
                                                        </span>
                                                        // <ReactPlayer width="100%" height="67%" controls pip
                                                        //     url={topic.topicVideoUrl} />
                                                    )
                                                }
                                            </div>
                                            <div className="col-sm-2">
                                                {/* <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjIlVYm4Af5rPVqTPv0S57Ho1ZBz_gEQ7HPg&usqp=CAU" width="65%" height="78%" /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    : <h3>No Chapters Found</h3>}
            </Container>
        </div>
    );

}
const mapStateToProps = ({ ChaptersApp }) => {
    const { chapters } = ChaptersApp;

    return {
        chapters
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchTopicsDetailsByChapter: (chapterData) => dispatch(fetchTopicsDetailsByChapter(chapterData)),
    }
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps, null, {})(StudentCourses));