import {
    SUBSCRIPTIONS_BY_STUDENT,
    SUBSCRIPTIONS_BY_STUDENT_SUCCESS,
    SUBSCRIPTIONS_BY_STUDENT_ERROR,
    ADD_SUBSCRIPTIONS
} from '../actions';
import Moment from 'moment';

export const getSubscriptionsByStudent = () => ({
    type: SUBSCRIPTIONS_BY_STUDENT
});

export const getSubscriptionsByStudentSuccess = (data) => ({
    type: SUBSCRIPTIONS_BY_STUDENT_SUCCESS,
    payload: data
});

export const getSubscriptionsByStudentError = (error) => ({
    type: SUBSCRIPTIONS_BY_STUDENT_ERROR,
    payload: error,
});

export const addSubscriptionsDetails = (data) => ({
    type: ADD_SUBSCRIPTIONS,
    payload: [data]
});

export const fetchSubscriptionsByStudent = (data) => {
    return (dispatch, getState, { axios, baseUrl, endpoints }) => {
        dispatch(getSubscriptionsByStudent());

        return axios.post(baseUrl + endpoints.subscriptionbystudent, { "studentId": (data.studentId) })
            .then(response => {
                return response.data;
            }).then(data => {
                var subsResp = data.response.data;
                var subFinalResp = [];

                subsResp.map((obj) => {
                    const dueDate = Moment(obj.subrDueDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
                    const now = Moment();

                    if (
                        dueDate.isValid() &&
                        now.isBefore(dueDate) &&
                        obj.paymentstatus === 's'
                    ) {
                        subFinalResp.push(obj);
                    }
                });

                dispatch(getSubscriptionsByStudentSuccess(subFinalResp));
            }).catch(() => {
                dispatch(getSubscriptionsByStudentError('Something went wrong. Try again after sometime.'));
            });
    }
};

export const addSubscriptions = (postData) => {
    return (dispatch, getState, { axios, baseUrl, endpoints }) => {

        return axios.post(baseUrl + endpoints.addSubscriptions, postData)
            .then(response => {
                return response.data;
            }).then(data => {
                dispatch(addSubscriptionsDetails(data.response.data));
            }).catch((error) => {
                dispatch(getSubscriptionsByStudentError('Something went wrong. Try again after sometime.'));
            });
    }
};
