/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import {
  CLASSES_GET_DETAILS,
  CLASSES_GET_DETAILS_SUCCESS,
  CLASSES_GET_DETAILS_ERROR,
  CLASSES_GET_DETAILS_BY_BOARD
} from '../actions';
import { toast } from 'react-toastify';

export const getClassesDetails = () => ({
  type: CLASSES_GET_DETAILS
});

export const getClassesDetailsByBoard = (item) => ({
  type: CLASSES_GET_DETAILS_BY_BOARD,
  payload: item
});

export const getClassesDetailsSuccess = (items) => ({
  type: CLASSES_GET_DETAILS_SUCCESS,
  payload: items
});

export const getClassesDetailsError = (error) => ({
  type: CLASSES_GET_DETAILS_ERROR,
  payload: error,
});

export const fetchClassesDetails = () => {
  return (dispatch, getState, { axios, baseUrl, endpoints }) => {

    dispatch(getClassesDetails());

    return axios.get(baseUrl + endpoints.getClasses)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(getClassesDetailsSuccess(data.response.data));
      }).catch(() => {
        dispatch(getClassesDetailsError('Something went wrong. Try again after sometime.'));
      });
  }
};

//get classesbyboard
export const fetchClassesDetailsByBoard = (boardData) => {
  return (dispatch, getState, { axios, baseUrl, endpoints }) => {
    dispatch(getClassesDetailsByBoard());

    return axios.post(baseUrl + endpoints.getClassesByBoard, { "boardId": parseInt(boardData.boardId) })
      .then(response => {

        return response.data;
      }).then(data => {
        dispatch(getClassesDetailsSuccess(data.response.data));
      }).catch(() => {

        dispatch(getClassesDetailsError('Something went wrong. Try again after sometime.'));
      });
  }
};
