import {
    CLASS_FEES_DETAILS_BY_BOARD,
    CLASS_FEES_DETAILS_BY_BOARD_SUCCESS,
    CLASS_FEES_DETAILS_BY_BOARD_ERROR
} from '../actions';


export const getClassFeesDetailsByBoard = () => ({
    type: CLASS_FEES_DETAILS_BY_BOARD
});

export const getClassFeesDetailsByBoardSuccess = (error) => ({
    type: CLASS_FEES_DETAILS_BY_BOARD_SUCCESS,
    payload: error,
});

export const getClassFeesDetailsByBoardError = (error) => ({
    type: CLASS_FEES_DETAILS_BY_BOARD_ERROR,
    payload: error,
});

export const fetchClassesFeesDetailsByBoard = (data) => {
    return (dispatch, getState, { axios, baseUrl, endpoints }) => {
        dispatch(getClassFeesDetailsByBoard());

        return axios.post(baseUrl + endpoints.classfeesdetailsbyboard, { "boardId": (data.boardId) })
            .then(response => {
                return response.data;
            }).then(data => {
                dispatch(getClassFeesDetailsByBoardSuccess(data.response.data));
            }).catch(() => {
                dispatch(getClassFeesDetailsByBoardError('Something went wrong. Try again after sometime.'));
            });
    }
};